<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6">
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic" style="margin-bottom: 100px;">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Client Settings</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <p style="text-align:left;">Welcome Video</p>
                    <v-select
                      label="Display to"
                        v-model="company.welcome_video"
                        :items="['None','All','Custom']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="company.welcome_video == 'Custom'"
                        v-model="company.list_client"
                        :items="companies"
                        chips
                        item-text="name"
                        item-value="user_id"
                        label="Select Clients"
                        multiple
                        solo
                      >
                    </v-select>

                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <label for="my-file">Welcome Video</label><br>
                   <input type="file" accept="video/*" @change="previewImageBG" class="form-control-file mt-5" id="my-file-bg">
                   <div class="border p-2 mt-3">
                      <p>Preview Here:</p>
                      <template  >
                        <!-- <video  contain :src="previewbg" class="img-fluid" /> -->
                        <video class="img-fluid" id="video-preview" controls v-show="previewbg" />
                      </template>
                    </div>
                  
                     <v-btn
                      dark
                    color="primary"
                    class="float-right mt-2"
                    :loading="uploadLoading"
                    @click="uploadLogoBG"
                  >Upload Video</v-btn>
                  <v-btn
                      dark
                    color="red"
                    class="float-right mt-2 mr-5"
                    @click="removeLogoBG"
                  >Remove Video</v-btn>
                    
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-card-actions class="justify-center">
                  <v-btn
                      dark
                      large
                    color="grey"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="$router.push({ name: 'Dashboard' })"
                  >Back</v-btn>
                   <v-btn
                      dark
                      large
                      
                    color="green"
                    class="
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          justify-center
                        "
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions class="justify-center ">
                  <v-btn
                    tile  dark
                    style="height: 49px;width:95px;"
                    color="primary"
                    class="mb-10"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
          <v-dialog
        fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="openResize"
    >

     <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="openResize = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Resize Logo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <div>
               <iframe
                                id="JotFormIFrame-90142788412154"
                                title="Trainee Registration Form"
                                onload="window.parent.scrollTo(0,0)"
                                allowtransparency="true"
                                allowfullscreen="true"
                                allow="geolocation; microphone; camera"
                                src="https://www.countingcharacters.com/image-resizer-online#sectionbg"
                                frameborder="0"
                                style="width: 1px;
                                min-width: 100%;
                                height:1000px;
                                border:none;"
                                scrolling="yes"
                                ></iframe>
        </div>
      </v-card>
    </v-dialog>
    </v-container>
    
     <!-- <vue-footer></vue-footer> -->
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
import Editor from '@tinymce/tinymce-vue'
export default {
components: {
      VueTelInput, HtmlEditor,'editor': Editor
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        phone:'',
        password : '',
        uploadLoading: false,
        is_admin:0,
        confpassword : '',
        openResize: false,
        role : '',
        companyname : '',
        sector : '',
        no_of_employee : '',
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        preview: null,
      image: null,
        country :'',
        otp_email:'',
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            companies : [],
        company : {
          id: '',
          welcome_video: 'None',
          welcome_video_url : '',
          list_client : []

        },
        previewbg :'',
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address_1 = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
    uploadLogo(){
      const data = new FormData();
        data.append('file_logo', this.company.logo);
        axios({
          method: "POST",
          url: "/api/upload-logo",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Logo uploaded", {
              position: 'top-right'
              });
              console.log();
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
    uploadLogoBG(){
      const data = new FormData();
        data.append('file_logo', this.company.welcome_video_url);
        this.uploadLoading = true
        axios({
          method: "POST",
          url: "/api/upload-video",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Video uploaded", {
              position: 'top-right'
              });
              this.company.welcome_video_url = response.data.data;
              this.saveProfile();
              this.uploadLoading = false
             // console.log();
             // localStorage.setItem('company_logo_bg',process.env.VUE_APP_WASBI_URL+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
    removeLogoBG(){
      if(confirm('Are you sure? you want to remove.')){
        
          Vue.$toast.success("Video removed", {
              position: 'top-right'
              });
              this.company.welcome_video_url = '';
              this.previewbg = ''
              this.saveProfile();
      }
      
    },
    removeLogo(){
        if(confirm('Are you sure? you want to remove.')){
        axios({
          method: "POST",
          url: "/api/remove-logo",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: {}
          })
          .then(response => {
          Vue.$toast.success("Logo removed", {
              position: 'top-right'
              });
              localStorage.setItem('company_logo','')
              this.preview = ''
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
        }
      
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        this.company.logo = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewImageBG: function(event) {
      var input = event.target;
      console.log(input);
      if (input.files) {
        this.company.welcome_video_url = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewbg = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      let video = document.getElementById('video-preview');
      let reader2 = new FileReader();

      reader2.readAsDataURL( this.image );
      reader2.addEventListener('load', function(){
          video.src = reader2.result;
      });
    },


    getUserProfile() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-client-settings",header)
        .then(response => {
            let data = response.data.data;
            
           if (flag == 1) {
                if(data){
                  this.company = JSON.parse(data.settings)
                  this.company.id = data.id
                  if(this.company.welcome_video_url){
                    let video = document.getElementById('video-preview');
                    video.setAttribute("src", url+this.company.welcome_video_url);
                    video.load();
                    this.previewbg = url+this.company.welcome_video_url
                  }
                    
                  
                }
               
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getAllClints() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-all-client",header)
        .then(response => {
            let data = response.data.data;
            this.companies = data

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      self.company.welcome_video = self.company.welcome_video ? self.company.welcome_video : false
      let id  = localStorage.getItem('company_id');
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        
        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/save-client-settings",
          data: {settings : self.company, id : this.company.id}
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Settings edited', {
                    position: 'top-right'
                  })
                  // this.$router.push('/company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getUserProfile();
      this.getAllClints()
    },

}
</script>

