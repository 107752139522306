<template>
<v-app id="inspire">

<vue-header-admin v-if="role.name != 'client'"></vue-header-admin>
<vue-header v-if="role.name == 'client'"></vue-header>
<v-main :style="role.name == 'client' ? 'padding:0px!important;margin-top: 150px;background:white;'  :'background:white;margin-top:15px;'">
    <v-container fluid>
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="8">
              
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class="heading-h2 mt-10">Send Letters </h1>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-stepper
                            v-model="e6"
                            vertical
                          >
                            <v-stepper-step
                            color="blue"
                              :complete-icon="'fa-check'"
                              :complete="e6 > 1"
                              step="1"
                            >
                              Select Letters ({{seletedLetters.length}} Selected)
                            </v-stepper-step>

                            <v-stepper-content step="1">
                              <div class="font-15 m-t-10" style="color: #4a4a4a !important;">This is a list of all unsent and unprinted letters that you have
                                created for this client. You can make any last minute changes, rename, or remove
                                accidental letters by clicking the "View/Edit" button. All sent or printed letters will
                                be shown in the upcoming "Track" screen.
                            </div>
                             <v-data-table
                                  item-key="id"
                                  style="border: solid 1px #dedede;"
                                  v-model="seletedLetters"
                                  :headers="headerPendingItems"
                                  :items="saved_letters"
                                  show-select
                                  :items-per-page="20"
                                  hide-default-footer
                                  class="elevation-1 mt-5 mb-5"
                                >
                                
                                <template v-slot:item.title="{ item }">
                                                    <span> <b>{{item.type}}</b></span><br/>
                                                    <span> {{item.title}}</span><br/>
                                                </template>
                                
                                 <template v-slot:item.print_status="{ item }">
                                                    <span> {{item.print_status == 0 ? 'Pending Print' : 'Completed'}} </span>
                                                </template>
                                  <template v-slot:item.delete="{ item }">
                                        <v-btn
                                          class="mx-2"
                                          text
                                          dark
                                          @click="unAssignDisputeItems(item)"
                                          x-small
                                          color="red"
                                        >
                                          <v-icon dark>
                                            fa-trash
                                          </v-icon>
                                        </v-btn>
                                  </template>
                                  <template v-slot:item.edit="{ item }">
                                        <v-btn
                                            color="black"
                                            style="text-transform: none;"
                                            outlined
                                            @click="addPendingItemDialog = false"
                                          >
                                            View/Edit
                                          </v-btn>
                                  </template>
                                </v-data-table>
                              <v-btn
                                color="blue"
                                dark
                                class="mr-3"
                                :disabled="seletedLetters.length == 0"
                                @click="e6 = 2"
                              >
                                Next
                              </v-btn>
                             
                              <!-- <v-btn
                                :disabled="!doc_id_flag"
                                color="grey"
                                dark
                                @click="e6 = 1"
                              >
                                Prev
                              </v-btn> -->
                            </v-stepper-content>

                            <v-stepper-step
                              :complete="e6 > 2"
                              step="2"
                            >
                              Choose Attached Documents
                            </v-stepper-step>

                            <v-stepper-content step="2">
                              <div class="font-15 m-t-10" style="color: #4a4a4a !important;">Include ID Documents With All? (Typically only needed for Round 1)
                            </div>
                            <div>
                              <v-row class="mt-5 mb-5">
                                <v-col
                                    cols="12"
                                    md="6"
                                  >
                                  <v-card
                                    class="mx-auto"
                                    elevation="2"
                                    outlined
                                >
                                    <v-list-item three-line>
                                      
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-if="doc_id_flag"
                                    small
                                    color="green"
                                    >
                                    <v-icon dark>
                                        fa-check
                                    </v-icon>
                                    </v-btn>
                                     <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-else
                                    small
                                    color="red"
                                    >
                                    <v-icon dark>
                                        fa-times
                                    </v-icon>
                                    </v-btn>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                        Photo ID
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="doc_id_flag">Document found</v-list-item-subtitle><v-list-item-subtitle v-else>Document not found</v-list-item-subtitle>
                                        <v-list-item-subtitle style="color:blue;" v-if="doc_id_flag">{{doc_id_value}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                          <!-- <span v-if="doc_id_flag" class="ml-3" style="float:right;color:green;cursor: pointer;" @click="downloadFile(doc_id_value)">View</span> <span style="float:right;color:#2785CD;cursor: pointer;" @click="onButtonClick('file_id')">Upload</span> -->
                                          <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            x-small
                                            @click="onButtonClick('file_id')"
                                            style="box-shadow: none;"
                                            color="blue"
                                            >
                                            <v-icon dark>
                                                fa-upload
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            v-if="doc_id_flag"
                                            style="box-shadow: none;"
                                            dark
                                            x-small
                                            @click="downloadFile(doc_id_value)"
                                            color="orange"
                                            >
                                            <v-icon dark>
                                                fa-eye
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            v-if="doc_id_flag"
                                            @click="deleteFile(doc_id_value)"
                                            style="box-shadow: none;"
                                            x-small
                                            color="red"
                                            >
                                            <v-icon dark>
                                                fa-trash
                                            </v-icon>
                                            </v-btn>
                                         <!-- <v-btn
                                            color="black"
                                            v-if="doc_id_flag"
                                            style="text-transform: none;"
                                            outlined
                                            @click="downloadFile(doc_id_value)"
                                          >
                                            View/Edit
                                          </v-btn> -->
                                          <!-- <v-btn
                                            color="black"
                                            v-else
                                            style="text-transform: none;"
                                            outlined
                                            @click="onButtonClick('file_id')"
                                          >
                                            Upload
                                          </v-btn> -->
                                          <input
                                            ref="file_id"
                                            class="d-none"
                                            type="file"
                                            accept="image/*"
                                            @change="onFileChanged"
                                          >
                                        </v-list-item-title>
                                        
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                  </v-col>




                                   <v-col
                                    cols="12"
                                    md="6"
                                  >
                                  <v-card
                                    class="mx-auto"
                                    elevation="2"
                                    outlined
                                >
                                    <v-list-item three-line>
                                      
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-if="doc_bill_flag"
                                    small
                                    color="green"
                                    >
                                    <v-icon dark>
                                        fa-check
                                    </v-icon>
                                    </v-btn>
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-else
                                    small
                                    color="red"
                                    >
                                    <v-icon dark>
                                        fa-times
                                    </v-icon>
                                    </v-btn>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                        Bill
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="doc_bill_flag">Document found</v-list-item-subtitle><v-list-item-subtitle v-else>Document not found</v-list-item-subtitle>
                                        <v-list-item-subtitle style="color:blue;" v-if="doc_bill_flag">{{doc_bill_value}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                          <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            x-small
                                            @click="onButtonClick('file_bill')"
                                            style="box-shadow: none;"
                                            color="blue"
                                            >
                                            <v-icon dark>
                                                fa-upload
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            v-if="doc_bill_flag"
                                            style="box-shadow: none;"
                                            dark
                                            x-small
                                            @click="downloadFile(doc_bill_value)"
                                            color="orange"
                                            >
                                            <v-icon dark>
                                                fa-eye
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            @click="deleteFile(doc_bill_value)"
                                            v-if="doc_bill_flag"
                                            style="box-shadow: none;"
                                            x-small
                                            color="red"
                                            >
                                            <v-icon dark>
                                                fa-trash
                                            </v-icon>
                                            </v-btn>
                                         <!-- <v-btn
                                            color="black"
                                            v-if="doc_bill_flag"
                                            style="text-transform: none;"
                                            outlined
                                            @click="downloadFile(doc_bill_value)"
                                          >
                                            View/Edit
                                          </v-btn>
                                          <v-btn
                                            color="black"
                                            v-else
                                            style="text-transform: none;"
                                            outlined
                                            @click="onButtonClick('file_bill')"
                                          >
                                            Upload
                                          </v-btn> -->
                                          <input
                                            ref="file_bill"
                                            class="d-none"
                                            type="file"
                                            accept="image/*"
                                            @change="onFileChanged"
                                          >
                                        </v-list-item-title>
                                        
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                  </v-col>



                                  <v-col
                                    cols="12"
                                    v-if="doc_letter_a_flag"
                                    md="6"
                                  >
                                  <v-card
                                    class="mx-auto"
                                    elevation="2"
                                    outlined
                                >
                                    <v-list-item three-line>
                                      
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-if="doc_letter_a_flag"
                                    small
                                    color="green"
                                    >
                                    <v-icon dark>
                                        fa-check
                                    </v-icon>
                                    </v-btn>
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-else
                                    small
                                    color="red"
                                    >
                                    <v-icon dark>
                                        fa-times
                                    </v-icon>
                                    </v-btn>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                          Social Security Card
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="doc_letter_a_flag">Document found</v-list-item-subtitle><v-list-item-subtitle v-else>Document not found</v-list-item-subtitle>
                                        <v-list-item-subtitle style="color:blue;" v-if="doc_letter_a_flag">{{doc_letter_a_value}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                          <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            x-small
                                            @click="onButtonClick('file_letter_a')"
                                            style="box-shadow: none;"
                                            color="blue"
                                            >
                                            <v-icon dark>
                                                fa-upload
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            v-if="doc_letter_a_flag"
                                            style="box-shadow: none;"
                                            dark
                                            x-small
                                            @click="downloadFile(doc_letter_a_value)"
                                            color="orange"
                                            >
                                            <v-icon dark>
                                                fa-eye
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            v-if="doc_letter_a_flag"
                                            style="box-shadow: none;"
                                            @click="deleteFile(doc_letter_a_value)"
                                            x-small
                                            color="red"
                                            >
                                            <v-icon dark>
                                                fa-trash
                                            </v-icon>
                                            </v-btn>
                                         <!-- <v-btn
                                            color="black"
                                            v-if="doc_letter_a_flag"
                                            style="text-transform: none;"
                                            outlined
                                            @click="downloadFile(doc_letter_a_value)"
                                          >
                                            View/Edit
                                          </v-btn>
                                          <v-btn
                                            color="black"
                                            v-else
                                            style="text-transform: none;"
                                            outlined
                                            @click="onButtonClick('file_letter_a')"
                                          >
                                            Upload
                                          </v-btn> -->
                                          <input
                                            ref="file_letter_a"
                                            class="d-none"
                                            type="file"
                                            accept="image/*"
                                            @change="onFileChanged"
                                          >
                                        </v-list-item-title>
                                        
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    v-if="doc_letter_b_flag"
                                    md="6"
                                  >
                                  <v-card
                                    class="mx-auto"
                                    elevation="2"
                                    outlined
                                >
                                    <v-list-item three-line>
                                      
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-if="doc_letter_b_flag"
                                    small
                                    color="success"
                                    >
                                    <v-icon dark>
                                        mdi-check
                                    </v-icon>
                                    </v-btn>
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-else
                                    small
                                    color="error"
                                    >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                    </v-btn>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                        Letter B
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="doc_letter_b_flag">Document found</v-list-item-subtitle><v-list-item-subtitle v-else>Document not found</v-list-item-subtitle>
                                        <v-list-item-subtitle style="color:blue;" v-if="doc_letter_b_flag">{{doc_letter_b_value}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                          <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            x-small
                                            @click="onButtonClick('file_letter_b')"
                                            style="box-shadow: none;"
                                            color="blue"
                                            >
                                            <v-icon dark>
                                                fa-upload
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            v-if="doc_letter_b_flag"
                                            style="box-shadow: none;"
                                            dark
                                            x-small
                                            @click="downloadFile(doc_letter_b_value)"
                                            color="orange"
                                            >
                                            <v-icon dark>
                                                fa-eye
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            v-if="doc_letter_b_flag"
                                            style="box-shadow: none;"
                                            @click="deleteFile(doc_letter_b_value)"
                                            x-small
                                            color="red"
                                            >
                                            <v-icon dark>
                                                fa-trash
                                            </v-icon>
                                            </v-btn>
                                         <!-- <v-btn
                                            color="black"
                                            v-if="doc_letter_b_flag"
                                            style="text-transform: none;"
                                            outlined
                                            @click="downloadFile(doc_letter_b_value)"
                                          >
                                            View/Edit
                                          </v-btn>
                                          <v-btn
                                            color="black"
                                            v-else
                                            style="text-transform: none;"
                                            outlined
                                            @click="onButtonClick('file_letter_b')"
                                          >
                                            Upload
                                          </v-btn> -->
                                          <input
                                            ref="file_letter_b"
                                            class="d-none"
                                            type="file"
                                            accept="image/*"
                                            @change="onFileChanged"
                                          >
                                        </v-list-item-title>
                                        
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    v-if="doc_letter_c_flag"
                                    md="6"
                                  >
                                  <v-card
                                    class="mx-auto"
                                    elevation="2"
                                    outlined
                                >
                                    <v-list-item three-line>
                                      
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-if="doc_letter_c_flag"
                                    small
                                    color="green"
                                    >
                                    <v-icon dark>
                                        fa-check
                                    </v-icon>
                                    </v-btn>
                                    <v-btn
                                    class="mx-2 mr-10 ml-5"
                                    fab
                                    dark
                                    v-else
                                    small
                                    color="error"
                                    >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                    </v-btn>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                        Letter C
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="doc_letter_c_flag">Document found</v-list-item-subtitle><v-list-item-subtitle v-else>Document not found</v-list-item-subtitle>
                                        <v-list-item-subtitle style="color:blue;" v-if="doc_letter_c_flag">{{doc_letter_c_value}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
            
                                        <v-list-item-title class="headline mb-1">
                                          <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            x-small
                                            @click="onButtonClick('file_letter_c')"
                                            style="box-shadow: none;"
                                            color="blue"
                                            >
                                            <v-icon dark>
                                                fa-upload
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            v-if="doc_letter_c_flag"
                                            style="box-shadow: none;"
                                            dark
                                            x-small
                                            @click="downloadFile(doc_letter_c_value)"
                                            color="orange"
                                            >
                                            <v-icon dark>
                                                fa-eye
                                            </v-icon>
                                            </v-btn>
                                            <v-btn
                                            class="mx-2 "
                                            fab
                                            dark
                                            v-if="doc_letter_c_flag"
                                            style="box-shadow: none;"
                                            @click="deleteFile(doc_letter_c_value)"
                                            x-small
                                            color="red"
                                            >
                                            <v-icon dark>
                                                fa-trash
                                            </v-icon>
                                            </v-btn>
                                         <!-- <v-btn
                                            color="black"
                                            v-if="doc_letter_c_flag"
                                            style="text-transform: none;"
                                            outlined
                                            @click="downloadFile(doc_letter_c_value)"
                                          >
                                            View/Edit
                                          </v-btn>
                                          <v-btn
                                            color="black"
                                            v-else
                                            style="text-transform: none;"
                                            outlined
                                            @click="onButtonClick('file_letter_c')"
                                          >
                                            Upload
                                          </v-btn> -->
                                          <input
                                            ref="file_letter_c"
                                            class="d-none"
                                            type="file"
                                            accept="image/*"
                                            @change="onFileChanged"
                                          >
                                        </v-list-item-title>
                                        
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                  </v-col>
                              </v-row>

                            </div>
                            <v-btn
                                color="grey"
                                dark
                                class="mr-3"
                                @click="e6 = 1"
                              >
                                Prev
                              </v-btn>
                              <v-btn
                                :disabled="!doc_id_flag"
                                color="blue"
                                dark
                                
                                @click="e6 = 3"
                              >
                                Next
                              </v-btn>
                             
                             
                            </v-stepper-content>

                            <v-stepper-step
                              :complete="e6 > 3"
                              step="3"
                            >
                              Choose Letter Send Method
                            </v-stepper-step>

                            <v-stepper-content step="3">
                              <v-col
                                  style="border: solid 2px #0075cc !important;border-radius: 4px;"
                                    cols="12"
                                    class="mb-5"
                                    md="12"
                                  >
                                  
                                <v-radio-group v-model="radioLetter" style="color:black;">
                                    <v-radio
                                    class="radio-div"
                                      :label="`Print Locally`"
                                      value="print"
                                    ></v-radio>
                                  </v-radio-group>
                                  <div class="font-15 mt-20 ml-5" style="  color: #4a4a4a !important;">On your own printer</div>
                              </v-col>
                              <v-btn
                                color="grey"
                                dark
                                class="mr-3"
                                @click="e6 = 2"
                              >
                                Prev
                              </v-btn>
                              <v-btn
                                color="blue"
                                dark
                               
                                @click="e6 = 4,getPdf()"
                              >
                                Next
                              </v-btn>
                             
                            </v-stepper-content>

                            <v-stepper-step :complete="e6 > 4" step="4">
                              Confirm Letter Sending Options
                            </v-stepper-step>
                            <v-stepper-content step="4">
                              <h2 style="color:#9B9B9B;">Preview Letter</h2>
                              <embed class="mt-5" :src="preview_url+'letters/'+pdf_name" width= "100%" height="600">
                              <p><a style="text-decoration: none;" :href="preview_url+'letters/'+pdf_name" target="_blank">Download as PDF</a></p>

                              <v-btn
                                :disabled="!doc_id_flag"
                                color="grey"
                                dark
                                class="mr-3"
                                @click="e6 = 3"
                              >
                                Prev
                              </v-btn>
                              <v-btn
                                color="blue"
                                
                                @click="e6 = 5"
                                dark
                              >
                                Next
                              </v-btn>
                             

                              
                             
                            </v-stepper-content>

                            <v-stepper-step
                              :complete="e6 > 5"
                              step="5"
                            >
                              Confirm Fax Sending Options
                            </v-stepper-step>

                            <v-stepper-content step="5">
                              <v-col
                              v-show="!faxSendFlag"
                                  style="border: solid 2px #0075cc !important;border-radius: 4px;"
                                    cols="12"
                                    class="mb-5"
                                    md="12"
                                  >
                                  
                                <v-radio-group v-model="radioFax" style="color:black;" >
                                    <v-radio
                                    class="radio-div"
                                      :label="`Send Fax`"
                                      value="fax"
                                    ></v-radio>
                                    <v-radio
                                    class="radio-div"
                                      :label="`Skip`"
                                      value="skip"
                                    ></v-radio>
                                  </v-radio-group>
                              </v-col>
                              <v-btn
                              v-if="!faxSendFlag"
                                color="blue"
                                dark
                                @click="sendFax()"
                              >
                                Continue
                              </v-btn>
                              <v-btn
                                v-if="faxSendFlag && role.name == 'client'"
                                color="blue"
                                dark
                                @click="$router.push('/dashboard')"
                              >
                                Done
                              </v-btn>
                               <v-btn
                                v-if="faxSendFlag && role.name == 'super_admin'"
                                color="blue"
                                dark
                                @click="$router.push('/admin/client-profile-detail/'+user_id)"
                              >
                                Done
                              </v-btn>
                               <v-btn
                                v-if="faxSendFlag && role.name == 'company_admin'"
                                color="blue"
                                dark
                                @click="$router.push('/admin/client-profile-detail/'+user_id)"
                              >
                                Done
                              </v-btn>
                              <v-btn text>
                                Cancel
                              </v-btn>
                            </v-stepper-content>
                          </v-stepper>
                    </v-col>
                    
                  </v-row>
            </v-col>
          </v-row>
          <v-overlay :value="overlay">
     <div class="loadingio-spinner-spinner-2k3nmd4xown"><div class="ldio-7xwhhkt0pnf">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>




    </v-overlay>
    <v-dialog
        persistent
      v-model="addPendingItemDialog"
      width="auto"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Add Saved/Pending Items
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <v-data-table
           item-key="id"
           v-model="seletedPendingItems"
          :headers="headerPendingItems"
          :items="pendingItems"
          show-select
          :items-per-page="20"
          hide-default-footer
          class="elevation-1"
        >
        <template v-slot:header.trn="{ header }">
          <img height="25" class="image-logo2" src="/assets/images/tr.png">
        </template>
        <template v-slot:header.exp="{ header }">
          <img height="25" class="image-logo2" src="/assets/images/ex.png">
        </template>
        <template v-slot:header.eq="{ header }">
          <img height="25" class="image-logo2" src="/assets/images/eq.png">
        </template>
        <template v-slot:item.account="{ item }">
                            <span> Transunion: {{item.account.transunion}}</span><br/>
                            <span> Experian: {{item.account.experian}}</span><br/>
                            <span> Equifax: {{item.account.equifax}}</span><br/>
                        </template>
        <template v-slot:item.trn="{ item }">
              <div style="text-align:center;" v-if="item.transunion">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        <template v-slot:item.exp="{ item }">
              <div style="text-align:center;" v-if="item.experian">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        <template v-slot:item.eq="{ item }">
              <div style="text-align:center;" v-if="item.equifax">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        
        </v-data-table>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          dark
          @click="assignDisputeItems"
            color="green"
          >
            Add to Dispute
          </v-btn>
           <v-btn
            color="green"
            outlined
            @click="addPendingItemDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="saveLetterDialog"
      width="600"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Save Letter
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-row>
           <v-col
                      cols="12"
                      md="3"
                    >
                    <v-select
                      :items="[1,2,3,4,5,6]"
                      label="Round"
                      v-model="letter_round"
                      outlined
                    ></v-select>

                    </v-col>
                     <v-col
                      cols="12"
                      md="9"
                    >
                    <v-text-field
                        label="Name of this letter"
                        outlined
                        v-model="letter_name"
                      ></v-text-field>
                     </v-col>
          </v-row>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          dark
          @click="saveLetter"
            color="green"
          >
            Save All Letters
          </v-btn>
           <v-btn
            color="green"
            outlined
            @click="saveLetterDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
    </v-container>
    
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        user: '',
        overlay: false,
        e6: 1,
        addPendingItemDialog: false,
        tab : null,
        saveLetterDialog:false,
        radioFax: 'fax',
        faxSendFlag: false,
        letter_round : 2,
        letter_name: 'RD2',
        docs : [],
        seletedPendingItems: [],
        seletedLetters : [],
        saved_letters : [],
        client : '',
        doc_id_flag : false,
        doc_id_value: '',
        user_id : '',
        doc_bill_flag : false,
        doc_bill_value: '',
        doc_letter_a_flag : false,
        doc_letter_a_value: '',
        doc_letter_b_flag : false,
        doc_letter_b_value: '',
        doc_letter_c_flag : false,
        doc_letter_c_value: '',
        headerPendingItems: [{
          text: 'Letter To',
          align: 'start',
          value: 'title',
          sortable: false
        },
        { text: 'Created', value: 'created_at',
          sortable: false },
        { text: 'Print Status', value: 'print_status',
          sortable: false },
        // { text: 'Actions', value: 'edit',
        //   sortable: false },
          { text: '', value: 'delete',
          sortable: false },
     ],
          headerDisputeItems: [{
          text: 'Creditor/Furnisher',
          align: 'start',
          value: 'creditor',
          sortable: false
        },
        { text: 'Account #', value: 'account',
          sortable: false },
        { text: 'Reason', value: 'reason',
          sortable: false },
        { text: 'Disputed', value: 'disputed',
          sortable: false },
        { text: 'trn', value: 'trn',
          sortable: false },
        { text: 'exp', value: 'exp',
          sortable: false },
        { text: 'eq', value: 'eq',
          sortable: false },
          { text: 'delete', value: 'delete',
          sortable: false },
          ],
        pendingItems : [],
        displayFlag :[],
        disputeItems: [],
        categories: [],
        preview_url: process.env.VUE_APP_WASBI_URL,
        transunion_letter : '',
        role : JSON.parse(localStorage.getItem('role')),
        experian_letter: '',
        equifax_lettr: '',
        transunion_address : '',
        pdf_name :'',
        selectedFile: '',
        experian_address:"",
        client_address : '',
        equifax_address :'',
        radioLetter : 'print',
        radioLetterRecipient: '',
        StatusOptions : ['Negative', 'Positive', 'Repaired', 'Deleted', 'In Dispute', 'Verified', 'Updated', 'Unspecified', 'Ignore'],
        reasonOptions: ['Please correct/update this inaccurate information on my credit report.','The following accounts were closed by me and should state that','The following account was a Bankruptcy/Charge-off. Balance should be $0','I never gave written permission for this account to be  on my consumer report','This account is not reporting to Experian or Equifax','This account is in violation of my consumer rights'],
        instructionOptions: ['Please correct/update this inaccurate information on my credit report.','Please remove it from my credit report.','This is not mine. I am a victim of ID Theft and I have included a police report. Please investigate and remove from my credit report.','Please supply information on how you have verified this item.','Please ensure that all information is accurate'],
        userFeedback : {
          inquiry : {
            check : [],
            status : {
              transunion : 'Negative',
              experian : 'Negative',
              equifax : 'Negative'
            },
            reason :'',
            instruction : '',
            new_reason: false,
            new_instruction: false
          },
          public_record : [],
          account_history: []
        },
        letterNameOptions : [],
        letter_category : '',
        step2: false,
        step3 :false,
       
      }
    },

    methods: {
      tableExpand(flag, index){
        

        if (flag === 1) {
          if(!this.displayFlag.includes(index))
            this.displayFlag.push(index);
        } else if(flag === 2) {
         const tmp = this.displayFlag;
         this.displayFlag = [];
         tmp.forEach(val => {
           if(val != index){
             this.displayFlag.push(val);
           }
         })
        }
      },
    assignDisputeItems(){
      this.disputeItems = this.seletedPendingItems;
      let self = this;
      var filtered = this.pendingItems.filter(function(value, index, arr){ 
        if(!self.seletedPendingItems.includes(value)){
            return value
        }
        
    });
    this.pendingItems = filtered;
    this.addPendingItemDialog = false;
    },
    saveLetter(){
      let self = this;
      self.overlay = true;
      let id  = this.$route.params.id;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        let req = JSON.parse(localStorage.getItem('credit-report-step-3'));
        axios
        .post("/api/save-client-letter",{
          id : id,
          letter_round : this.letter_round,
          letter_name : this.letter_name,
          transunion_letter : this.transunion_letter,
          experian_letter : this.experian_letter,
          equifax_lettr : this.equifax_lettr
        },header)
        .then(response => {
              Vue.$toast.success('Letter saved', {
              position: 'top-right'
              });
              if(this.role.name == 'client')
                this.$router.push('/send-letter/'+this.$route.params.id)
              else
                this.$router.push('/admin/send-letter/'+this.$route.params.id)
              this.overlay = false;
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
            this.overlay = false;
           });
    },
    generateLtterSave(){
      if(!this.letter_category || !this.letter_name){
        Vue.$toast.error('Select Letter to continue', {
            position: 'top-right'
            });
      }else{
        localStorage.setItem('credit-report-step-3',JSON.stringify([{
          dispute_items : this.disputeItems,
          letter_category : this.letter_category,
          letter_name : this.letter_name
        }]))
        if(this.role.name == 'client')
          this.$router.push('/credit-report-wizard-step-3/'+this.$route.params.id)
        else
          this.$router.push('/admin/credit-report-wizard-step-3/'+this.$route.params.id)
      }
    },
    unAssignDisputeItems(item){
      let self = this;
      var filtered = this.disputeItems.filter(function(value, index, arr){ 
        if(value != item){
            return value
        }
        
    });
    this.disputeItems = filtered;
    this.pendingItems.push(item);
    this.addPendingItemDialog = false;
    this.seletedPendingItems = [];
    },
    generateLeter(){
      if(this.disputeItems.length == 0){
        Vue.$toast.error('No item seleted', {
            position: 'top-right'
            });
      }else{
        this.step3 = true;
      }
    },
    getUserProfile() {
    let flag = 1;
    let self = this;
    self.overlay = true;
    let id  = this.$route.params.id;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-saved-letter/"+id,header)
        .then(response => {
              this.saved_letters = response.data.value
              this.overlay = false;
              this.getUserProfileOld(this.saved_letters[0].user_id);
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
    calculatePendingItems(){
      let item = JSON.parse(localStorage.getItem('credit-report-saved-items'))
      let id = 1;
      console.log(item);
      if(item.inquiry.check.length != 0){
        item.inquiry.check.forEach(val => {
          this.pendingItems.push({
            transunion : val.includes(",transunion") ? 1 : 0,
            experian : val.includes(",experian") ? 1 : 0,
            equifax : val.includes(",equifax") ? 1 : 0,
            creditor : val.split(',')[0],
            account : {
              transunion : '',
              experian : '',
              equifax : ''
            },
            reason : item.inquiry.reason,
            disputed : 'No',
            id : id
          })
          id = id+1;
        })
      }
      item.public_record.forEach(val => {
        if(val.status.transunion == 'Negative' || val.status.experian == 'Negative' || val.status.equifax == 'Negative'){
          this.pendingItems.push({
            transunion : val.status.transunion == 'Negative' ? 1 : 0,
            experian : val.status.experian == 'Negative' ? 1 : 0,
            equifax : val.status.equifax == 'Negative' ? 1 : 0,
            creditor : val.creditor,
            account : {
              transunion : val.status.transunion == 'Negative' ? val.account.transunion.substring(0, 5) + '****' : '',
              experian : val.status.experian == 'Negative' ? val.account.experian.substring(0, 5) + '****' : '',
              equifax : val.status.equifax == 'Negative' ? val.account.equifax.substring(0, 5) + '****': ''
            },
            reason : val.reason,
            disputed : 'No',
            id : id
          })
          id = id+1;
        }
      })
      item.account_history.forEach(val => {
        if(val.status.transunion == 'Negative' || val.status.experian == 'Negative' || val.status.equifax == 'Negative'){
          this.pendingItems.push({
            transunion : val.status.transunion == 'Negative' ? 1 : 0,
            experian : val.status.experian == 'Negative' ? 1 : 0,
            equifax : val.status.equifax == 'Negative' ? 1 : 0,
            creditor : val.creditor,
            account : {
              transunion : val.status.transunion == 'Negative' ? (val.account.transunion ? val.account.transunion.substring(0, 5) + '****' : '') : '',
              experian : val.status.experian == 'Negative' ? (val.account.experian ? val.account.experian.substring(0, 5) + '****' : '') : '',
              equifax : val.status.equifax == 'Negative' ? (val.account.equifax ? val.account.equifax.substring(0, 5) + '****' : ''): ''
            },
            reason : val.reason,
            disputed : 'No',
            id : id
          })
          id = id+1;
        }
      })
      
    },

    sendFax(){
      if(this.radioFax == 'skip'){
        this.faxSendFlag = true;
      }else{
        let flag = 1;
        this.overlay = true;
        let id =this.$route.params.id ;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
          axios
            .post("/api/send-fax/" + id,{data : this.seletedLetters,'user_id' : this.user_id},header)
            .then(response => {
                if (response.data.status == true) {
                  this.faxSendFlag = true;
                  this.overlay = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
            })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });

              });

      }
    },

    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })
                  //this.$router.push('company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },
    getCategory() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-category",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.categories = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     downloadFile(key){
      let self = this;
      let url = process.env.VUE_APP_WASBI_URL.replace('/logo','')
      this.docs.forEach(val =>{
        console.log(val);
        if(val.file_name == key){
          window.open(url+val.folder_id+'/'+val.file_name, '_blank');
        }
      })
      },
      deleteFile(key){
        let self = this;
        let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        if(confirm('Are you sure, You want to remove?')){
          this.docs.forEach(val =>{
            if(val.file_name == key){
              axios
                  .get("/api/delete-file-single/" + val.id,header)
                  .then(response => {
                      let data = response.data.data;
                      self.docs = []
                      self.getUserProfile()
                        Vue.$toast.success("File removed successfully", {
                          position: 'top-right'
                        });
                  })
                    .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });

                    });
            }
          })
        }
      },
      
     getUserProfileOld(id) {
      let flag = 1;
      this.user_id = id;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .get("/api/get-client/" + id,header)
          .then(response => {
              let data = response.data.data;
              this.doc_id_flag = false
              this.doc_bill_flag = false
              this.doc_letter_a_flag = false
              this.doc_letter_b_flag = false
              this.doc_letter_c_flag = false
            if (flag == 1) {
              this.client = data;
               if(response.data.doc.length != 0){
                this.docs = []
                this.docs = response.data.doc
                response.data.doc.forEach(element => {
                  if(element.file_name.includes("ID")){
                    this.doc_id_flag = true;
                    this.doc_id_value = element.file_name
                  }
                  if(element.file_name.includes("Bill")){
                    this.doc_bill_flag = true;
                    this.doc_bill_value = element.file_name
                  }
                  if(element.file_name.includes("Letter_A")){
                    this.doc_letter_a_flag = true;
                    this.doc_letter_a_value = element.file_name
                  }
                  if(element.file_name.includes("Letter_B")){
                    this.doc_letter_b_flag = true;
                    this.doc_letter_b_value = element.file_name
                  }
                  if(element.file_name.includes("Letter_C")){
                    this.doc_letter_c_flag = true;
                    this.doc_letter_c_value = element.file_name
                  }
                  

                });
              }
              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }

          })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });

            });

      },
      onFileChanged(e) {
      let self = this;
      let selectedFile = e.target.files[0]
      const data = new FormData();
          if(self.selectedFile == 'file_id')
            data.append('file_id', selectedFile);
          if(self.selectedFile == 'file_bill')
            data.append('file_bill', selectedFile);
          if(self.selectedFile == 'file_letter_a')
            data.append('file_letter_a', selectedFile);
          if(self.selectedFile == 'file_letter_b')
            data.append('file_letter_b',selectedFile);
          if(self.selectedFile == 'file_letter_c')
            data.append('file_letter_c',selectedFile);
          data.append('flag',self.selectedFile.replace('file_', ''));
          data.append('user_id',this.user_id);
          this.loadingUpload = true;
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          this.loadingUpload = false;
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
      // do something
    },
    onButtonClick(id) {
      this.$refs[id].click()
      this.selectedFile = id;
    },
    getPdf() {
    let flag = 1;
    this.overlay = true;
    let id =this.$route.params.id ;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .post("/api/get-pdf/" + id,{data : this.seletedLetters,'user_id' : this.user_id},header)
        .then(response => {
            this.pdf_name = response.data.name+'.pdf';
            this.overlay = false;
        })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

          });

      },
     getLeterName() {
    let id = this.letter_category;
    let client_id = localStorage.getItem('selected_client');
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-letter-category/"+id+'/'+client_id,header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.letterNameOptions = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },

 },

created() {
      console.log('created called.');
      this.getUserProfile()     
      
      
    },

}
</script>
<style >
.heading-h2 {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 38px;
  letter-spacing: normal;
  color: #4a4a4a;
}
.v-input__slot {
  align-items: center;
  justify-content: center;
}
.checkbox-credit .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  left: 0px;
  right: auto;
  position: relative;
  text-align: center!important;
  display: contents!important;
}
.red--text .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  color: red;
}

</style>

<style type="text/css">
@keyframes ldio-7xwhhkt0pnf {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-7xwhhkt0pnf div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-7xwhhkt0pnf linear 1s infinite;
  background: #ffffff;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-7xwhhkt0pnf div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}
.loadingio-spinner-spinner-2k3nmd4xown {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-7xwhhkt0pnf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-7xwhhkt0pnf div { box-sizing: content-box; }
/* generated by https://loading.io/ */
.radio-div .red--text .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  color: black!important;
}
.letter-tab .v-tab{
  border-right :solid 1px #ccc !important;
  border-bottom : solid 1px #ccc;
}
.j-c-sb {
  justify-content: space-between !important;
}
.border {
  border: solid 1px #dedede !important;
}
.default-color a {
  color: #4a4a4a !important;
}
.font-500 {
  font-weight: 500 !important;
}
.font-700 {
  font-weight: 700 !important;
}
.letter-tab .v-tabs-slider-wrapper{
 display: none;
}
.letter-tab .v-tab--active {
  border-bottom : solid 0px #dedede !important;
}
</style>