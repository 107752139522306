<template>
    <v-app id="inspire">
    <v-main>

  </v-main>
   
    </v-app>
  </template>
  
  <script>
  import moment from 'moment';
  export default {
      components: {},
      data () {
        return {
          header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
          },
          rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 3 || 'Min 3 characters',
            emailMatch: () => (`The email and password you entered don't match`),
          },
          loading:false,
          dialog:false,
          initials:'',
          valid:true,
          selected_job:'',
          selected_project:'',
          checkbox: '',
          headers_jobs: [
              {
              text: 'User Id',
              align: 'start',
              sortable: false,
              value: 'user_id',
            },
            { text: 'Type', value: 'is_admin',
              sortable: false },
            { text: 'First Name', value: 'first_name',
              sortable: false },
            { text: 'Last Name', value: 'last_name',
              sortable: false },
            { text: 'Username', value: 'user_name',
              sortable: false },
            { text: 'Email', value: 'email',
              sortable: false },
            { text: 'Created At', value: 'created_at',
              sortable: false },
            { text: 'Updated At', value: 'updated_at',
              sortable: false },
          ],
          projects: [
            
          ],
          users: []
        }
      },
      created () { 
          this.getUsers();
      },
      methods:{
  
          formatDate(value) {
              return moment(value).format("DD MMM YYYY")
          },
          getUsers(){
            let company_id = localStorage.getItem('ghl_callback_company_id')
            let code = this.$route.query.code;
              this.loading = true;
              this.selected_project = name;
              axios
              .post("/api/ghl_chat_enable",{ company_id : company_id, code : code},this.header)
              .then(response => {
                  let data = response.data;
  
                this.$router.push('/admin/company-profiles')
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
          },
  
      }
      
  }
  </script>
  
  <style>
  
  </style>