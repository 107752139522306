<template>
  <v-app id="inspire" style="background:#F9F9F9;">
  <vue-header-admin :key="keyCount"></vue-header-admin>
      <v-main>
      <v-container fluid class="px-6 py-6">
          <v-row
              align="center"
              justify="center"
            >
              <v-col cols="12" md="12">
                 <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                  <v-card-text style="margin-bottom: -30px;">
                    <v-row>
                      <v-col
                        cols="6"
                        md="6"
                      >
                      <h5 class="text-h4 font-weight-bold text-typo">QR Codes Invites({{totalCount}})</h5>
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                      >
                      <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                      <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                      </v-col>
                       <!-- <v-col cols="12"
                        md="6" >
                           <v-btn
                                      color="#4CAF50"
                          class="
                           ont-weight-bolder
                            btn-default
                            mr-5
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-4
                                          
                                        "
                                          small
                                          @click="$router.push('add-company')"
                                      >
                                          Add Company
                                      </v-btn>
                                      <v-btn
                                      color="red"
                          class="
                           ont-weight-bolder
                            btn-default
                            mr-5
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-4
                                          
                                        "
                                          small
                                          dark
                                          @click="deleteItem('all')"
                                      >
                                          mass delete
                                      </v-btn>
                                      <vue-json-to-csv :json-data="users"
                                      :labels="{ name: { title: 'Name' },contact_email : {title : 'Contact Email'},contact_phone : {title : 'Contact Phone'},details : {title : 'Details'},created_at : {title : 'Created At'} }"
                                      csv-title="company"
                                      >
                                       <v-btn
                                      color="blue"
                          class="
                           ont-weight-bolder
                            btn-default
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-4
                                          
                                        "
                                      dark
                                          small
                                      >
                                          Export to csv
                                      </v-btn>
                                    </vue-json-to-csv>
                        </v-col> -->
                      
                        <!-- <v-col cols="12"
                        md="6" >
                           <v-btn
                                      color="#4CAF50"
                          class="
                           ont-weight-bolder
                            btn-default
                            mr-5
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-5
                                          
                                        "
                                          small
                                          @click="dialogDoc = true"
                                      >
                                      <i class="fa fa-plus mr-2" aria-hidden="true"  ></i> Create QR Code
                                      </v-btn>
                                      <v-btn
                                      color="red"
                          class="
                           ont-weight-bolder
                            btn-default
                            mr-5
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-4
                                          
                                        "
                                          small
                                          dark
                                          @click="deleteItem('all')"
                                      >
                                          mass delete
                                      </v-btn>
                                      </v-col>
                        <v-col cols="12"
                        md="6" >
                              <v-text-field
                                 v-model="search"
                                  label="Search"
                                  single-line
                                  clearable
                                  @inout="getUsers()"
                                  hide-details
                              >
                                  <template v-slot:append>
                                  
                                  </template>
                                  <template v-slot:append-outer>
                                      <v-btn
                                      color="#4CAF50"
                          class="
                           ont-weight-bolder
                            btn-default
                            py-4
                            px-8
                            ms-auto
                            mt-sm-auto mt-4
                                          
                                        "
                                          small
                                          @click="getUsers"
                                      >
                                          Submit
                                      </v-btn>
                                  </template>
                              </v-text-field>
                      </v-col> -->
                    
                      <v-col cols="12"
                        md="12">
                        
                          <v-data-table
                              :headers="headers_jobs"
                              :options.sync="datatable_options"
                              :items="users"
                              show-select
                              v-model="selectedItems"
                              loading-text="Loading... Please wait"
                              :page.sync="page"
                              :items-per-page="itemsPerPage"
                              class="table mobile"
                              :hide-headers="1"
                              :footer-props="{
                                      firstIcon: '',
                                      lastIcon: '',
                                      prevIcon: '',
                                      nextIcon: '',
                                      'page-text': false,
                                      'items-per-page-options': [10, 50, 100,-1]
                                    }"
                          >
                                    
                          <template v-slot:item.status="{ item }">
                              {{item.status == 1 ? 'Active' :'Inactive'}}
                          </template>
                          <template v-slot:item.name="{ item }">
                            <div class="qr-manage-code__type ng-binding mt-5"  data-test="manage-qrcode-list-item-type">{{item.type}}</div>
                              <div  class="qr-manage-code__title mt-2"><i class="fa fa-globe" aria-hidden="true" v-if="item.type =='Website'" ></i><i class="fa fa-address-card" aria-hidden="true" v-if="item.type =='VCard'" ></i> {{item.name }} </div>
  
                              <div class="qr-manage-code--row-first--col-left"><span class="qr-manage-code__folder-icon icon icon-qr-folder-outline"></span><div class="qr-manage-code__date ng-binding"><span class="qr-manage-code__date-icon icon icon-business-time"></span><i class="fa fa-clock mr-3" aria-hidden="true" ></i> {{getDate(item.created_at)}}</div><div class="qr-manage-code__date ng-binding"><span class="qr-manage-code__date-icon icon icon-business-time"></span><i class="fa fa-share mr-3" aria-hidden="true" style="color:#47bdef" ></i> <span style="color:#47bdef">{{item.invited_by}}</span></div></div>
  
                              <div class="qr-manage-code--row-first--col-right">
                              <div v-if="!$vuetify.breakpoint.xs" class="qr-manage-code__url" ng-show="::(getUrl() || !value.trackable)"><span class="qr-manage-code__url-icon icon icon-qr-link" ng-show="::getUrl()"></span><div class="qr-manage-code__url-container"><a class="qr-manage-code__url-link ng-binding"  target="_blank"  ng-bind="getUrl()" data-test="manage-qrcode-list-item-shorturl" :href="app_url+'/redirect-qr/'+item.id"><i class="fa fa-link mr-3" aria-hidden="true" ></i>{{app_url}}/redirect-qr/{{item.id}}</a><!-- ngIf: !isAbTestEnabled_CNV2467() --><i ng-if="!isAbTestEnabled_CNV2467()" class="qr-manage-code__edit-icon icon icon-avatar-edit ng-scope" ng-show="::getUrl()" ng-click="openShortUrlEdit()" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit Short URL"></i><!-- end ngIf: !isAbTestEnabled_CNV2467() --><!-- ngIf: isAbTestEnabled_CNV2467() --></div><div class="qr-manage-code__target ng-scope" ng-show="getTarget()" ng-if="!isDemo"><span class="qr-manage-code__target-icon icon icon-qr-link-arrow" ng-show="::value.trackable"></span><a  target="_blank" data-test="manage-qrcode-list-item-url" class="ng-binding" :href="item.link"><i class="fa fa-arrow-down mr-3" aria-hidden="true" ></i> {{item.link}}<span  class="ng-binding"></span></a></div><!-- end ngIf: !isDemo --></div>
                              </div>
                          </template>
                          <template v-slot:item.scan="{ item }">
                            <div class="qr-manage-code__stats" ng-show="::value.trackable"><div class="qr-manage-code__scans ng-binding" ng-bind="getTotalScans()">{{item.scan_count}}</div><div class="qr-manage-code__scans-label ng-binding" ng-bind="::scansLabel">Scans</div><!-- ngIf: !isAbTestEnabled_CNV2467() --><a ng-if="!isAbTestEnabled_CNV2467()" ng-href="#!/insights?folder=active&amp;id=59498223" class="qr-manage-code__insights ng-binding ng-scope" ng-click="openInsights($event)" data-test="manage-qrcode-list-item-open-insights"  @click="$router.push('/admin/qr-detail/'+item.id)">Details<i class="fa fa-arrow-right ml-2" aria-hidden="true" style="color:  #47bdef ;"></i></a> <a ng-if="!isAbTestEnabled_CNV2467()" ng-href="#!/insights?folder=active&amp;id=59498223" class="qr-manage-code__insights ng-binding ng-scope" ng-click="openInsights($event)" data-test="manage-qrcode-list-item-open-insights"  @click="selected_qr = item,dialogEdit= true"><i class="fa fa-pen mr-2" aria-hidden="true" style="color:  #47bdef ;"></i> Edit</a><a ng-if="!isAbTestEnabled_CNV2467()" ng-href="#!/insights?folder=active&amp;id=59498223" class="qr-manage-code__insights ng-binding ng-scope" ng-click="openInsights($event)" data-test="manage-qrcode-list-item-open-insights"  @click="selected_qr = item,creditShareDialog= true"><i class="fa fa-share mr-2" aria-hidden="true" style="color:  #47bdef ;"></i> Share</a></div>
                          </template>
                           <template v-slot:item.phone="{ item }">
                              {{item.phone_m ? item.phone_m : "Not Avilable"}}
                          </template>
                          <template v-slot:item.date_of_birth="{ item }">
                              {{dateFormat(item.dob)}}
                          </template>
                          <template v-slot:item.password="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                              @click="dialogPassword = true, selectedPasswordId = item.id,selectedPassword = generatePassword()"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                key
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.edit="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="$router.push('edit-company/'+item.id)"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                edit
                              </v-icon>
                            </v-btn>
                          </template>
  
                          <template v-slot:item.url="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="dialogURL =true, company_url = item.company_url ? item.company_url : ''"
                              x-small
                              color="orange"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                visibility
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.qr="{ item }">
                            <div style="text-align: center;">
                               <img v-if="item.custom_qr_upload" :src="api_url+'qrcust_'+item.id+'.'+item.custom_qr_ext" height="130" class="mt-2">
                               <img v-else :src="api_url+'qr_'+item.id+'.png'" height="130" class="mt-2">
                               </div>
                            </template>
                          <template v-slot:item.agreement_name="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="viewContract(item.id)"
                              x-small
                              color="orange"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                visibility
                              </v-icon>
                            </v-btn>
                          </template>
  
                          <template v-slot:item.credit_new="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'add'"
                              x-small
                              color="green"
                            >
                               <v-icon size="20" class="material-icons-round opacity-10" dark>
                                add_card
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.credit_remove="{ item }">
                               <v-btn
                              class="mx-2"
                              text
                              dark
                             @click="creditDialog = true,selectedCompany=item.id,creditFlag = 'remove'"
                              x-small
                              color="red"
                            >
                               <v-icon size="20" class="material-icons-round opacity-10" dark>
                                credit_card_off
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.address="{ item }">
                          <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                          </span>
                          </template>
                          <template v-slot:item.status_id="{ item }">
                             <v-btn
                              class="mx-2"
                              dark
                              @click="changeStatus(item.user_id,item.status)"
                              x-small
                              :color="item.status==1 ? 'red':'green'"
                            >
                            {{item.status==1 ? 'Deactivate':'Activate'}}
                            </v-btn>
                          </template>
                          <template v-slot:item.document="{ item }">
                            {{item.is_uploaded_doc == 1 ? 'Available' : 'Not available'}}
                          </template>
                          <template v-slot:item.process1="{ item }">
                            <v-btn
                              class="mx-2"
                              dark
                              @click="dialogDoc = true, selected_id = item.user_id"
                              x-small
                              v-if="item.process_1 == 0"
                              :color="'green'"
                            >
                            Start
                            </v-btn>
                            <v-btn
                              class="mx-2"
                              disabled
  
                              @click="dialogDoc = true"
                              x-small
                              v-if="item.process_1 == 1"
                              :color="'green'"
                            >
                            Finished
                            </v-btn>
                          </template>
                          <template v-slot:item.process_1_key="{ item }">
                            <v-btn
                              class="mx-2"
                              
                              @click="dialogDoc = true, selected_id = item.user_id"
                              x-small
                              disabled
                              v-if="item.process_1 == 0"
                              :color="'green'"
                            >
                            View
                            </v-btn>
                            <v-btn
                              class="mx-2"
                              dark
                              @click="getProcessStatus(item.process_1_key)"
                              x-small
                              v-if="item.process_1 == 1"
                              :color="'blue'"
                            >
                            View
                            </v-btn>
                          </template>
                          <template v-slot:item.ghl_flag="{ item }">
                             <v-btn
                              class="mx-2"
                              dark
                              text
                              @click="syncGhl(item.id, item.ghl_flag)"
                              x-small
                              :color="item.ghl_flag==1 ? 'green':'red'"
                            >
                            <v-icon v-if="item.ghl_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                toggle_on
                              </v-icon>
                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                toggle_off
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.ghl_chat_flag="{ item }">
                             <v-btn
                              class="mx-2"
                              dark
                              text
                              @click="syncGhlChat(item.id, item.ghl_chat_flag)"
                              x-small
                              :color="item.ghl_chat_flag==1 ? 'green':'red'"
                            >
                            <v-icon v-if="item.ghl_chat_flag==1" size="40" class="material-icons-round opacity-10" dark>
                                toggle_on
                              </v-icon>
                              <v-icon v-else size="40" class="material-icons-round opacity-10" dark>
                                toggle_off
                              </v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.id="{ item }">
                            <v-btn
                              class="mx-2"
                              dark
                              style="text-transform: none;"
                              @click="updateInvite(item.qr_rel_id, 1)"
                              color="green"
                            >
                            <v-icon  class="material-icons-round opacity-10 mr-1" dark>
                                check
                            </v-icon> Accept
                            </v-btn>
                          <v-btn
                              class="mx-2 mt-2"
                              dark
                              style="text-transform: none;"
                              @click="updateInvite(item.qr_rel_id, 0)"
                              color="red"
                            >
                            <v-icon size="20" class="material-icons-round opacity-10 mr-1" dark>
                                close
                              </v-icon>Reject
                            </v-btn>
                            
                         
                          </template>
                          </v-data-table>
                          <div class="text-center pt-2">
                              <v-pagination
                              prev-icon="fa fa-angle-left"
                              next-icon="fa fa-angle-right"
                              class="pagination"
                              circle
                              color="#4CAF50"
                                  v-model="page"
                                  :length="pageCount"
                                  @input="getUsers"
                              ></v-pagination>
                              </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
          <v-dialog
            v-model="dialogDoc"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Create QR Code
              </v-card-title>
              <div  class="mt-5 mb-5">
                <Login v-if="dialogDoc" />
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogDoc = false,getUsers()"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
          persistent
        v-model="dialogPassword"
        width="500"
      >
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Reset Password
          </v-card-title>
  
          <v-card-text style="margin-top:30px;"> 
             <rotate-loader class="mt-3 mb-3" :loading="loadingUpload2" color="#1D75F4"></rotate-loader>
             <v-radio-group   v-model="selectedPasswordRadio">
                   <v-radio
                      :label="`Automatically generate a password`"
                      :value="1"
                     
                    ></v-radio>
                      <v-text-field
                          id="name"
                          name="name"
                          outlined
                          class="mt-3"
                          style="password"
                          v-if="selectedPasswordRadio == 1"
                          label="Password"
                          width="300px"
                          ref="name"
                          v-model="selectedPassword"
                          />
                     <v-radio
                      :label="`Create password`"
                      :value="0"
                    ></v-radio>
                      <v-text-field
                          id="name"
                          name="name"
                          class="mt-3"
                          style="password"
                          v-if="selectedPasswordRadio == 0"
                          outlined
                          label="Password"
                          width="300px"
                          ref="name"
                          v-model="selectedPassword"
                          />
             </v-radio-group>
           
  
          </v-card-text>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loadingUpload2"
              @click="resetPassword(selectedPasswordId)"
            >
              Reset
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingUpload2"
              @click="dialogPassword = false,selectedPasswordRadio = 1"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
          <v-dialog
            v-model="creditDialog"
            width="700"
          >
           <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Move QR Code
          </v-card-title>
  
          <v-card-text style="margin-top:30px;">
            <v-select
            outlined
            dense
            :prepend-icon="'fa-folder'"
            :items="folders"
                  v-model="selected_qr.folder_id"
                  item-text="name"
                  item-value="id"
            ></v-select>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="saveQR()"
            >
              Move
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="creditDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
          </v-dialog>



          <v-dialog
            v-model="creditShareDialog"
            width="700"
          >
           <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Share QR Code
          </v-card-title>
  
          <v-card-text style="margin-top:30px;">
            <v-autocomplete
              v-model="share_selected_user"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search_user"
              color="white"
              hide-no-data
              hide-selected
              item-text="name"
              class="font-size-input input-style py-0 input-login-new"
              item-value="user_id"
              label="Search User"
              placeholder="Start typing to Search"
              prepend-icon="mdi-database-search"
              return-object
            ></v-autocomplete>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              :disabled="loadingAssign"
              @click="shareQR()"
            >
              Share
            </v-btn>
             <v-btn
              color="primary"
              text
              :disabled="loadingAssign"
              @click="creditShareDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
          </v-dialog>


           <v-dialog
            v-model="process_status_dialog"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Transaction {{transaction.Key}}
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <span class="text-h5" >
                  <b>Status: </b>
                </span>
                <span class="text-h5" v-if="!transaction.ProcessingException" >
                  {{transaction.Status}}
                </span>
                <span class="text-h5" v-else >
                  {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
                </span>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="process_status_dialog = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogEdit"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                QR Edit
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <v-select
                  :items="['Website','VCard']"
                  solo
                  dense
                  v-model="selected_qr.type"
                ></v-select>
                <v-subheader style="padding: 0px;">Enter {{selected_qr.type}} URL</v-subheader>
              <v-text-field
                color="#e91e63"
                outlined
                v-model="selected_qr.link"
                solo
                dense
              >
              </v-text-field>
            <v-subheader style="padding: 0px;">Enter QR Code Name</v-subheader>
              <v-text-field
                color="#e91e63"
                outlined
                v-model="selected_qr.name"
                solo
                dense
              >
              </v-text-field>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  text
                  @click="saveQR()"
                >
                  Save
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="dialogEdit = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
           <v-dialog
            v-model="dialogURL"
            width="700"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Company URLs
              </v-card-title>
              <div  class="mt-5 mb-5">
               <v-card-text >
                <h3><span style="color:teal;">Login: </span>{{baseUrl+'/login/'+company_url}}<v-btn
                              class="mx-2"
                              text
                              dark
                              @click="copyURL(baseUrl+'/login/'+company_url)"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                copy
                              </v-icon></v-btn></h3>
              </v-card-text>
              <v-card-text >
                <h3><span style="color:teal;">Signup: </span>{{baseUrl+'/signup/'+company_url}}<v-btn
                              class="mx-2"
                              text
                              dark
                              @click="copyURL(baseUrl+'/signup/'+company_url)"
                              x-small
                              color="blue"
                            >
                              <v-icon size="20" class="material-icons-round opacity-10" dark>
                                copy
                              </v-icon></v-btn></h3>
              </v-card-text>
              </div>
              <v-divider></v-divider>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialogURL = false"
                >
                  close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-container>
  </v-main>
  
  </v-app>
  
  </template>
  
  <script>
  import { VueTelInput } from 'vue-tel-input'
  import {Circle8} from 'vue-loading-spinner'
  import moment from 'moment';
  import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Login from '../QRGenModal.vue'
  import VueJsonToCsv from 'vue-json-to-csv'
  export default {
      components : {
        'RotateLoader': ClipLoader,DatePicker,Login,
        VueJsonToCsv
      },
      data () {
        return {
          header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
          },
          rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 3 || 'Min 3 characters',
            emailMatch: () => (`The email and password you entered don't match`),
          },
          transaction : [],
          loading:true,
          dialog:false,
          initials:'',
          valid:true,
          dialogURL : false,
          company_url : '',
          loadingUpload2:false,
          selectedItems: [],
          new_credit: '',
          selectedCompany: '',
          selectedFile : '',
          loadingAssign: false,
          creditDialog:false,
          share_selected_user : "",
          items : [],
          search_user : '',
          creditShareDialog : false,
          keyCount : 0,
          pageCount:1,
          dialogPassword: false,
          app_url : window.location.origin,
          api_url: process.env.VUE_APP_WASBI_URL,
          dialogEdit: false,
          pageSort: {},
          documentList:[],
          selected_qr : '',
          folders : [{id: "", name: "No Folder",}],
          totalCount:0,
          creditFlag: '',
          selectedPasswordId:'',
          selectedPasswordRadio: 1,
          selectedPassword:'',
          page:1,
          itemsPerPage:10,
          selected_job:'',
          search : "",
          dialogDoc:false,
          folder_id : "",
          datatable_options:{},
          offset: 0,
          selected_id : '',
          limit: 10,
          selected_project:'',
          dialogEdit : false,
          checkbox: '',
          baseUrl : window.location.origin,
          process_status_dialog : false,
          headers_jobs: [
            { text: 'Details', value: 'name',
              sortable: true },
           
              { text: 'QR', value: 'qr',
              sortable: false,width: '200', },
  
            { text: 'Download', value: 'id',
              sortable: false,width: '50' },
          ],
          projects: [
            
          ],
          users: []
        }
      },
      mounted  () {
        console.log('aa',this.$router);
        if(this.$route.params.id)
          this.folder_id = this.$route.params.id
      },
      created () { 
        console.log('aaa',this.$route);
        
          if(!localStorage.getItem('token'))
              this.$router.push('/login')
        this.getLinks();
      },
      methods:{
        async copyURL(mytext) {
        try {
          await navigator.clipboard.writeText(mytext);
          alert('Copied');
        } catch($e) {
          alert('Cannot copy');
        }
      },
      getDate(date){
        return moment(date).format('ll');
      },
      getLinks(){
          let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
          axios
            .get("/api/get-all-folder", {headers: headers})
            .then(response => {
              response.data.data.forEach(element => {
                this.folders.push(element)
              });
              
        })
      },
      downloadFile(url) {
  
        const imageName = 'your-image.png'; // Replace with the actual image name
        const downloadUrl = url;
  
        // Create a hidden link to trigger the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.style.display = 'none';
        document.body.appendChild(link);
        window.open(downloadUrl, '_blank');
        // Trigger the download
        // link.click();
  
        // Remove the link from the document
        document.body.removeChild(link);
      },
      viewContract(id){
        let url = process.env.VUE_APP_WASBI_URL
        window.open(url+id+'_user_agreement'+'.pdf', '_blank');
      },
        dateFormat(date){
          return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
        },
          formatDate(value) {
              return moment(value).format("DD MMM YYYY")
          },
          addCredit(){
            let id = this.selectedCompany;
             var r = this.creditFlag == 'remove' ? confirm("Are you sure, You went to remove credit?") : confirm("Are you sure, You went to add credit?");
            if (r == true) {
              this.loadingAssign = true;
              axios
                .post("/api/add-credit-admin/"+id,{
                  credit : this.new_credit,
                  flag : this.creditFlag
                },this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                  if(this.creditFlag == 'remove'){
                    Vue.$toast.success('Credit removed successfully', {
                        position: 'top-right'
                        });
                  }else{
                    Vue.$toast.success('Credit added successfully', {
                        position: 'top-right'
                        });
                  }
                        this.creditDialog = false;
                        this.new_credit = 0;
                        this.loadingAssign = false;
                      this.getUsers();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                        this.loadingAssign = false;
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          StartProcess1(flag){
            let id = this.selected_id;
            var r = confirm("Are you sure, You went to start process 1?");
              if (r == true) {
                this.loading = true;
                this.dialogDoc = false;
                      axios
                  .get("/api/process1/"+id+"/"+flag,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                          Vue.$toast.success('Process started successfully', {
                              position: 'top-right'
                              });
                              this.loading = false;
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                              this.loading = false;
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          openDocModal(id){
            
            this.dialogDoc = true;
            axios
              .get("/api/get-documents/"+id,this.header)
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                  this.documentList = data.data;
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
             });
          },
          editItem(id){
            this.$router.push('/add-parking/'+id)
          },
          onFileChanged(e) {
            let self = this;
            let file = e.target.files[0]
            const data = new FormData();
              data.append('file',file);
              data.append('id',this.selectedFile);
              this.loading = true
                axios({
                method: "POST",
                url: "/api/upload-custom-qr",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                Vue.$toast.success("QR uploaded", {
                    position: 'top-right'
                    });
                self.getUsers();
                this.loading = false
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    this.isDisableUpload = false;
                    this.isDarkUpload = true;
                });
  
          },
          generatePassword() {
              var length = 8,
                  charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                  retVal = "";
              for (var i = 0, n = charset.length; i < length; ++i) {
                  retVal += charset.charAt(Math.floor(Math.random() * n));
              }
              return retVal;
          },
          onButtonClick(id) {
            this.$refs['file'].click()
            this.selectedFile = id;
          },
          deleteItem(id){
            let multiple_flag = 0
            if(id == 'all')
              multiple_flag = 1
            var r = confirm("Are you sure, You went to delete?");
            if(id == 'all' && this.selectedItems.length == 0){
              Vue.$toast.error("Select atleast one item to delete", {
                position: 'top-right'
                });
                r = false
            }
            if (r == true) {
              axios
                .post("/api/delete-qr/"+id,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;
  
                if (response.data) {
                    Vue.$toast.success('Deleted successfully', {
                        position: 'top-right'
                        });
                      this.getUsers();
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
  
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              })
            }
          },
          changeStatus(id,value){
              this.loading = true
               var r = confirm("Are you sure, You went to change status?");
              if (r == true) {
                      axios
                  .get("/api/change-status/"+id+"/"+value,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                          Vue.$toast.success('Profile status changed successfully', {
                              position: 'top-right'
                              });
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          resetQR(id){
              this.loading = true
               var r = confirm("Are you sure, You went to reset QR to Origianl?");
              if (r == true) {
                      axios
                  .get("/api/reset-qr-image/"+id,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                         
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          updateInvite(id, flag){
              this.loading = true
               var r = flag == 0 ? confirm("Are you sure, You went to reject?") : confirm("Are you sure, You went to accept?");
              if (r == true) {
                      axios
                  .get("/api/qr-invite-update/"+id+"/"+flag,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                         
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          saveQR(){
              this.loading = true
                      axios
                  .post("/api/qr-save",this.selected_qr,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                              this.dialogEdit = false;
                              this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              
          },
          shareQR(){
              this.loading = true
              if(this.share_selected_user){
                      axios
                  .post("/api/qr-share",{id : this.selected_qr.id, user : this.share_selected_user.user_id},this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                        Vue.$toast.success('QR Code shared', {
                              position: 'top-right'
                              });
                              this.loading = false
                              this.creditShareDialog = false;
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                    }else{
                      Vue.$toast.error('Please select one user', {
                      position: 'top-right'
                      });
                      this.loading = false
                    }
                  
              
          },
          syncGhlChat(id,value){
            if(value == 0){
            var r = confirm("Are you sure, You went to sync with GHL?");
              if (r == true) {
                localStorage.setItem('ghl_callback_company_id',id)
                let url = "https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri="+process.env.VUE_APP_GHL_REDIRECT_URL+"&client_id="+process.env.VUE_APP_GHL_CLIENT_ID+"&scope=contacts.readonly%20conversations.readonly%20conversations.write%20conversations/message.readonly"
                window.location.href = url;
  
  
              }
            }
          },
          syncGhl(id,value){
            var r = confirm("Are you sure, You went to sync with GHL?");
              if (r == true) {
                this.loading = true;
                      axios
                  .post("/api/sync-ghl/"+id+"/"+value,this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                        if(value == 1)
                          Vue.$toast.success('GHL sync turned off successfully', {
                              position: 'top-right'
                              });
                        else
                            Vue.$toast.success('GHL synced successfully', {
                            position: 'top-right'
                            });
                        this.getUsers();
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  return false;
              }
          },
          getUsers(){
              this.loading = true;
              this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
              this.selected_project = name;
              let req = {
                limit : this.datatable_options.itemsPerPage,
                offset : this.offset,
                sort : this.datatable_options.sortBy,
                sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                search : this.search,
                folder_id : this.folder_id,
                invite : true,
              }
              axios({
            method: "POST",
             
            url: "/api/get-qrcodes",
            data: req,
             header : { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
              }
          })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.users = data.data;
                  this.pageCount = data.total_page
                  this.totalCount = data.count
                  console.log( this.users);
                  this.keyCount = this.keyCount + 1
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
          },
          resetPassword(id){
            this.loading = true
               var r = confirm("Are you sure, You went to reset password?");
              if (r == true) {
                this.loadingUpload2 = true;
                      axios
                  .post("/api/reset-password-company/"+id,{password: this.selectedPassword},this.header)
                  .then(response => {
                      let data = response.data;
  
                      if (response.data) {
                          this.loading = false
                          Vue.$toast.success('Password reset successfully', {
                              position: 'top-right'
                              });
                              this.selectedPasswordRadio = 1;
                              this.dialogPassword = false;
                               this.loadingUpload2 = false;
                          }else {
                              Vue.$toast.error(data.message, {
                              position: 'top-right'
                              });
                          }
  
                      })
                      .catch(err => {
                      Vue.$toast.error(err, {
                      position: 'top-right'
                      });
                      })
                  
              }else{
                  this.loading = false
                  return false;
              }
          },
          searchUser(val){
            axios({
                method: "POST",
                 
                url: "/api/get-search-users",
                data : {search : val},
                header : { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
                  }
              })
              .then(response => {
                  
                  this.items = response.data.data;
              })
          },
          getProcessStatus(id){
              this.loading = true;
              this.offset = (this.page - 1) * 10
              axios({
                method: "GET",
                 
                url: "/api/get-process-status/"+id,
                header : { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
                  }
              })
              .then(response => {
                  
                  let data = response.data;
  
              if (response.data) {
                console.log(data);
                  this.transaction = data.data.value[0];
                  console.log(this.transaction);
                  this.process_status_dialog = true;
                  this.loading = false;
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }
  
              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
  
             });
          },
          
          
  
      },
      watch: {
              datatable_options: {
                  handler () {
                      console.log(this.datatable_options);
                  this.getUsers()
                  },
                  deep: true,
              },
              search_user (val) {
                    if(val)
                      this.searchUser(val);
              },
          },
      
  }
  </script>
  
  <style >
  .qr-manage-code__title {
    color: #797d80;
    border-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    padding: 2px 8px;
    margin: 0 0 10px;
  
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    line-height: 1.2;
    vertical-align: middle;
    position: relative;
    left: -9px;
  }
  .qr-manage-code__type {
    font-size: 12px;
    color: #d4d4d4;
    line-height: 1;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    cursor: default;
  }
  .qr-manage-code__folder {
    font-size: 14px;
    color: #a4a6a8;
    white-space: nowrap;
    cursor: default;
  }
  .qr-manage-code__date {
    font-size: 14px;
    color: #a4a6a8;
    white-space: nowrap;
    position: relative;
    cursor: default;
  }
  .qr-manage-code__url {
    border-left: 1px solid #f7f7f7;
    padding: 0 0 0 55px;
    margin: 0;
    color: #a4a6a8;
    font-size: 14px;
    position: relative;
    float: left;
    top: -50px;
    left: 135px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 255px;
  }
   .qr-manage-code__url-link {
    color: #797d80;
    display: inline-block;
  }
  .qr-manage-code__url a {
    max-width: 100%;
    white-space: nowrap;
    display: inline-block;
    color: inherit;
    text-decoration: none;
    color: #797d80;
    display: inline-block;
  }
  .qr-manage-code__target a {
    width: fit-content;
    padding: 1px 6px;
    margin: -1px 0 0 -6px;
    z-index: 1;
  }
  .qr-manage-code__stats {
  
    right: 385px;
    top: 30px;
    border-left: 1px solid #f7f7f7;
    padding: 6px 0 5px 24px;
    margin: 0;
    width: 130px;
  }
  .qr-manage-code__scans {
    color: #797d80;
    font-size: 25px;
    font-weight: 600;
    line-height: 1;
    cursor: default;
  }
  .qr-manage-code__scans-label {
    font-size: 14px;
    color: #a4a6a8;
    line-height: 1;
    cursor: default;
  }
  .qr-manage-code__insights {
    display: inline-block;
    border: 0;
    background: transparent;
    color: #47bdef;
    padding: 0;
    margin: 7px 0 0;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  }
  @media screen and (max-width: 768px) {
        .mobile table.v-table tr {
          max-width: 100%;
          position: relative;
          display: block;
        }
  
        .mobile table.v-table tr:nth-child(odd) {
          border-left: 6px solid deeppink;
        }
  
        .mobile table.v-table tr:nth-child(even) {
          border-left: 6px solid cyan;
        }
  
        .mobile table.v-table tr td {
          display: flex;
          width: 100%;
          border-bottom: 1px solid #f5f5f5;
          height: auto;
          padding: 10px;
        }
  
        .mobile table.v-table tr td ul li:before {
          content: attr(data-label);
          padding-right: .5em;
          text-align: left;
          display: block;
          color: #999;
  
        }
        .v-datatable__actions__select
        {
          width: 50%;
          margin: 0px;
          justify-content: flex-start;
        }
        .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
          background: transparent;
        }
  
      }
      .mobile .v-data-table__mobile-row__header {
      display: none;
  }
  
  .mobile  .v-data-table__mobile-row__cell {
      text-align: left !important;
  }
  .mobile .v-data-table__mobile-row{
    justify-content: center;
  }
  </style>
  