<template>
  <v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6">
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <v-card-text style="margin-bottom: 30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Projects</h5>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                        <v-data-table
                            :headers="headers"
                            :items="projects"
                            :items-per-page="10"
                            class="table"
                        >
                        <template v-slot:item.updated="{ item }">
                            {{ formatDate(item.updated) }}
                        </template>
                        <template v-slot:item.name="{ item }">
                            <a style="color:#2196F3;" @click="getJobs(item.name),jobCount = item.jobsInProject" > {{ item.name }}</a>
                        </template>
                        
                        </v-data-table>
                    </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                   <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl mt-5">
                <v-card-text style="margin-bottom: 30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Project Jobs</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <v-btn
                     style="float:right;text-transform: none;"
                      class="ma-2"
                      :loading="loading"
                      :disabled="loading"
                      dark
                      color="red"
                      @click="seletedItemsProcess('delete')"
                    >
                      Delete seleted
                    </v-btn>
                    <v-btn
                     style="float:right;text-transform: none;"
                      class="ma-2"
                      :loading="loading"
                      :disabled="loading"
                      dark
                      color="blue"
                      @click="seletedItemsProcess('cart')"
                    >
                      Add to cart seleted
                    </v-btn>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                        <v-data-table
                            :headers="headers_jobs"
                            :items="project_jobs"
                            v-model="selectedItems"
                            show-select
                            :server-items-length="jobCount"
                            :options.sync="optionsDatatable"
                            :footer-props="{
                                'items-per-page-options': [50, 100, 200]
                              }"
                            :items-per-page="50"
                            class="table"
                        >
                        <template v-slot:item.submittedDate="{ item }">
                            {{ formatDate(item.submittedDate) }}
                            </template>
                        <template v-slot:item.dateUpdated="{ item }">
                        {{ formatDate(item.dateUpdated) }}
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-btn
                                class="mx-2"
                                text
                                dark
                                v-show="item.jobStatus == 'Editing'" @click="addToCart(item.id)"
                                x-small
                                title="Add to cart"
                                color="blue"
                              >
                                <v-icon dark>
                                fa-cart-plus
                                </v-icon>
                              </v-btn>
                              <v-btn
                                class="mx-2"
                                text
                                v-show="item.jobStatus == 'In Cart'" :to="{path:'/admin/cart'}"
                                dark
                                x-small
                                title="Go to cart"
                                color="green"
                              >
                                <v-icon dark>
                                  fa-shopping-cart
                                </v-icon>
                              </v-btn>
                              <v-btn
                                class="mx-2"
                                text
                                @click="viewProof(item.id)"
                                title="View Proof"
                                dark
                                x-small
                                color="blue-grey"
                              >
                                <v-icon dark>
                                  fa-eye
                                </v-icon>
                              </v-btn>
                              <v-btn
                                class="mx-2"
                                text
                                dark
                                @click="deleteProfile(item.id)"
                                title="Delete item"
                                x-small
                                color="red"
                              >
                                <v-icon dark>
                                  fa-trash
                                </v-icon>
                              </v-btn>
                            <!-- <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item v-show="item.jobStatus != 'In Cart'" @click="addToCart(item.id)">
                                    <v-list-item-title>Add to Cart</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item v-show="item.jobStatus == 'In Cart'" :to="{path:'/admin/cart'}">
                                    <v-list-item-title>Go to Cart</v-list-item-title>
                                    </v-list-item>

                                    <v-list-item @click="viewProof(item.id)">
                                    <v-list-item-title>View Proof</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="deleteProfile(item.id)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                </v-menu> -->
                                
                        </template>
                        
                        </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</v-main>
<v-overlay :value="loading">
    <rotate-loader :loading="true" color="#fff"></rotate-loader>
  </v-overlay>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Accept Proof
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col
          cols="12"
          md="6"
          style="padding: 0px;"
          >
        <v-text-field
            id="password"
            label="Initials"
            name="initials"
            :rules="[rules.required, rules.min]"
            ref="initials"
            v-model="initials"
            :type="'text'"
        />

        </v-col>
         <v-col
        cols="12"
        md="12"
        style="padding: 0px;"
        >
        <v-checkbox
          v-model="checkbox"
          :rules="[rules.required]"
          :label="`Yes I have viewed the proof and approve them. `"
        ></v-checkbox>
        </v-col>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="!valid"
          @click="addToCartAPI"
        >
          ADD TO CART
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-app>
</template>

<script>
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
    components: {'RotateLoader': ClipLoader},
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        loading:false,
        dialog:false,
        initials:'',
        valid:true,
        offset: 0,
        limit : 50,
        item_per_page: 50,
        selectedItems:[],
        jobCount: 0,
        optionsDatatable: {},
        selected_job:'',
        selected_project:'',
        checkbox: '',
        headers: [
          {
            text: 'Project Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Number of Jobs', value: 'jobsInProject',
            sortable: false },
          { text: 'Last Modified', value: 'updated',
            sortable: false },
        ],
        headers_jobs: [
            {
            text: 'Job Number',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Product Name', value: 'envelope',
            sortable: false ,width: 200},
          { text: 'Document Name', value: 'document',
            sortable: false },
          { text: 'Mailing List', value: 'addressList',
            sortable: false },
          { text: 'Status', value: 'jobStatus',
            sortable: false },
          { text: 'Created', value: 'submittedDate',
            sortable: false },
          { text: 'Updated', value: 'dateUpdated',
            sortable: false },
          { text: 'Action', value: 'action',
            sortable: false,width: 200 },
        ],
        projects: [
          
        ],
        project_jobs: []
      }
    },
    watch: {
      optionsDatatable: {
        handler () {
          let self = this;
          self.item_per_page = self.optionsDatatable.itemsPerPage;
          self.offset = (self.optionsDatatable.page - 1) * self.item_per_page
          if(self.selected_project)
            self.getJobs(self.selected_project);
        },
        deep: true,
      },
    },
    created () { 
        this.getProjects();
    },
    methods:{
        seletedItemsProcess(flag){
          console.log(this.selectedItems);
          let msg = flag =='cart' ? "Are you sure, you want to add to cart?" : "Are you sure, you want to delete?"
          if(this.selectedItems.length != 0){
            if(confirm(msg)){
              this.loading = true;
              axios
                .post("/api/process-seleted-items",{'jobs': this.selectedItems, 'flag': flag},this.header)
                .then(response => {
                    let data = response.data;
                  console.log(data);
                  if (data.flag == 1) {
                      this.loading = false;
                      if(data.data){
                        if(flag =='cart')
                          Vue.$toast.success('Products added to cart', {
                            position: 'top-right'
                            });
                        else
                          Vue.$toast.success('Products deleted', {
                            position: 'top-right'
                            });
                          this.getProjects();
                          this.getJobs(this.selected_project);
                      }
                      
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }

                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
              });
            }
          }else{
               Vue.$toast.error('Select atleast one item', {
              position: 'top-right'
              });
          }
        },
        getProjects(){
        this.loading = true;
        let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        axios
            .get("/api/getprojects",header)
            .then(response => {
                let data = response.data;

            if (data.flag == 1) {
                console.log('suman',JSON.parse(data.data).projects);
                this.projects = JSON.parse(data.data).projects;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        getJobs(name){
            this.loading = true;
            this.selected_project = name;
            console.log(this.optionsDatatable);
            let req = {
              limit : this.item_per_page,
              offset : this.offset,
              name: name
              // sort : this.datatable_options.sortBy,
              // sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              // search : this.search
            }
            axios
            .post("/api/getjobs",req,this.header)
            .then(response => {
                let data = response.data;

            if (data.flag == 1) {
                console.log(JSON.parse(data.data));
                this.project_jobs = JSON.parse(data.data).jobs;
                console.log(this.project_jobs);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        viewProof(id){
            this.loading = true;
            axios({
                    method: "POST",
                    url: "/api/viewProof",
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                    data: {'job_id': id}
                    })
                    
            .then(response => {
                let data = response.data;

            if (data) {
                this.loading = false;
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   var fileLink = document.createElement('a');
                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', 'Proof'+moment()+'.pdf');
                   document.body.appendChild(fileLink);

                   fileLink.click();
                // window.open(data.data, '_blank');
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        addToCart(id){
          this.dialog = true;
          this.selected_job = id;
        },
        deleteProfile(id){
            
            if(confirm("Are you sure, You want to delete?")){
              this.loading = true;
              axios
              .post("/api/deleteJob",{'id': id},this.header)
              .then(response => {
                  let data = response.data;

              if (data.flag == 1) {
                  this.loading = false;
                  this.getProjects();
                  this.getJobs(this.selected_project);
                  Vue.$toast.success("Deleted successfully", {
                      position: 'top-right'
                      });
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });

            });
          }
        },
        addToCartAPI(){
          this.$refs.form.validate();
          this.dialog = true;
          axios
            .post("/api/addtocart",{'job_id': this.selected_job, 'initials': this.initials},this.header)
            .then(response => {
                let data = response.data;
              console.log(data);
            if (data.flag == 1) {
                this.loading = false;
                // if(data.data.description =='Success'){
                  Vue.$toast.success('Product added to cart', {
                    position: 'top-right'
                    });
                    this.dialog = false;
                    this.selected_job = '';
                    this.checkbox = '';
                    this.initials = '';
                    this.getJobs(this.selected_project);
                }
                
                else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
        }
    }
    
}
</script>

<style>

</style>