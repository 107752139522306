<template>
  <v-app id="inspire">
  
  <vue-header-no-login></vue-header-no-login>
  <v-main style="padding:0px!important;background-color: #fff;">
    <v-row style="margin: 0px;padding: 0px;">
      <v-col cols="12" style="margin: 0px;padding: 0px;">
        <v-carousel
            cycle
            :height="!$vuetify.breakpoint.mobile ? 900 : 500"
            width="100%"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
              :src="slide.src"
            >
            <v-container
                      fill-height
                      fluid
                      pa-0 ma-0 pb-3 

                    >
                      <v-layout align-end  style="padding-bottom: 80px;">
                        <v-row >
                          <v-col cols="12">
                          <span class=" carousel-header" ><a style="background-color: white;opacity: .5;" href="#" :class="!$vuetify.breakpoint.mobile ? 'carousel-header' : 'carousel-header-mobile'">{{ slide.sub_header }}</a></span>
                          
                        </v-col>
                        <v-col cols="12">
                        <v-btn dark color="#F2835A" tile :class="!$vuetify.breakpoint.mobile ? 'button-caro' : 'button-caro-mobile'">
                              {{ $t('home.carousel.learn_button')  }}
                            </v-btn>
                            </v-col>
                            </v-row>
                      </v-layout>
                    </v-container>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" md="6" lg="6" v-if="$vuetify.breakpoint.mobile">
          <div style="padding:56.25% 0 0 0;position:relative;" class="mt-10"><iframe src="https://player.vimeo.com/video/863261810?h=94cc90c0f7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Home - ScoreZoom®"></iframe></div>
         
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <div class="et_pb_section et_pb_section_1 et_section_regular mt-10 mb-10">
				
            <div class="et_pb_row et_pb_row_0">
              <div class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
                    
                <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">
                    
                  <div class="et_pb_text_inner"><h2>{{$t('home.block_1.header')}}</h2>
                    <p class="mt-3">{{$t('home.block_1.description')}}</p>
                    <ul>
                    <p class="mt-3"><li style="color: #666;">{{$t('home.block_1.description_1')}}</li></p>
                    <p class="mt-3"><li style="color: #666;">{{$t('home.block_1.description_2')}}</li></p>
                    <p class="mt-3"><li style="color: #666;">{{$t('home.block_1.description_3')}}</li></p>
                    <p class="mt-3" v-if="$vuetify.breakpoint.mobile"><li style="color: #666;">{{$t('home.block_1.description_4')}}</li></p>
                    <p class="mt-3" v-if="$vuetify.breakpoint.mobile"><li style="color: #666;">{{$t('home.block_1.description_5')}}</li></p>
                    <p class="mt-3" v-if="$vuetify.breakpoint.mobile"><li style="color: #666;">{{$t('home.block_1.description_6')}}</li></p>
                    </ul>
                  </div>
                </div> 
              </div>                     
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="6" v-if="!$vuetify.breakpoint.mobile">
          <div style="padding:56.25% 0 0 0;position:relative;" class="mt-10"><iframe src="https://player.vimeo.com/video/863261810?h=94cc90c0f7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Home - ScoreZoom®"></iframe></div>
          <ul>
            <p class="mt-3"><li style="color: #666;">{{$t('home.block_1.description_4')}}</li></p>
            <p class="mt-3"><li style="color: #666;">{{$t('home.block_1.description_5')}}</li></p>
            <p class="mt-3"><li style="color: #666;">{{$t('home.block_1.description_6')}}</li></p>
          </ul>
        </v-col>
        <v-col cols="12">




          <div class="et_pb_section et_pb_section_1 et_section_regular mt-10 mb-10">
				
              <div class="et_pb_row et_pb_row_0">
                <div class=" et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
                      
                  <div class="et_pb_module et_pb_text et_pb_text_0 text-center et_pb_bg_layout_light">
                      
                    <div class="et_pb_text_inner text-center" style="text-align: center;"><h2>{{$t('home.block_8.header')}}</h2>
                    </div>
                  </div> 
                </div>                     
              </div>
              <div class="et_pb_row_2">
                <v-row
                          class="mb-4 mt-10"
                          justify="space-between"
                        >
                          <v-col class="text-center">
                            <p
                              class=""
                              :style="'font-weight: 700; font-size: 30px; font-family: sans-serif !important;color:'+color"
                              :color="color"
                              v-text="total_amount"
                            ></p>
                            
                            <v-fade-transition>
                              <v-avatar
                                v-if="isPlaying"
                                :color="color"
                                :style="{
                                  animationDuration: animationDuration
                                }"
                                class="mb-1 v-avatar--metronome"
                                size="12"
                              ></v-avatar>
                            </v-fade-transition>
                          </v-col>
                      
                        </v-row>

                        <v-slider
                          v-model="bpm"
                          :color="color"
                          track-color="grey"
                          always-dirty
                          :max="3"
                          step="1"
                          ticks="always"
                          tick-size="4"

                        >
                          <template v-slot:prepend>
                            <v-icon
                              :color="'red'"
                              style="margin-top: 5px;"
                              @click="decrement"
                            >
                            fa fa-minus
                            </v-icon>
                          </template>

                          <template v-slot:append>
                            <v-icon
                              :color="'green'"
                              style="margin-top: 5px;"
                              @click="increment"
                            >
                            fa fa-plus
                            </v-icon>
                          </template>
                        </v-slider>
                        <v-row
                          class="mb-4"
                          justify="space-between"
                        >
                          <v-col class="text-center">
                            <v-card class="d-flex" :style="'background-color: '+color+';box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);'">
                                  <v-container class="d-flex flex-column" fluid>
                                  
                                      <v-simple-table dark  class="d-flex flex-column loan-table" :style="'background-color: '+color+';'">
                                        <template v-slot:default>
                                            <thead>
                                              <tr>
                                                  <th class="text-center">{{$t('home.block_8.table.header_1')}}</th>
                                                  <th class="text-center">{{$t('home.block_8.table.header_2')}}</th>
                                                  <th class="text-center">{{$t('home.block_8.table.header_3')}}</th>
                                                  <th class="text-center">{{$t('home.block_8.table.header_4')}}</th>
                                              </tr>
                                            </thead>
                                            <tbody style="overflow-y: scroll">
                                              <tr
                                                    v-for="item in desserts"
                                                    :key="item.goal"
                                                  >
                                                    <td>{{ item.goal }}</td>
                                                    <td>{{ item.interest ? item.interest : "N/A" }}</td>
                                                    <td>{{ item.monthly != '' ? (item.monthly) : "Declined" }}</td>
                                                    <td>{{ item.saving != '' ? (item.saving) : 'Declined' }}</td>
                                              </tr>
                                            </tbody>
                                        </template>
                                      </v-simple-table>

                                  </v-container>
                                </v-card>
                                <p style="font-size: 11px;" class="mt-3">*Assumptions: 30 year period with a $2,000 limit credit card every month; $250,000 mortgage with a 30 year term; four 48 month car loans; two $10,000 personal loans; and insurance every month.</p>
                          </v-col>
                        </v-row>
              </div>
        </div>








        </v-col>
        <v-col cols="12" class="padding0">
          <div class="et_pb_section et_pb_section_2 et_pb_fullwidth_section et_section_regular mb-5">
				
                <section class="et_pb_module et_pb_fullwidth_header et_pb_fullwidth_header_0 et_pb_text_align_left et_pb_bg_layout_dark">
      
      
                <div class="et_pb_fullwidth_header_container left">
                  <div class="header-content-container center">
                  <div class="header-content">
                    
                    <h1 class="et_pb_module_header">{{$t('home.block_2.header')}}</h1>
                    <span class="et_pb_fullwidth_header_subhead">{{$t('home.block_2.description')}}</span>
                    <div class="et_pb_header_content_wrapper"></div>
                    
                  </div>
                </div>
                  
                </div>
                <div class="et_pb_fullwidth_header_overlay"></div>
                <div class="et_pb_fullwidth_header_scroll"></div>
              </section>
                
                
              </div>
        </v-col>
        <v-col cols="12" class="padding0">
          <div class="et_pb_row et_pb_row_1 mt-10">
                  <div class="et_pb_column et_pb_column_1_3 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough">
                  
                  
                  <div class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_0  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
                  
                  
                  <div class="et_pb_blurb_content">
                    <div class="et_pb_main_blurb_image"><a href="#"><span class="et_pb_image_wrap"><img decoding="async" src="@/assets/img/home-block-3-1.jpg" alt="" class="et-waypoint et_pb_animation_top et-animated"></span></a></div>
                    <div class="et_pb_blurb_container">
                      <h4 class="et_pb_module_header">{{$t('home.block_3.item_1.header')}}</h4>
                      <div class="et_pb_blurb_description et_pb_text_inner"><p ><span >{{$t('home.block_3.item_1.description')}}&nbsp; <a href="#">Find out.</a></span></p></div>
                    </div>
                  </div> <!-- .et_pb_blurb_content -->
                </div> <!-- .et_pb_blurb -->
                </div> <!-- .et_pb_column --><div class="et_pb_column et_pb_column_1_3 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough">
                  
                  
                  <div class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_1  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
                  
                  
                  <div class="et_pb_blurb_content">
                    <div class="et_pb_main_blurb_image"><a href="#"><span class="et_pb_image_wrap"><img decoding="async" src="@/assets/img/home-block-3-2.jpg" alt="" class="et-waypoint et_pb_animation_top et-animated"></span></a></div>
                    <div class="et_pb_blurb_container">
                      <h4 class="et_pb_module_header"><a href="#">{{$t('home.block_3.item_2.header')}}</a></h4>
                      <div class="et_pb_blurb_description et_pb_text_inner"><p><span >{{$t('home.block_3.item_2.description')}} <a href="#">Book an appointment</a> and get started now!</span></p></div>
                    </div>
                  </div> <!-- .et_pb_blurb_content -->
                </div> <!-- .et_pb_blurb -->
                </div> <!-- .et_pb_column --><div class="et_pb_column et_pb_column_1_3 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">
                  
                  
                  <div class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_2  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
                  
                  
                  <div class="et_pb_blurb_content">
                    <div class="et_pb_main_blurb_image"><a href="#"><span class="et_pb_image_wrap"><img decoding="async" src="@/assets/img/home-block-3-3.jpg" alt="" class="et-waypoint et_pb_animation_top et-animated"></span></a></div>
                    <div class="et_pb_blurb_container">
                      <h4 class="et_pb_module_header"><a href="#">{{$t('home.block_3.item_3.header')}}</a></h4>
                      <div class="et_pb_blurb_description et_pb_text_inner"><p><span >{{$t('home.block_3.item_3.description')}}</span><a href="#"> Learn More.</a></p></div>
                    </div>
                  </div> <!-- .et_pb_blurb_content -->
                </div> <!-- .et_pb_blurb -->
                </div> <!-- .et_pb_column -->
                  
                  
                </div>
        </v-col>
        <v-col cols="12" class="padding0">
          <div class="et_pb_section et_pb_section_2 et_pb_fullwidth_section et_section_regular mb-5 mt-10">
				
                <section class="et_pb_module et_pb_fullwidth_header et_pb_fullwidth_header_1 et_pb_text_align_left et_pb_bg_layout_dark">
      
      
                <div class="et_pb_fullwidth_header_container left">
                  <div class="header-content-container center">
                  <div class="header-content">
                    
                    <h1 class="et_pb_module_header">{{$t('home.block_4.header')}}</h1>
                    <span class="et_pb_fullwidth_header_subhead">{{$t('home.block_4.description')}}</span>
                    <div class="et_pb_header_content_wrapper"></div>
                    
                  </div>
                </div>
                  
                </div>
                <div class="et_pb_fullwidth_header_overlay"></div>
                <div class="et_pb_fullwidth_header_scroll"></div>
              </section>
                
                
              </div>
        </v-col>
        <v-col cols="12" class="padding0">
          <div class="et_pb_row et_pb_row_2 mt-10">
				<div class="et_pb_column et_pb_column_4_4 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">
				
				
				<div class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light et_pb_text_0 ">
				
				
				<div class="et_pb_text_inner text-center"><h2>{{$t('home.block_5.header')}}</h2>
            <p class="mt-5">{{$t('home.block_5.description')}} <a href="#">Learn More.</a></p>
            <p>&nbsp;</p></div>
                  </div>
                  </div> 
                    
                    
                  </div>
        </v-col>
        <v-col cols="12" class="padding0 mb-10">
          <div class="et_pb_row et_pb_row_4 mb-10">
                <div class="et_pb_column-2 et_pb_column_1_2 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough">
                
                
                <div class="et_pb_module et_pb_text et_pb_text_6 et_clickable  et_pb_text_align_left et_pb_bg_layout_light">
                
                
                <div class="et_pb_text_inner mb-5"><h4 style="color: #666;"><span>{{ $t('home.block_6.item_1.header') }}</span></h4></div>
              </div> <!-- .et_pb_text --><div class="et_pb_module et_pb_image et_pb_image_1">
                
                
                <span class="et_pb_image_wrap-2 has-box-shadow-overlay"><div class="box-shadow-overlay"></div><img decoding="async" src="@/assets/img/home-block-6-7.png" title="arrow" height="auto" width="auto"  sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 628px, 100vw" class="wp-image-1057"></span>
              </div><div class="et_pb_module et_pb_text et_pb_text_7 et_clickable  et_pb_text_align_left et_pb_bg_layout_light">
                
                
                <div class="et_pb_text_inner mt-5"><p>{{ $t('home.block_6.item_1.description') }}</p></div>
              </div> <!-- .et_pb_text -->
              </div> <!-- .et_pb_column -->
              <div class="et_pb_column-2 et_pb_column_1_2 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough et-last-child">
                
                
                <div class="et_pb_module et_pb_text et_pb_text_8  et_pb_text_align_left et_pb_bg_layout_light">
                
                
                <div class="et_pb_text_inner mb-5"><h4 style="color: #666;">{{ $t('home.block_6.item_2.header') }}</h4></div>
              </div> <!-- .et_pb_text --><div class="et_pb_module et_pb_image et_pb_image_2">
                
                
                <a href="#" target="_blank"><span class="et_pb_image_wrap-2 has-box-shadow-overlay"><div class="box-shadow-overlay"></div><img decoding="async" src="@/assets/img/home-block-6-7.png" alt=""  height="auto" width="auto"  sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 628px, 100vw" class="wp-image-1058"></span></a>
              </div><div class="et_pb_module et_pb_text et_pb_text_9  et_pb_text_align_left et_pb_bg_layout_light">
                
                
                <div class="et_pb_text_inner"><p><span></span></p>
        <p>{{ $t('home.block_6.item_2.description') }}</p></div>
              </div> <!-- .et_pb_text -->
              </div> 
                
                
              </div>
        </v-col>
        <v-col cols="12" class="padding0 mb-10">

          <v-carousel
            :height="!$vuetify.breakpoint.mobile ? 600 : 400"
            width="100%"
            hide-delimiter-background
            show-arrows-on-hover
            :show-arrows="false"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(slide, i) in slides_2"
              :key="i"
              :src="slide.src"
            >
            <v-container
                      

                    >
                    <div class="et_pb_column et_pb_column_4_4 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child" style="float: none;">
				
				
                            <div class="et_pb_module et_pb_blurb et_pb_blurb_3  et_pb_text_align_center  et_pb_blurb_position_top et_pb_bg_layout_dark">
                            
                            
                            <div class="et_pb_blurb_content">
                              <div class="et_pb_main_blurb_image mt-5"><span class="et_pb_image_wrap" style="border: none;"><img decoding="async" width="64" height="64" src="@/assets/img/quote-light.png" alt="" class="et-waypoint et_pb_animation_top wp-image-101 et-animated"></span></div>
                              <div class="et_pb_blurb_container">
                                <h2 v-if="!$vuetify.breakpoint.mobile" class="et_pb_module_header" style="text-shadow: none;"><span>{{ $t('home.block_7.title_1') }}</span></h2>
                                <h2 v-else class="et_pb_module_header" style="text-shadow: none;font-size: 20px!important;"><span>{{ $t('home.block_7.title_1') }}</span></h2>
                                
                              </div>
                            </div> <!-- .et_pb_blurb_content -->
                          </div> <!-- .et_pb_blurb --><div class="et_pb_module et_pb_slider et_pb_slider_0 et_hover_enabled et_clickable et_pb_slider_fullwidth_off et_pb_bg_layout_dark" data-active-slide="et_pb_slide_8">
                            <div class="et_pb_slides">
                              <div class="et_pb_slide et_pb_slide_8 et_pb_bg_layout_dark et_pb_media_alignment_center et-pb-active-slide" data-slide-id="et_pb_slide_8">
                            
                            
                            <div class="et_pb_container clearfix" style="height: 403.4px;">
                              <div class="et_pb_slider_container_inner">
                                
                                <div :class="!$vuetify.breakpoint.mobile ? 'et_pb_slide_description mt-10' : 'et_pb_slide_description'">
                                  <h2 v-if="!$vuetify.breakpoint.mobile" class="et_pb_slide_title text-center">{{ $t('home.block_7.title_2') }}</h2>
                                  <h2 v-else class="et_pb_slide_title text-center" style="font-size: 20px!important;">{{ $t('home.block_7.title_2') }}</h2>
                                  <div class="et_pb_slide_content"><p :style="!$vuetify.breakpoint.mobile ? 'text-align: center;' : 'text-align: center;font-size:11px;'">“{{ $t('home.block_7.description_1') }}”</p>
                    <p :style="!$vuetify.breakpoint.mobile ? 'text-align: center;' : 'text-align: center;font-size:11px;'">“{{ $t('home.block_7.description_2') }}”</p></div>
                                  <div class="et_pb_button_wrapper text-center mt-10"><v-btn dark color="white" tile outlined :class="!$vuetify.breakpoint.mobile ? 'button-caro-2' : 'button-caro-mobile'">
                              {{ $t('home.block_7.read_button')  }}
                            </v-btn></div>
                                </div> <!-- .et_pb_slide_description -->
                              </div>
                            </div> <!-- .et_pb_container -->
                            
                          </div> <!-- .et_pb_slide -->
                          
                            </div> <!-- .et_pb_slides -->
                            
                          </div> <!-- .et_pb_slider -->
                          
                          </div>
                    </v-container>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        </v-row>
       <vue-footer></vue-footer>
       </v-main>
  </v-app>
  
  </template>
  
  <script>
  import { VueTelInput } from 'vue-tel-input'
  import AvatarCropper from 'vue-avatar-cropper'
  export default {
  components: {
        VueTelInput,AvatarCropper
    },
    data () {
  
        return {

          slides: [
          {
            src: require('../assets/img/home-slider-1.jpeg'),
            sub_header : this.$t('home.carousel.sub_header_1')
          },
          {
            src: require('../assets/img/home-slider-2.jpeg'),
            sub_header : this.$t('home.carousel.sub_header_2')
          },
          {
            src: require('../assets/img/home-slider-3.jpeg'),
            sub_header : this.$t('home.carousel.sub_header_3')
          },
          {
            src: require('../assets/img/home-slider-4.jpeg'),
            sub_header : this.$t('home.carousel.sub_header_4')
          },
        ],
        slides_2 :[
        {
            src: require('../assets/img/home-david.jpg'),
            sub_header : this.$t('home.carousel.sub_header_1')
          },
        ],
        bpm: 0,
        total_amount : "$0*",
        interval: null,
        desserts: [
            {
              goal: 'Mortgage',
              interest: "8.36%",
              monthly : "$1,900",
              saving : "$0"
            },
            {
              goal: 'Credit Card',
              interest: "22.99%",
              monthly : "$58",
              saving : "$0"
            },
            {
              goal: 'Auto Loan',
              interest: "15.11%",
              monthly : "$697",
              saving : "$0"
            },
            {
              goal: 'Personal Loan',
              interest: "210%",
              monthly : "$1,810",
              saving : "$0"
            },
            {
              goal: 'Apartment',
              interest: 'N/A',
              monthly : 'Declined',
              saving : 'Declined'
            },
            {
              goal: 'Employment',
              interest: 'N/A',
              monthly : 'Declined',
              saving : 'Declined'
            },
            
          ],
        }
      },
      computed: {
      color () {
        if (this.bpm == 0) {
          this.total_amount = "$0*"; 
          this.desserts = [
            {
              goal: 'Mortgage',
              interest: "8.36%",
              monthly : "$1,900",
              saving : "$0"
            },
            {
              goal: 'Credit Card',
              interest: "22.99%",
              monthly : "$58",
              saving : "$0"
            },
            {
              goal: 'Auto Loan',
              interest: "15.11%",
              monthly : "$697",
              saving : "$0"
            },
            {
              goal: 'Personal Loan',
              interest: "210%",
              monthly : "$1,810",
              saving : "$0"
            },
            {
              goal: 'Apartment',
              interest: 'N/A',
              monthly : 'Declined',
              saving : 'Declined'
            },
            {
              goal: 'Employment',
              interest: 'N/A',
              monthly : 'Declined',
              saving : 'Declined'
            },
            
          ];
          return '#D83621'
        }
        if (this.bpm == 1) {
          this.total_amount = "$371,880*";
          this.desserts = [
            {
              goal: 'Mortgage',
              interest: "6%",
              monthly : "$1,500",
              saving : "$144,000"
            },
            {
              goal: 'Credit Card',
              interest: "19.8%",
              monthly : "$50",
              saving : "$3060"
            },
            {
              goal: 'Auto Loan',
              interest: "9.85%",
              monthly : "$632",
              saving : "$12,480"
            },
            {
              goal: 'Personal Loan',
              interest: "18.35%",
              monthly : "$255",
              saving : "$186,600"
            },
            {
              goal: 'Apartment',
              interest: 'N/A',
              monthly : '2X Deposit',
              saving : '2X Deposit'
            },
            {
              goal: 'Employment',
              interest: 'N/A',
              monthly : 'Hired',
              saving : 'Hired'
            },
            
          ];
          return '#F4801E'}
        if (this.bpm == 2) {
          this.total_amount = "$485,172*";
          this.desserts = [
            {
              goal: 'Mortgage',
              interest: "4.5%",
              monthly : "$1,267",
              saving : "$227,880"
            },
            {
              goal: 'Credit Card',
              interest: "10.9%",
              monthly : "$27",
              saving : "$11,130"
            },
            {
              goal: 'Auto Loan',
              interest: "7.17%",
              monthly : "$601",
              saving : "$18,432"
            },
            {
              goal: 'Personal Loan',
              interest: "9.17%",
              monthly : "$208",
              saving : "$192,240"
            },
            {
              goal: 'Apartment',
              interest: 'N/A',
              monthly : 'Accepted',
              saving : 'Accepted'
            },
            {
              goal: 'Employment',
              interest: 'N/A',
              monthly : 'Hired',
              saving : 'Hired'
            },
            
          ];
          return '#32CD32'}
        if (this.bpm == 3) {
          this.total_amount = "$561,502*";
          this.desserts = [
            {
              goal: 'Mortgage',
              interest: "3.3%",
              monthly : "$1,095",
              saving : "$289,900"
            },
            {
              goal: 'Credit Card',
              interest: "7.6%",
              monthly : "$19",
              saving : "$13,960"
            },
            {
              goal: 'Auto Loan',
              interest: "5.9%",
              monthly : "$586",
              saving : "$21,312"
            },
            {
              goal: 'Personal Loan',
              interest: "7.19%",
              monthly : "$198",
              saving : "$193,440"
            },
            {
              goal: 'Apartment',
              interest: 'N/A',
              monthly : 'Accepted',
              saving : 'Accepted'
            },
            {
              goal: 'Employment',
              interest: 'N/A',
              monthly : 'Hired',
              saving : 'Hired'
            },
            
          ];
          return '#228B22'}
        return 'red'
      },
      animationDuration () {
        return `${60 / this.bpm}s`
          },
        },
      methods: {

          decrement () {
            this.bpm--
          },
          increment () {
            this.bpm++
          },
          getCur(val){
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
              });

             return formatter.format(val); /* $2,500.00 */
          }
   },
  
  created() {
        console.log('created called.');
      },
      beforeCreate: function () {
          if (localStorage.getItem('token')) {
              if(localStorage.getItem('is_admin') && localStorage.getItem('is_admin') == 1)
                  this.$router.push('/admin/dashboard')
              else
                  this.$router.push('/dashboard')
          }
      },
  
  }
  </script>
  <style>
  .carousel-header {
  font-family: 'Ubuntu',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: 50px !important;
    color: black !important;
    line-height: 1.2em;
    margin: 100px 50px 50px 150px!important;
}
.carousel-header-mobile {
  font-family: 'Ubuntu',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: 20px !important;
    color: black !important;
    float: left;
    line-height: 1.2em;

    margin-left: 10px!important;
}
.button-caro{
  margin-left: 300px!important;
  min-height: 50px;
  text-transform: none;
}
.button-caro-2{
  min-height: 50px;
  text-transform: none;
}
.button-caro-mobile{
  margin-left: 10px!important;
  min-height: 30px;
  margin-top: 0px;
  text-transform: none;
}
.et_pb_text_0 h2 {
  font-family: Open Sans,Arial,sans-serif;
  font-weight: 700;
  color: #333;
}
.et_pb_text_inner p {
  font-family: Open Sans,Arial,sans-serif;
  font-size: 14px;
  color: #666;
}
.et_pb_row_0{
  width: 80%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}
@media (min-width: 981px) {
  .et_pb_row_2{
    width: 60%;
    max-width: 600px;
    margin: auto;
    position: relative;
  }
}
.et_pb_row {
  width: 90%;
  max-width: 1080px;
  margin: auto;
  position: relative;
}
.et_pb_section_1.et_pb_section {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 8px;
  padding-left: 0px;
  margin-top: 30px;
  position: relative;
  background-color: #fff;
}
body {
  background-color: #fff;
}
.et_pb_fullwidth_header.et_pb_fullwidth_header_0 {
  background-image: url(https://thescorezoom.com/wp-content/uploads/2022/05/pickawood-gf8e6XvG_3E-unsplash.jpg);
  background-color: #2ea3f2;
}
.et_pb_fullwidth_header.et_pb_fullwidth_header_1 {
  background-image: url(https://thescorezoom.com/wp-content/uploads/2022/06/pexels-pixabay-50987-scaled.jpg);
  background-color: #2ea3f2;
}
.et_pb_fullwidth_header {
  padding: 50px 0;
  position: relative;
  background-position: 50%;
  background-size: cover;
}
.et_pb_bg_layout_dark, .et_pb_bg_layout_dark h1, .et_pb_bg_layout_dark h2, .et_pb_bg_layout_dark h3, .et_pb_bg_layout_dark h4, .et_pb_bg_layout_dark h5, .et_pb_bg_layout_dark h6 {
  color: #fff !important;
}
.et_pb_fullwidth_header .et_pb_fullwidth_header_container {
  position: relative;
  z-index: 3;
  width: 80%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
.et_pb_module_header{ 
font-family: 'Ubuntu',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 600;
  font-size: 35px;
  text-shadow: 0em 0.1em 0.1em rgba(0,0,0,0.4);
}
.et_pb_fullwidth_header_subhead{
  font-family: Open Sans,Arial,sans-serif;
  font-size: 14px;
}
.padding0{
  padding: 0px;
  margin: 0px;
}
.et_pb_animation_top.et-animated {
  opacity: 1;
  -webkit-animation: fadeTop 1s cubic-bezier(.77,0,.175,1) 1;
  animation: fadeTop 1s cubic-bezier(.77,0,.175,1) 1;
}
.et_pb_blurb_0 .et_pb_main_blurb_image .et_pb_image_wrap {
  border-width: 5px;
  border-color: #F0B800;
}
.et_pb_image_wrap {
  display: block;
  margin: auto;
  border: 0 solid #333;
  border-width: 5px;
  border-color: #F0B800;
}
.et_pb_main_blurb_image {
  display: inline-block;
  margin-bottom: 30px;
  line-height: 0;
  max-width: 100%;
}
.et_pb_blurb_content {
  max-width: 550px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  display: table;
  width: 100%;
}
.et_pb_text_align_left {
  text-align: left;
}

 
  @media (min-width: 981px) {
  .et_pb_column {
    margin-right: 3.5%;
  }
  .et_pb_column_1_3 {
    width: 29.6667%;
  }
}
  .et_pb_column {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}
.et_pb_image_wrap img{
  max-width: 100%;
  height: auto;
}
.et_pb_image_wrap-2 img{
  max-width: 100%;
  height: auto;
}
.et_pb_column_1_3 h4, .et_pb_column_1_4 h4, .et_pb_column_1_5 h4, .et_pb_column_1_6 h4, .et_pb_column_2_5 h4 {
  font-size: 18px;
  font-weight: 600;
  color: #333!important;
  padding-bottom: 10px;
  line-height: 1em;
  font-family: 'Ubuntu',Helvetica,Arial,Lucida,sans-serif;
  text-shadow: none;
}
.et_pb_blurb .et_pb_module_header a, .et_pb_blurb h4 a {
  text-decoration: none;
}
.et_pb_blurb.et_pb_text_align_left .et_pb_blurb_content .et_pb_blurb_container {
  text-align: left;
}
.et_pb_blurb_description a {
  color: #2ea3f2;
}
.et_pb_text_inner a {
  color: #2ea3f2;
}
@media (min-width: 981px) {
 .et_pb_column_1_2{
    width: 47.25%;
  }
}
.et_pb_column-2 {
  float: left;
  background-size: cover;
  background-position: 50%;
  position: relative;
  z-index: 2;
  min-height: 1px;
}
@media (min-width: 981px) {
  .et_pb_column-2 {
    margin-right: 2.5%;
  }
}
.et_pb_image_wrap-2 {
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.3);
  box-shadow: 6px 6px 18px 0px rgba(0,0,0,0.3);
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.et_pb_slide_title {
  font-family: 'Ubuntu',Helvetica,Arial,Lucida,sans-serif;
  font-weight: 700;
  font-size: 50px !important;
  line-height: 2em;
  color: #fff !important;
}

</style>
<style lang="scss">  
.loan-table tbody {
   tr:hover {
      background-color: transparent !important;
   }
}
</style>
