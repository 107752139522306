<template>
<v-app id="inspire">

<vue-header-admin v-if="role.name != 'client'"></vue-header-admin>
<vue-header v-if="role.name == 'client'"></vue-header>
<v-main :style="role.name == 'client' ? 'padding:0px!important;margin-top: 150px;background:white;'  :'background:white;margin-top:15px;'">
    <v-container fluid >
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="8">
              
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class="heading-h2 mt-10">Letter Editor </h1>
                    </v-col>
                     <v-col
                      cols="12"
                      md="12"
                    >
                    <v-btn
                      dark
                      v-if="!addBureauFlag"
                      @click="addBureauFlag = true"
                        color="blue"
                      >
                       + Add Custom bureau 
                      </v-btn>
                    
                    </v-col>
                    <v-col
                    v-if="addBureauFlag"
                      cols="12"
                      md="12"
                    >
                    <v-row class="mt-2">
                      <v-col
                      cols="12"
                      md="4"
                      v-if="addBureauFlag"
                    >
                   <v-text-field
                        label="Name of bureau"
                        v-model="newBureau.name"
                      ></v-text-field>
                      </v-col>
                   <v-col
                      cols="12"
                      v-if="addBureauFlag"
                      md="4"
                    >
                    <v-select
                      :items="categories"
                      label="Letter Category *"
                      item-text="category"
                      item-value="id"
                      @input="getLeterName"
                      v-model="newBureau.letter_category"
                    ></v-select>
                   </v-col>
                   
                   <v-col
                   v-if="addBureauFlag"
                      cols="12"
                      md="4"
                    >
                    <v-select
                      :items="letterNameOptions"
                      label="Letter Name *"
                      item-text="title"
                      item-value="id"
                      v-model="newBureau.letter_name"
                    ></v-select>
                   </v-col>
                   </v-row>
                   <div style="text-align:right;" v-if="addBureauFlag">
                         <v-btn
                          dark
                          small
                          class="mr-3"
                          style="height: 45px;
                        text-transform: none;
                            padding: 0 40px;
                            " 
                          @click="saveCustomBureau"
                            color="green"
                          >
                            Save bureau
                          </v-btn>
                          <v-btn
                          dark
                          small
                          outlined
                          style="height: 45px;
                        text-transform: none;
                            padding: 0 40px;
                            " 
                          @click="addBureauFlag = false, newBureau.name = '',newBureau.letter_category = '',newBureau.letter_name=''"
                            color="green"
                          >
                            Cancel
                          </v-btn>
                        </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <div style="border: 1px solid rgb(102, 102, 102);" >
                    <v-tabs class="letter-tab" v-model="tab">
                    <v-tab v-if="transunion_letter" href="#tab-1"><img height="25" class="image-logo2" src="@/assets/img/tr.png"></v-tab>
                    <v-tab v-if="experian_letter" href="#tab-2"><img height="25" class="image-logo2" src="@/assets/img/ex.png"></v-tab>
                    <v-tab v-if="equifax_lettr" href="#tab-3"><img height="25" class="image-logo2" src="@/assets/img/eq.png"></v-tab>
                    <v-tab v-show="customGeneratedBureau.length != 0" :href="'#tab-'+item.name" v-for="item in customGeneratedBureau" v-bind:key="item.name">{{item.name}}  <v-icon class="ml-2" dark color="red" @click="removeBureau(item)">
                  fa-times
                </v-icon> </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab" style="margin-bottom:0px;">
                    <v-tab-item
                      value="tab-1"
                    >
                    <span class="m-t-20 pl-5 pr-5 p-10 border d-flex j-c-sb">
                                  <span class="" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send From Address:
                                                          </span>
                                  <br>
                                  <span class="default-color font-500" v-html="client_address">
                                  </span>
                              </span>
                                  <span class="w-25p" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send To Address:</span>
                                  <span class="default-color font-500">
                                <span class="baddress d-none" id="toAddress-equifax"></span><span class="baddress d-block" id="toAddress-transunion" v-html="transunion_address">
                                  
                                  
                                  </span>     			</span>
                                </span>
                                </span>
                    <!-- <editor v-model="transunion_letter" api-key="gy0tehv0d7n934gbood5c6jyemezfrywnh9b6p5keila4odp" :init="{plugins: 'wordcount', height: 500,}" /> -->
                    <html-editor v-model="transunion_letter"></html-editor>
                    </v-tab-item>
                    <v-tab-item
                      value="tab-2"
                    >
                    <span class="m-t-20 pl-5 pr-5 p-10 border d-flex j-c-sb">
                                  <span class="" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send From Address:
                                                          </span>
                                  <br>
                                  <span class="default-color font-500" v-html="client_address">
                                  </span>
                              </span>
                                  <span class="w-25p" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send To Address:</span>
                                  <span class="default-color font-500">
                                <span class="baddress d-none" id="toAddress-equifax"></span><span class="baddress d-block" id="toAddress-transunion" v-html="experian_address">
                                  
                                  
                                  </span>     			</span>
                                </span>
                                </span>
                    <!-- <editor v-model="experian_letter" api-key="gy0tehv0d7n934gbood5c6jyemezfrywnh9b6p5keila4odp" :init="{plugins: 'wordcount', height: 500,}" /> -->
                     <html-editor v-model="experian_letter"></html-editor>
                    </v-tab-item>
                    <v-tab-item

                      value="tab-3"
                    >
                    <span class="m-t-20 pl-5 pr-5 p-10 border d-flex j-c-sb">
                                  <span class="" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send From Address:
                                                          </span>
                                  <br>
                                  <span class="default-color font-500" v-html="client_address">
                                  </span>
                              </span>
                                  <span class="w-25p" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send To Address:</span>
                                  <span class="default-color font-500">
                                <span class="baddress d-none" id="toAddress-equifax"></span><span class="baddress d-block" id="toAddress-transunion" v-html="equifax_address">
                                  
                                  
                                  </span>     			</span>
                                </span>
                                </span>
                    <!-- <editor v-model="equifax_lettr" api-key="gy0tehv0d7n934gbood5c6jyemezfrywnh9b6p5keila4odp" :init="{plugins: 'wordcount', height: 500,}" /> -->
                     <html-editor v-model="equifax_lettr"></html-editor>
                    </v-tab-item>

                    <v-tab-item
                      v-for="(item,index) in customGeneratedBureau" v-bind:key="item.name"
                      :value="'tab-'+item.name"
                    >
                    <span class="m-t-20 pl-5 pr-5 p-10 border d-flex j-c-sb">
                                  <span class="" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send From Address:
                                                          </span>
                                  <br>
                                  <span class="default-color font-500" v-html="client_address">
                                  </span>
                              </span>
                                  <span class="w-25p" style="line-height: 1.7;">
                                  <span class="font-15  default-color m-b-15">Send To Address:</span>
                                  <span class="default-color font-500">
                                <span class="baddress d-none" id="toAddress-equifax"></span><span class="baddress d-block" id="toAddress-transunion" >
                                  
                                  
                                  </span>     			</span>
                                </span>
                                </span>
                    <!-- <editor v-model="customGeneratedBureau[index].letter" api-key="gy0tehv0d7n934gbood5c6jyemezfrywnh9b6p5keila4odp" :init="{plugins: 'wordcount', height: 500,}" /> -->
                     <html-editor v-model="customGeneratedBureau[index].letter"></html-editor>
                    </v-tab-item>
                  </v-tabs-items>
                    </div>
                    <div style="text-align:right;" class="mt-10">
                         <v-btn
                          dark
                          style="height: 45px;
                        text-transform: none;
                            padding: 0 40px;
                            " 
                          @click="saveLetterDialog = true"
                            color="green"
                          >
                            Save & Continue to Print
                          </v-btn>
                        </div>
                    </v-col>
                    
                  </v-row>
            </v-col>
          </v-row>
          <v-overlay :value="overlay">
     <div class="loadingio-spinner-spinner-2k3nmd4xown"><div class="ldio-7xwhhkt0pnf">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>




    </v-overlay>
    <v-dialog
        persistent
      v-model="addPendingItemDialog"
      width="auto"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Add Saved/Pending Items
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
           <v-data-table
           item-key="id"
           v-model="seletedPendingItems"
          :headers="headerPendingItems"
          :items="pendingItems"
          show-select
          :items-per-page="100"
          hide-default-footer
          class="elevation-1"
        >
        <template v-slot:header.trn="{ header }">
          <img height="25" class="image-logo2" src="/assets/images/tr.png">
        </template>
        <template v-slot:header.exp="{ header }">
          <img height="25" class="image-logo2" src="/assets/images/ex.png">
        </template>
        <template v-slot:header.eq="{ header }">
          <img height="25" class="image-logo2" src="/assets/images/eq.png">
        </template>
        <template v-slot:item.account="{ item }">
                            <span> Transunion: {{item.account.transunion}}</span><br/>
                            <span> Experian: {{item.account.experian}}</span><br/>
                            <span> Equifax: {{item.account.equifax}}</span><br/>
                        </template>
        <template v-slot:item.trn="{ item }">
              <div style="text-align:center;" v-if="item.transunion">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        <template v-slot:item.exp="{ item }">
              <div style="text-align:center;" v-if="item.experian">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        <template v-slot:item.eq="{ item }">
              <div style="text-align:center;" v-if="item.equifax">
                <v-icon dark color="red">
                  mdi-close-box
                </v-icon> 
                <br/><span style="color: #706e6b !important;  opacity: .5;">Negative</span>
              </div>
        </template>
        
        </v-data-table>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          dark
          @click="assignDisputeItems"
            color="green"
          >
            Add to Dispute
          </v-btn>
           <v-btn
            color="green"
            outlined
            @click="addPendingItemDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="saveLetterDialog"
      width="600"
    >
  
      <v-card>
        
        <v-card-title class="text-h5 grey lighten-2">
          Save Letter
        </v-card-title>

        <v-card-text style="margin-top:30px;"> 
          <v-row>
           <v-col
                      cols="12"
                      md="3"
                    >
                    <v-select
                      :items="[1,2,3,4,5,6]"
                      label="Round"
                      v-model="letter_round"
                    ></v-select>

                    </v-col>
                     <v-col
                      cols="12"
                      md="9"
                    >
                    <v-text-field
                        label="Name of this letter"
                        v-model="letter_name"
                      ></v-text-field>
                     </v-col>
          </v-row>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          dark
          @click="saveLetter"
            color="green"
          >
            Save All Letters
          </v-btn>
           <v-btn
            color="green"
            outlined
            @click="saveLetterDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
    </v-container>
    
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
export default {
components: {
      VueTelInput,HtmlEditor
  },
  data () {

      return {
        user: '',
        overlay: false,
        role : JSON.parse(localStorage.getItem('role')),
        addPendingItemDialog: false,
        tab : null,
        saveLetterDialog:false,
        letter_round : 2,
        letter_name: 'RD2',
        seletedPendingItems: [],
        addBureauFlag : false,
        newBureau : {
          name : "",
          letter_category : "",
          letter_name : ""
        },
        customBureau : [],
        headerPendingItems: [{
          text: 'Creditor/Furnisher',
          align: 'start',
          value: 'creditor',
          sortable: false
        },
        { text: 'Account #', value: 'account',
          sortable: false },
        { text: 'Reason', value: 'reason',
          sortable: false },
        { text: 'Disputed', value: 'disputed',
          sortable: false },
        { text: 'trn', value: 'trn',
          sortable: false },
        { text: 'exp', value: 'exp',
          sortable: false },
        { text: 'eq', value: 'eq',
          sortable: false },],
          headerDisputeItems: [{
          text: 'Creditor/Furnisher',
          align: 'start',
          value: 'creditor',
          sortable: false
        },
        { text: 'Account #', value: 'account',
          sortable: false },
        { text: 'Reason', value: 'reason',
          sortable: false },
        { text: 'Disputed', value: 'disputed',
          sortable: false },
        { text: 'trn', value: 'trn',
          sortable: false },
        { text: 'exp', value: 'exp',
          sortable: false },
        { text: 'eq', value: 'eq',
          sortable: false },
          { text: 'delete', value: 'delete',
          sortable: false },
          ],
        pendingItems : [],
        displayFlag :[],
        disputeItems: [],
        categories: [],
        customGeneratedBureau : [],
        transunion_letter : '',
        experian_letter: '',
        letterNameOptions : [],
        categories : [],
        equifax_lettr: '',
        transunion_address : '',
        experian_address:"",
        client_address : '',
        equifax_address :'',
        radioLetter : 'round_2',
        radioLetterRecipient: '',
        StatusOptions : ['Negative', 'Positive', 'Repaired', 'Deleted', 'In Dispute', 'Verified', 'Updated', 'Unspecified', 'Ignore'],
        reasonOptions: ['Please correct/update this inaccurate information on my credit report.','The following accounts were closed by me and should state that','The following account was a Bankruptcy/Charge-off. Balance should be $0','I never gave written permission for this account to be  on my consumer report','This account is not reporting to Experian or Equifax','This account is in violation of my consumer rights'],
        instructionOptions: ['Please correct/update this inaccurate information on my credit report.','Please remove it from my credit report.','This is not mine. I am a victim of ID Theft and I have included a police report. Please investigate and remove from my credit report.','Please supply information on how you have verified this item.','Please ensure that all information is accurate'],
        userFeedback : {
          inquiry : {
            check : [],
            status : {
              transunion : 'Negative',
              experian : 'Negative',
              equifax : 'Negative'
            },
            reason :'',
            instruction : '',
            new_reason: false,
            new_instruction: false
          },
          public_record : [],
          account_history: []
        },
        letterNameOptions : [],
        letter_category : '',
        step2: false,
        step3 :false,
        FinalData: {
          profile : {
                score : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                report_date : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                name : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                known_as : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                birth : {
                  transunion : '',
                  experian : '',
                  equifax : '',
                },
                current_address: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                employer: {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
                previous_address : {
                  transunion : [],
                  experian : [],
                  equifax : [],
                },
          },
          credit_summary : {
                  transunion : {},
                  experian : {},
                  equifax : {},
                
          },
          credit_inquiries: [],
          public_record : [],
          account_history : []
        }
      }
    },

    methods: {
      tableExpand(flag, index){
        

        if (flag === 1) {
          if(!this.displayFlag.includes(index))
            this.displayFlag.push(index);
        } else if(flag === 2) {
         const tmp = this.displayFlag;
         this.displayFlag = [];
         tmp.forEach(val => {
           if(val != index){
             this.displayFlag.push(val);
           }
         })
        }
      },
    assignDisputeItems(){
      this.disputeItems = this.seletedPendingItems;
      let self = this;
      var filtered = this.pendingItems.filter(function(value, index, arr){ 
        if(!self.seletedPendingItems.includes(value)){
            return value
        }
        
    });
    this.pendingItems = filtered;
    this.addPendingItemDialog = false;
    },
    saveLetter(){
      let self = this;
      self.overlay = true;
      let id  = this.$route.params.id;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        let req = JSON.parse(localStorage.getItem('credit-report-step-3'));
        axios
        .post("/api/save-client-letter",{
          id : id,
          letter_round : this.letter_round,
          letter_name : this.letter_name,
          transunion_letter : this.transunion_letter,
          experian_letter : this.experian_letter,
          equifax_letter : this.equifax_lettr,
          custom_bureau : this.customGeneratedBureau
        },header)
        .then(response => {
              Vue.$toast.success('Letter saved', {
              position: 'top-right'
              });
              if(this.role.name == 'client')
                this.$router.push('/send-letter/'+this.$route.params.id)
              else
                this.$router.push('/admin/send-letter/'+this.$route.params.id)
              this.overlay = false;
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
            this.overlay = false;
           });
    },
    generateLtterSave(){
      if(!this.letter_category || !this.letter_name){
        Vue.$toast.error('Select Letter to continue', {
            position: 'top-right'
            });
      }else{
        localStorage.setItem('credit-report-step-3',JSON.stringify([{
          dispute_items : this.disputeItems,
          letter_category : this.letter_category,
          letter_name : this.letter_name
        }]))
        if(this.role.name == 'client')
          this.$router.push('/credit-report-wizard-step-3/'+this.$route.params.id)
        else
          this.$router.push('/admin/credit-report-wizard-step-3/'+this.$route.params.id)
      }
    },
    unAssignDisputeItems(item){
      let self = this;
      var filtered = this.disputeItems.filter(function(value, index, arr){ 
        if(value != item){
            return value
        }
        
    });
    this.disputeItems = filtered;
    this.pendingItems.push(item);
    this.addPendingItemDialog = false;
    this.seletedPendingItems = [];
    },
    removeBureau(item){
      if(confirm('Are you sure, You want to remove?')){
        let arr = this.customGeneratedBureau.filter(function(value, index){ 
            return value != item;
        });
        this.customGeneratedBureau = arr ;
        arr = this.customBureau.filter(function(value, index){ 
            return value.name != item.name;
        });
        this.customBureau = arr ;
      }
    },
    generateLeter(){
      if(this.disputeItems.length == 0){
        Vue.$toast.error('No item seleted', {
            position: 'top-right'
            });
      }else{
        this.step3 = true;
      }
    },
    saveCustomBureau(){
      let exist_flag = false;
      this.customBureau.forEach(element => {
        if(element.name == this.newBureau.name)
          exist_flag = true;
      });
      if(!exist_flag){
        this.customBureau.push(this.newBureau);
        this.getUserProfile();
        this.addBureauFlag = false;
        this.newBureau = {name : '', letter_category : '', letter_name : ''}
      }else{
        alert('Bureau name already exist!')
      }
    },
    getUserProfile() {
    let flag = 1;
    let self = this;
    self.overlay = true;
    let id  = this.$route.params.id;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      let req = {bureau : JSON.parse(localStorage.getItem('credit-report-step-3')), custom_bureau : this.customBureau };
      axios
        .post("/api/get-generated-letter/"+id,req,header)
        .then(response => {
              this.transunion_letter = response.data.letter_trn;
              this.experian_letter = response.data.letter_exp;
              this.equifax_lettr = response.data.letter_eq;
              this.transunion_address = response.data.address_trn;
              this.experian_address = response.data.address_exp;
              this.equifax_address = response.data.address_eq;
              this.client_address = response.data.client_address
              this.customGeneratedBureau = response.data.custom_bureau;
              this.overlay = false;
         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
    calculatePendingItems(){
      let item = JSON.parse(localStorage.getItem('credit-report-saved-items'))
      let id = 1;
      console.log(item);
      if(item.inquiry.check.length != 0){
        item.inquiry.check.forEach(val => {
          this.pendingItems.push({
            transunion : val.includes(",transunion") ? 1 : 0,
            experian : val.includes(",experian") ? 1 : 0,
            equifax : val.includes(",equifax") ? 1 : 0,
            creditor : val.split(',')[0],
            account : {
              transunion : '',
              experian : '',
              equifax : ''
            },
            reason : item.inquiry.reason,
            disputed : 'No',
            id : id
          })
          id = id+1;
        })
      }
      item.public_record.forEach(val => {
        if(val.status.transunion == 'Negative' || val.status.experian == 'Negative' || val.status.equifax == 'Negative'){
          this.pendingItems.push({
            transunion : val.status.transunion == 'Negative' ? 1 : 0,
            experian : val.status.experian == 'Negative' ? 1 : 0,
            equifax : val.status.equifax == 'Negative' ? 1 : 0,
            creditor : val.creditor,
            account : {
              transunion : val.status.transunion == 'Negative' ? val.account.transunion.substring(0, 5) + '****' : '',
              experian : val.status.experian == 'Negative' ? val.account.experian.substring(0, 5) + '****' : '',
              equifax : val.status.equifax == 'Negative' ? val.account.equifax.substring(0, 5) + '****': ''
            },
            reason : val.reason,
            disputed : 'No',
            id : id
          })
          id = id+1;
        }
      })
      item.account_history.forEach(val => {
        if(val.status.transunion == 'Negative' || val.status.experian == 'Negative' || val.status.equifax == 'Negative'){
          this.pendingItems.push({
            transunion : val.status.transunion == 'Negative' ? 1 : 0,
            experian : val.status.experian == 'Negative' ? 1 : 0,
            equifax : val.status.equifax == 'Negative' ? 1 : 0,
            creditor : val.creditor,
            account : {
              transunion : val.status.transunion == 'Negative' ? (val.account.transunion ? val.account.transunion.substring(0, 5) + '****' : '') : '',
              experian : val.status.experian == 'Negative' ? (val.account.experian ? val.account.experian.substring(0, 5) + '****' : '') : '',
              equifax : val.status.equifax == 'Negative' ? (val.account.equifax ? val.account.equifax.substring(0, 5) + '****' : ''): ''
            },
            reason : val.reason,
            disputed : 'No',
            id : id
          })
          id = id+1;
        }
      })
      
    },

    getCategory() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-category",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.categories = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getLeterName() {
    let id = this.newBureau.letter_category;
    let client_id = localStorage.getItem('selected_client');
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-letter-category/"+id+'/'+client_id,header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.letterNameOptions = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },

    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })
                  //this.$router.push('company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },
    getCategory() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-category",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.categories = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },

 },

created() {
      console.log('created called.');
      this.getUserProfile()     
      this.getCategory(); 

    },

}
</script>
<style >
.heading-h2 {
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 38px;
  letter-spacing: normal;
  color: #4a4a4a;
}
.v-input__slot {
  align-items: center;
  justify-content: center;
}
.checkbox-credit .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  left: 0px;
  right: auto;
  position: relative;
  text-align: center!important;
  display: contents!important;
}
.red--text .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
  color: red;
}

</style>

<style type="text/css">
@keyframes ldio-7xwhhkt0pnf {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-7xwhhkt0pnf div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-7xwhhkt0pnf linear 1s infinite;
  background: #ffffff;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-7xwhhkt0pnf div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #ffffff;
}.ldio-7xwhhkt0pnf div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #ffffff;
}
.loadingio-spinner-spinner-2k3nmd4xown {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-7xwhhkt0pnf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-7xwhhkt0pnf div { box-sizing: content-box; }
/* generated by https://loading.io/ */
.radio-div .red--text .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  color: black!important;
}
.letter-tab .v-tab{
  border-right :solid 1px #ccc !important;
  border-bottom : solid 1px #ccc;
}
.j-c-sb {
  justify-content: space-between !important;
}
.border {
  border: solid 1px #dedede !important;
}
.default-color a {
  color: #4a4a4a !important;
}
.font-500 {
  font-weight: 500 !important;
}
.font-700 {
  font-weight: 700 !important;
}
.letter-tab .v-tabs-slider-wrapper{
 display: none;
}
.letter-tab .v-tab--active {
  border-bottom : solid 0px #dedede !important;
}
</style>