<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6">
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
               <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                  
                      <div class="code-info">
                        <v-row>
                          <v-col cols="12" md="8">
                            <a ng-if="!isAbTestEnabled_CNV2467()" ng-href="#!/insights?folder=active&amp;id=59498223" class="qr-manage-code__insights ng-binding ng-scope" ng-click="openInsights($event)" data-test="manage-qrcode-list-item-open-insights"  @click="$router.push('/admin/qr-codes')"><i class="fa fa-arrow-left mr-2" aria-hidden="true" style="color:  #47bdef ;"></i>Back</a>
                          </v-col>
                          <v-col cols="12" md="8">
                            <div class="qr-manage-code__type ng-binding mt-5"  data-test="manage-qrcode-list-company-type">{{company.type}}</div>
                            <div class="qr-manage-code__title mt-2"><i class="fa fa-globe" aria-hidden="true" v-if="company.type =='Website'" ></i><i class="fa fa-address-card" aria-hidden="true" v-if="company.type =='VCard'" ></i> {{company.name }} </div>

                            <div class="qr-manage-code--row-first--col-left"><span class="qr-manage-code__folder-icon icon icon-qr-folder-outline"></span><div class="qr-manage-code__folder"><span class="qr-manage-code__folder-name ng-binding ng-scope"  data-test="manage-qrcode-list-company-folder"><i class="fa fa-folder mr-3" aria-hidden="true" ></i> No folder</span></div><div class="qr-manage-code__date ng-binding"><span class="qr-manage-code__date-icon icon icon-business-time"></span><i class="fa fa-clock mr-3" aria-hidden="true" ></i> {{getDate(company.created_at)}}</div></div>

                            <div class="qr-manage-code--row-first--col-right">
                            <div v-if="!$vuetify.breakpoint.xs" class="qr-manage-code__url" ng-show="::(getUrl() || !value.trackable)"><span class="qr-manage-code__url-icon icon icon-qr-link" ng-show="::getUrl()"></span><div class="qr-manage-code__url-container"><a class="qr-manage-code__url-link ng-binding"  target="_blank"  ng-bind="getUrl()" data-test="manage-qrcode-list-company-shorturl" :href="app_url+'/redirect-qr/'+company.id"><i class="fa fa-link mr-3" aria-hidden="true" ></i>{{app_url}}/redirect-qr/{{company.id}}</a><!-- ngIf: !isAbTestEnabled_CNV2467() --><i ng-if="!isAbTestEnabled_CNV2467()" class="qr-manage-code__edit-icon icon icon-avatar-edit ng-scope" ng-show="::getUrl()" ng-click="openShortUrlEdit()" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit Short URL"></i><!-- end ngIf: !isAbTestEnabled_CNV2467() --><!-- ngIf: isAbTestEnabled_CNV2467() --></div><div class="qr-manage-code__target ng-scope" ng-show="getTarget()" ng-if="!isDemo"><span class="qr-manage-code__target-icon icon icon-qr-link-arrow" ng-show="::value.trackable"></span><a  target="_blank" data-test="manage-qrcode-list-company-url" class="ng-binding" :href="company.link"><i class="fa fa-arrow-down mr-3" aria-hidden="true" ></i> {{company.link}}<span  class="ng-binding"></span></a></div><!-- end ngIf: !isDemo --></div>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" style="justify-content: center; display: grid;">
                            <img v-if="company.custom_qr_upload" :src="api_url+'qrcust_'+company.id+'.'+company.custom_qr_ext" height="130" class="mt-2">
                            <img v-else :src="api_url+'qr_'+company.id+'.png'" height="130" class="mt-2">
                            <div>
                            <v-btn
                            class="mt-2"
                            tile
                            v-if="company.custom_qr_upload"
                            outlined
                            style="width: 130px;"
                            @click="downloadFile(api_url+'qrcust_'+company.id+'.'+company.custom_qr_ext)"
                            color="blue"
                          >
                             Download
                          </v-btn>
                          <v-btn
                            class="mt-2"
                            tile
                            v-else
                            outlined
                            style="width: 130px;"
                            @click="downloadFile(api_url+'qr_'+company.id+'.png')"
                            color="blue"
                          >
                             Download
                          </v-btn>
                          </div>
                            </v-col>
                        </v-row>
                      </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
               <v-card class="card-shadow border-radius-xl mt-5" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                  
                      <div class="code-info">
                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="qr-campaign-info__total-scans"><span class="title ng-binding">Total Scans</span><div class="total-scans ng-binding">{{company.scan_count}}</div><span class="divider">/</span><div class="unique-scans ng-binding">{{ company.scan_count }} Unique</div></div>
                            
                          </v-col>

                        </v-row>
                      </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
               <v-card class="card-shadow border-radius-xl mt-5" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                  
                      <div class="code-info">
                        <v-row>
                          <v-col cols="12" md="6">
                            <date-picker @input="getQRRange(),getQRRangeCategory()" v-model="dateRange" type="date" :shortcuts="shortcuts" range placeholder="Select date range"></date-picker>
                          </v-col>
                          <v-col cols="12" md="6">
                          <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="grey"
                                  
                                  outlined
                                  tile
                                  class="float-right"
                                  style="text-transform: none;"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                <i class="fa fa-cog mr-2" aria-hidden="true" ></i> Options <i class="fa fa-chevron-down ml-2" aria-hidden="true" ></i>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  
                                >
                                  <v-list-item-title>
                                    <vue-json-to-csv :json-data="total_csv"
                                    :labels="{ created_at: { title: 'Date/Time' },country : {title : 'Country'},city : {title : 'City'},zip : {title : 'Zip'},os : {title : 'OS'}, visitor : {title : 'Visitor'} }"
                                    csv-title="qr-code-generator-report"
                                    >
                                     <v-btn
                                     style="text-transform: none;"
                                    color="grey"
                                      class="
                      
                                                      
                                                    "
                                    text
                                    >
                                    Download as CSV
                                    </v-btn>
                                  </vue-json-to-csv></v-list-item-title>
                                  </v-list-item>
                                  <v-list-item
                                  
                                >
                                  <v-list-item-title><v-btn
                                    color="grey"
                                    @click="removeQRScan()"
                                    style="text-transform: none;"
                                      class="
             
                                                      
                                                    "
                                    text
                                    >
                                    Reset Scans
                                    </v-btn></v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            </v-col>
                          <v-col cols="12" md="6">
                            <BarChart :data="qr_data" :name="'SCANS OVER TIME'" />
                          </v-col>
                          <v-col cols="12" md="6">
                            <OS :data="os_data" :name="'SCANS BY OPERATING SYSTEM'" />
                          </v-col>
                          <v-col cols="12" md="6">
                            <Country :data="country_data" :name="'SCANS BY TOP COUNTRIES'" />
                          </v-col>
                          <v-col cols="12" md="6">
                            <City :data="city_data" :name="'SCANS BY TOP CITIES'" />
                          </v-col>
                          <v-col cols="12" md="6">
                            <Zip :data="zip_data" :name="'SCANS BY TOP ZIP'" />
                          </v-col>

                        </v-row>
                      </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
     <!-- <vue-footer></vue-footer> -->
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import moment from 'moment';
import BarChart from './Charts/Bar.vue'
import OS from './Charts/OS.vue'
import Country from './Charts/Country.vue'
import City from './Charts/City.vue'
import Zip from './Charts/Zip.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueJsonToCsv from 'vue-json-to-csv'
export default {
components: {
      VueTelInput,BarChart,DatePicker,OS, Country, City, Zip,VueJsonToCsv
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        loading : false,
        phone:'',
        password : '',
        total_csv : [],
        dateRange : [ this.getLast7Days(), new Date()],
        is_admin:0,
        confpassword : '',
        role : '',
        qr_data : [],
        country_data : [],
        city_data : [],
        os_data : [],
        zip_data : [],
        companyname : '',
        sector : '',
        no_of_employee : '',
        showPassword : false,
        app_url : window.location.origin,
        api_url: process.env.VUE_APP_WASBI_URL,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        otp_email:'',
        otp_phone:'',
        shortcuts: [
          {
            text: 'Today',
            onClick() {
              const date = [new Date(), new Date()];
              // return a Date
              return date;
            },
          },
          {
            text: 'Last 7 Days',
            onClick() {
              const today = new Date();
              const last7Days = new Date(today);
              last7Days.setDate(today.getDate() - 7);

              const dateRange = [last7Days, today];
              // return a Date range
              return dateRange;
            },
          },
          {
            text: 'Last 30 Days',
            onClick() {
              const today = new Date();
              const last30Days = new Date(today);
              last30Days.setDate(today.getDate() - 30);

              const dateRange = [last30Days, today];
              // return a Date range
              return dateRange;
            },
          },
          {
            text: 'Last 12 Months',
            onClick() {
              const today = new Date();
              const last12Months = new Date(today);
              last12Months.setFullYear(today.getFullYear() - 1);

              const dateRange = [last12Months, today];
              // return a Date range
              return dateRange;
            },
          },
        ],
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: "",
          address: '',
          city: '',
          state: '',
          zip:'',
          question_1 : '',
          question_2 : '',
          question_3 : '',
          question_4 : '',
          question_5 : ''

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    getLast7Days() {
      const today = new Date();
      const last7Days = new Date(today);
      last7Days.setDate(today.getDate() - 7);
      return last7Days;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address_1 = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },



    add: function () {
         // for setting tab
    },
    getDate(date){
      return moment(date).format('ll');
    },
    downloadFile(url) {

    const imageName = 'your-image.png'; // Replace with the actual image name
    const downloadUrl = url;

    // Create a hidden link to trigger the download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.style.display = 'none';
    document.body.appendChild(link);
    window.open(downloadUrl, '_blank');
    // Trigger the download
    // link.click();

    // Remove the link from the document
    document.body.removeChild(link);
    },
    getUserProfile() {
    let flag = 1;
    let id  = this.$route.params.id;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-qr-detail/"+id,header)
        .then(response => {
            let data = response.data.data;

           if (flag == 1) {
             console.log(data);
                this.company = data

            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
     getQRRange() {
      let flag = 1;
      let id  = this.$route.params.id;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .post("/api/get-scaned-qr/"+id,{start_date : moment(this.dateRange[0]).format('YYYY-MM-DD'),end_date : moment(this.dateRange[1]).format('YYYY-MM-DD')},header)
          .then(response => {
              let data = response.data.data;

            if (flag == 1) {
              console.log(data);
                  this.qr_data = data

              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }

          })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });

            });

      },
      removeQRScan() {
      let flag = 1;
      if(confirm('Are you sure you want to reset?')){
      let id  = this.$route.params.id;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .get("/api/remove-scaned-qr/"+id,header)
          .then(response => {
              let data = response.data.data;

            if (flag == 1) {
              this.getUserProfile()
              this.getQRRange();
              this.getQRRangeCategory()

              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }

          })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });

            });
          }

      },
      getQRRangeCategory() {
      let flag = 1;
      let id  = this.$route.params.id;
      let header = { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  }
          }
        axios
          .post("/api/get-scaned-qr-category/"+id,{start_date : moment(this.dateRange[0]).format('YYYY-MM-DD'),end_date : moment(this.dateRange[1]).format('YYYY-MM-DD')},header)
          .then(response => {
              let data = response.data.data;

            if (flag == 1) {
                  this.country_data = data.country
                  this.city_data = data.city
                  this.os_data = data.os
                  this.zip_data = data.zip
                  this.total_csv = data.total

              }else {
                  Vue.$toast.error(data.message, {
                  position: 'top-right'
                  });
              }

          })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });

            });

      },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let id  = this.$route.params.id;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.name) {
            Vue.$toast.error('Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_email && flag == 1) {
            Vue.$toast.error('Contact email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_phone && flag == 1) {
            Vue.$toast.error('Contact phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        if (this.company.company_url && /\s/g.test(this.company.company_url) && flag == 1) {
            Vue.$toast.error("Company URL should not contain spaces ", {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
        if (flag == 1) {
          this.loading = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-company/"+id,
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Company edited', {
                    position: 'top-right'
                  })
                  this.loading = false
                  // this.$router.push('/company-profiles')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
                this.loading = false;
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });   
              if(err.response.data.errors.company_url)
                Vue.$toast.error(err.response.data.errors.company_url[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getUserProfile();
      this.getQRRange()
      this.getQRRangeCategory()
    },

}
</script>

<style>
.code-info {
  position: relative;
  margin: 0 auto;
  max-width: 1300px;
}
.qr-campaign-info__total-scans {
  position: relative;
  display: inline-block;
  width: 100%;
}
.qr-campaign-info__total-scans .title {
  position: relative;
  display: block;
  color: #a4a6a8;
  font-weight: 600;
}
.qr-campaign-info__total-scans .total-scans {
  position: relative;
  display: inline-block;
  top: 2px;
  color: #90c52d;
  font-weight: 700;
  font-size: 35px;
}
.qr-campaign-info__total-scans .divider {
  color: #797d80;
  margin: 0 10px;
}
.qr-campaign-info__total-scans .unique-scans {
  position: relative;
  display: inline-block;
  color: #84888b;
  font-weight: 600;
}
.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 118px!important;
}
.mx-datepicker-sidebar {
  width: 116px!important;
}
.horizontal-line {
  position: relative;
  width: 85%;
  top: 40px;
  margin: -5px auto 20px;
  border-top: 1px solid #eaeaea;
}
</style>