<template>
  <v-app class="bg-white">
    <!-- <vue-header-no-login></vue-header-no-login> -->
    <v-main class="auth-pages " 
        :style="`background-image: url(${backgroundLogo}); background-size: cover;` ">
        <span class="mask bg-gradient-default opacity-6" style="background-color: #000000;"></span>
      <v-row class="min-vh-100" style="height: 100%;margin: 0 0 0 0;" >
        <v-col cols="12" md="12" lg="12">
          <v-container class="pb-0 position-relative new-login-left" style=" justify-content: space-around;position: relative;" >
                
                <v-row class=" new-login-left-box" style="" align="center" justify="center">
                        <div style="text-align: center;">
                        <img  :height="this.$vuetify.breakpoint.xs ? 70 : 100" class="image-logo2" src="@/assets/img/logo.png"> 
                          </div>
                        <v-card class="new-login-left-form " outlined style="background-color:transparent;" v-if="!forgot_flag">
                          
                         
                         <div class="card-padding pb-0"  >
                                  
                                  <h2 class=" mb-2 text-label text-white" style=" font-size: 25px;font-weight: 600;">Sign in</h2>
                                  
                                </div>
                                <div class="card-padding pb-4">
                              
                                  <v-text-field
                                    label="Email"
                                    color=" #3498db "
                                    outlined
                                    rounded
                                    ref="email"
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>

                                  <v-text-field
                                    label="Password"
                                    color=" #3498db "
                                    prepend-inner-icon="fa-key"
                                    ref="password"
                                    outlined
                                    rounded
                                    v-model="password"
                                    dense
                                    :type="showpassword ? 'text' : 'password'"
                                    :append-icon="showpassword ? 'fa-eye' : 'fa-eye-slash'"
                                    @click:append="showpassword = !showpassword"
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    :loading="buttonFlag"
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#3498db"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="checkForm"
                                    >Log in</v-btn
                                  >
                                  <!-- <div class="text-center">
                                    <p class="text-sm text-body font-weight-light mt-3 mb-0" style="color:#7b809a !important;">No account yet? 
                                      <a
                                        @click="$router.push('/signup')"
                                        style="color: #4CAF50;"
                                        class="
                                            text-decoration-none
                                          font-weight-bold
                                        "
                                        >Sign up</a
                                      >
                                    </p>
                                  </div> -->
                                  <div class="text-center">
                                    <p class="text-sm text-body font-weight-light mt-3 mb-0" style="color:#7b809a !important;">
                                      <a
                                        @click="forgot_flag = true"
                                        class="
                                            text-decoration-none
                                          font-weight-bold text-white
                                        "
                                        >Forgot password?</a
                                      >
                                    </p>
                                  </div>
                                </div>
                        </v-card>
                        <v-card class="new-login-left-form" outlined style="background-color:transparent;" v-if="forgot_flag">
                         
                         <div class="card-padding pb-0" style="text-align:center;" >
                                  
                                  <h2 class=" mb-2 text-label text-white" style=" font-size: 25px;font-weight: 600;">Reset password</h2>
                                  
                                </div>
                                <div class="card-padding pb-4">
                              
                                  <v-text-field
                                    label="Email"
                                    color="#3498db"
                                    outlined
                                    rounded
                                    v-model="email"
                                    prepend-inner-icon="fa-envelope"
                                    dense
                                    class="font-size-input input-style py-0 input-login-new"
                                  ></v-text-field>                                 
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    :loading="buttonFlag"
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#3498db"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgotPass"
                                    >Submit</v-btn
                                  >
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="grey"
                                    small
                                    style="margin-top: -40px;"
                                    v-on:click="forgot_flag = false"
                                    >Back to login</v-btn
                                  >
                                 
                                </div>
                        </v-card>
                        <!-- <div class="card-padding pb-0 new-login-left-form" style="text-align:center;border: none;" >
                                  
                          <p class="mb-0 text-label ">
                                    If you don't have an account. Get start from here
                                  </p>
                                  <v-btn
                                    elevation="0"
                                    :ripple="false"
                                    height="43"
                                    rounded
                                    class="
                                      font-weight-bold
                                      text-uppercase
                                      btn-primary
                                      
                                      py-2
                                      px-6
                                      me-2
                                      mt-7
                                      mb-2
                                      w-100
                                    "
                                    color="#2E639F"
                                    small
                                    style="margin-top: -40px;"
                                    @click="$router.push('/signup')"
                                    >Join US Now!</v-btn
                                  >
                                </div> -->
                      <!-- </v-col> -->
                    </v-row>
                    </v-container>
        </v-col>
        <!-- <v-col cols="12" md="6" lg="6" style="background-color: transparent;
background-image: linear-gradient(180deg, #1D4BB6 0%,  #3498db  100%);padding-bottom: 0px!important;">
          <v-container class="pb-0 position-relative new-login-left" style=" justify-content: space-around;position: relative;" >
                
                <v-row class=" new-login-left-box" style="" align="center" justify="center">
                <div style="text-align: left;">
                  <h3 class="score-header-1"><span style="color: #ffffff;">BUILDING CREDIT SMARTER</span>
                  </h3>
                  </div>
                  <div style="text-align: left;" class="mt-10">
                  <h3 class="score-header-2"><span style="color: #ffffff;">Build The Credit Score You Deserve!</span>
                  </h3>
                  </div>
                  <div class="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline mt-10">
                  <video  class="elementor-video" src="https://thescorezoom.com/wp-content/uploads/2022/09/scorezoomintrovideo.mp4" autoplay="" loop="" controls="" playsinline="" controlslist="nodownload"></video>
                  </div>
                </v-row>
          </v-container>
          
        </v-col> -->


      </v-row>
                    
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      showPassword : false,
      dialog: false,
      pageFlag: 'init',
      forgot_flag : false,
      backgroundLogo: require('@/assets/img/logo-bg.jpg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    if (localStorage.getItem('token')) {
        if(localStorage.getItem('is_admin') && localStorage.getItem('is_admin') == 1)
            this.$router.push('/admin/qr-codes')
        else
            this.$router.push('/dashboard')
    }
},
created: function () {
  this.url = window.location.origin;//this.$route.params.url ? this.$route.params.url : '';console.log(this.$route)
  // if(this.url)
  //     this.getCompanyInfo(this.url)
  // else
  //     this.loadingFlag = true;
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            this.company = response.data.company;
            console.log(this.company);
            this.loadingFlag = true;
            this.loadingLogo = true;
          })

},
forgotPass(){
  if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1,
            frontend_url : this.url,
            company : this.company,
            company_flag : this.company ? true : false
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Please check your email for change password', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.forgot_flag = false;
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
        }
},
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
// forgotPass(){
//     this.buttonFlag =true;
//     this.isDark = false;
//     axios({
//           method: "POST",
           
//           url: "/api/auth/forgot-password",
//           data: {
//             email: this.email,
//           }
//         })
//           .then(response => {
//                 this.sendFlag = 1;
//                 Vue.$toast.success('Forgot Password Mail sent', {
//                 position: 'top-right'
//                 });
//                 this.buttonFlag =false;
//                 this.isDark = true;
//                 this.pageFlag = 'login';
//           })
//           .catch(err => {
//             Vue.$toast.error(err, {
//             position: 'top-right'
//             });
//           })
// },
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
 checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login-admin",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("first_name", result.first_name);
              localStorage.setItem("email", result.email);
              localStorage.setItem("phone", result.phone_m);
              localStorage.setItem("is_admin", result.is_admin);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("role", JSON.stringify(result.roles[0]));
              localStorage.setItem("company_name", result.company_name);
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
              localStorage.setItem("company_id", result.company_id);
              if(result.roles[0].name !='super_admin')
                localStorage.setItem("agreement_flag", result.agreement);
              if(result.roles[0].name =='super_admin')
                this.$router.push("/admin/qr-codes");
              else{
              if(result.agreement == 0)
                this.$router.push("/admin/qr-codes");
              else
                this.$router.push("/admin/qr-codes");
              }
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login-new .v-icon{
    color:  #3498db !important;
}
.input-login-new .v-label{
  color:  #3498db !important;
}
.input-login-new #input-6{
  color:  #3498db !important;
}
.input-login-new #input-10{
  color:  #3498db !important;
}
.new-login-left {
  height: 100%;
  display: table;
  position: relative;
}
.new-login-left-box {
  display: table-cell;
  vertical-align: middle;
  height: 770px;
}
.new-login-left-form {
  max-width: 550px;
  width: 90%;
  margin: 30px auto 0;
  border: 0px solid #dedede!important;
  box-sizing: border-box;
}
.text-label{
  font-family: Arial,Helvetica,sans-serif!important;color: rgb(123, 128, 154) !important;font-weight: 400;
}
.default-color-code{
  color:  #3498db !important;
}
.score-header-1{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 800;
  font-family: "Poppins", Sans-serif;
}
.score-header-2{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.e-hosted-video video {
  max-width: 100%;
width: 100%;
margin: 0;
line-height: 1;
border: none;
}
.input-login-new input {
  color: white!important;
}
</style>