var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('vue-header-admin'),_c('v-main',[_c('v-container',{staticClass:"px-6 py-6",staticStyle:{"margin-bottom":"150px"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-12 mt-5"},[_c('v-col',{staticClass:"mt-5",attrs:{"md":"12"}},[_c('v-card',{staticClass:"card-shadow border-radius-xl",class:_vm.$route.name == 'VrDefault' ? 'bg-gradient-default' : ''},[_c('div',{staticClass:"px-6 pt-4 pb-0 d-flex"},[_c('v-avatar',{staticClass:"shadow",attrs:{"color":"bg-gradient-success border-radius-xl mt-n8","height":"64","width":"64"}},[_c('v-icon',{staticClass:"material-icons-round text-white",attrs:{"size":"24"}},[_vm._v("language")])],1),_c('p',{staticClass:"font-weight-bold text-h6 mb-0 ms-3",class:_vm.$route.name == 'VrDefault' ? 'text-white' : 'text-typo'},[_vm._v(" Grants ")])],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12"}},[_c('v-card-text',{staticClass:"py-3"},[_c('v-list',{staticClass:"py-0 bg-transparent"},[_c('v-list-item',{staticClass:"px-4 py-1"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","label":"Focus Area","disabled":_vm.loadingAssign,"items":_vm.focusarea},on:{"change":function($event){return _vm.getDashboardGrant()}},model:{value:(_vm.selected_focus_area),callback:function ($$v) {_vm.selected_focus_area=$$v},expression:"selected_focus_area"}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0 ml-10",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name == 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Grant Total: "+_vm._s(_vm.focus_area_grant_total)+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name == 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Grant Completed: "+_vm._s(_vm.focus_area_grant_completed)+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name == 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Grant Value: "+_vm._s(_vm.getValue(_vm.focus_area_value))+" ")])])],1)],1)],1),_c('v-list',{staticClass:"py-0 bg-transparent"},[_c('v-list-item',{staticClass:"px-4 py-1"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","label":"Location:","disabled":_vm.loadingAssign,"items":_vm.locations},on:{"change":function($event){return _vm.getDashboardGrant()}},model:{value:(_vm.selected_location),callback:function ($$v) {_vm.selected_location=$$v},expression:"selected_location"}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0 ml-10",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name == 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Grant Total: "+_vm._s(_vm.location_grant_total)+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name == 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Grant Completed: "+_vm._s(_vm.location_grant_completed)+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name == 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Grant Value: "+_vm._s(_vm.getValue(_vm.location_value))+" ")])])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"6","md":"5"}},[_c('div',{staticClass:"mt-0 mt-lg-n4 w-100 h-100 overflow-hidden",attrs:{"id":"map"}})])],1)],1)],1)],1),_c('v-row',{staticClass:"mb-6 mt-10"},_vm._l((_vm.imageCards),function(item,i){return _c('v-col',{key:item.title + i,staticClass:"pt-0 mb-lg-0 mb-10",attrs:{"lg":"4"}},[_c('v-card',{staticClass:"card card-shadow border-radius-xl py-5 text-center",attrs:{"data-animation":"true"}},[_c('div',{staticClass:"mt-n11 mx-4 card-header position-relative z-index-2"},[_c('div',{staticClass:"d-block blur-shadow-image"},[_c('img',{staticClass:"img-fluid shadow border-radius-lg",attrs:{"src":item.image,"alt":item.image}})]),_c('div',{staticClass:"colored-shadow",style:({ backgroundImage: 'url(' + item.image + ')' })})]),_c('div',{staticClass:"d-flex mx-auto mt-n8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"material-icons-round text-primary ms-auto px-5",attrs:{"size":"18"}},'v-icon',attrs,false),on),[_vm._v(" refresh ")])]}}],null,true)},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"material-icons-round text-info me-auto px-5",attrs:{"size":"18"}},'v-icon',attrs,false),on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1),_c('h5',{staticClass:"font-weight-normal text-typo text-h5 mt-7 mb-2 px-4"},[_c('a',{staticClass:"text-decoration-none text-default",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(item.title))])]),_c('p',{staticClass:"mb-0 text-body font-weight-light px-5"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('hr',{staticClass:"horizontal dark my-6"}),_c('div',{staticClass:"d-flex text-body mx-2 px-4"},[_c('p',{staticClass:"mb-0 font-weight-normal text-body"},[_vm._v(" $"+_vm._s(item.price)+"/night ")]),_c('i',{staticClass:"\r\n                  material-icons-round\r\n                  position-relative\r\n                  ms-auto\r\n                  text-lg\r\n                  me-1\r\n                  my-auto\r\n                "},[_vm._v("place")]),_c('p',{staticClass:"text-sm my-auto font-weight-light"},[_vm._v(" "+_vm._s(item.location)+" ")])])])],1)}),1)],1),_c('vue-footer-admin')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }