// steps.js

export const steps = [
    {
      title: "Step 0",
      fields: [
        { name: "full_name", label: "Full Name", type: "text", required: true, md: 12 },
        // { name: "company_name", label: "Company Name or Profession (Optional)", type: "text", md: 6 },
        { name: "email", label: "Email", type: "email", required: true, md: 6, },
        { name: "phone", label: "Phone", type: "tel", required: true, md: 6},
        { name: "message", label: "How Would You Like to Be Involved?", type: "textarea", required: true, md: 12},
        { name: "is_agree", label: "I agree to terms & conditions", type: "checkbox", required: true, md: 12 },
      ]
    },
    // Add more steps as needed
  ];
  