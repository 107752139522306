<template>
    <div>
      <div class="px-4 pt-2 pb-0 d-flex">
        <div class="ms-4">
          <h6 class="text-h6 text-typo font-weight-bold">{{name}}</h6>
         
        </div>
      </div>
      <div class="card-padding pt-0 px-4">
        <div class="chart">
          <canvas id="bar-chart" class="chart-canvas" height="200"></canvas>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Chart from "chart.js/auto";
  import 'chartjs-adapter-moment';
  export default {
    name: "bar-chart",
    data: function () {
      return {
        barChartId: "bar-chart",
        chart : '',
      };
    },
    props : ["name","data"],
    mounted() {
      let labels = [];
      let chart_data = [];
      this.data.forEach(element => {
          labels.push(element.date)
          chart_data.push(element.count)
      });
      console.log(labels);
      this.chart = new Chart(document.getElementById(this.barChartId).getContext("2d"), {type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Total',
                    data: chart_data,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)', // Bar color
                    borderColor: 'rgba(75, 192, 192, 1)', // Border color
                    borderWidth: 1 // Border width
                }]
            },
            options: {
                scales: {
                    x: {
                        type: 'time', // Use time scale for x-axis
                        time: {
                            unit: 'day' // Set the time unit to 'day'
                        },
                        title: {
                            display: true,
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                        }
                    }
                }
            }
        }
      
      );
    },
    methods : {
      updateChart() {
        let labels = [];
        let chart_data = [];
        this.data.forEach(element => {
          labels.push(element.date)
          chart_data.push(element.count)
        });
        this.chart.data.labels = labels;
        this.chart.data.datasets[0].data = chart_data;
        this.chart.update();
      },
    },
    watch: {
      data: function (newLabels, oldLabels) {
        // Update the chart here
        this.updateChart();
      },
      
    },
  };
  </script>
  