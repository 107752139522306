<template>
  <v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6 mb-10">
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <v-card-text style="margin-bottom: 30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class=" mb-5" style="color: rgba(0,0,0,.6);">Faxes Sent</h1>
                    </v-col>
                    <v-col cols="12">
                       <v-btn
                                    color="red"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="deleteProfile('all')"
                                    >
                                        Delete Selected
                                    </v-btn>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                        <v-data-table
                            :headers="headers"
                            :items="projects"
                            v-model="selectedItems"
                            show-select
                            item-key="FileName"
                            :items-per-page="10"
                            class="table"
                        >
                        <template v-slot:item.updated="{ item }">
                            {{ formatDate(item.updated) }}
                        </template>
                        <template v-slot:item.ToFaxNumber="{ item }">
                            {{ formatPhoneNumber(item.ToFaxNumber) }} / {{item.Subject}}
                        </template>
                        <template v-slot:item.Size="{ item }">
                            {{ formatBytes(item.Size) }}
                        </template>
                        <template v-slot:item.SentStatus="{ item }">
                            <span style="color:red;" v-if="item.SentStatus == 'Failed'">{{ item.SentStatus }} </span>
                            <span style="color:green;" v-else>{{ item.SentStatus }} </span>
                        </template>
                        <template v-slot:item.id="{ item,index }" >
                            {{ projects.indexOf(item)+1}}
                        </template>
                        <template v-slot:item.action="{ item }">
                             <v-btn
                                class="mx-2"
                                text
                                dark
                                x-small
                                @click="viewFax(item)"
                                title="View"
                                color="orange"
                              >
                                <v-icon size="20" class="material-icons-round opacity-10" dark>
                              visibility
                            </v-icon>
                              </v-btn>
                              <v-btn
                                class="mx-2"
                                text
                                dark
                                x-small
                                @click="resendFax(item)"
                                title="Resend"
                                color="green"
                              >
                               <v-icon size="20" class="material-icons-round opacity-10" dark>
                              send
                            </v-icon>
                              </v-btn>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-btn
                                class="mx-2"
                                text
                                dark
                                @click="deleteProfile(item.FileName)"
                                title="Delete item"
                                x-small
                                color="red"
                              >
                               <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                                </v-btn>

                        </template>
                        </v-data-table>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class=" mb-5" style="color: rgba(0,0,0,.6);">Project Jobs</h1>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <v-btn
                     style="float:right;text-transform: none;"
                      class="ma-2"
                      :loading="loading"
                      :disabled="loading"
                      dark
                      color="red"
                      @click="seletedItemsProcess('delete')"
                    >
                      Delete seleted
                    </v-btn>
                    <v-btn
                     style="float:right;text-transform: none;"
                      class="ma-2"
                      :loading="loading"
                      :disabled="loading"
                      dark
                      color="primary"
                      @click="seletedItemsProcess('cart')"
                    >
                      Add to cart seleted
                    </v-btn>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                        <v-data-table
                            :headers="headers_jobs"
                            :items="project_jobs"
                            v-model="selectedItems"
                            show-select
                            :server-items-length="jobCount"
                            :options.sync="optionsDatatable"
                            :footer-props="{
                                'items-per-page-options': [50, 100, 200]
                              }"
                            :items-per-page="50"
                            class="elevation-1"
                        >
                        <template v-slot:item.submittedDate="{ item }">
                            {{ formatDate(item.submittedDate) }}
                            </template>
                        <template v-slot:item.dateUpdated="{ item }">
                        {{ formatDate(item.dateUpdated) }}
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-btn
                                class="mx-2"
                                fab
                                dark
                                v-show="item.jobStatus == 'Editing'" @click="addToCart(item.id)"
                                x-small
                                title="Add to cart"
                                color="primary"
                              >
                                <v-icon dark>
                                  mdi-cart-plus
                                </v-icon>
                              </v-btn>
                              <v-btn
                                class="mx-2"
                                fab
                                v-show="item.jobStatus == 'In Cart'" :to="{path:'/admin/cart'}"
                                dark
                                x-small
                                title="Go to cart"
                                color="green"
                              >
                                <v-icon dark>
                                  mdi-cart-outline
                                </v-icon>
                              </v-btn>
                              <v-btn
                                class="mx-2"
                                fab
                                @click="viewProof(item.id)"
                                title="View Proof"
                                dark
                                x-small
                                color="blue-grey"
                              >
                                <v-icon dark>
                                  mdi-file-eye
                                </v-icon>
                              </v-btn>
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                @click="deleteProfile(item.id)"
                                title="Delete item"
                                x-small
                                color="red"
                              >
                                <v-icon dark>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            
                        </template>
                        
                        </v-data-table>
                    </v-col>
                  </v-row> -->
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <vue-footer-admin></vue-footer-admin>
</v-main>
<v-overlay :value="loading">
    <rotate-loader :loading="true" color="#fff"></rotate-loader>
  </v-overlay>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Accept Proof
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col
          cols="12"
          md="6"
          style="padding: 0px;"
          >
        <v-text-field
            id="password"
            label="Initials"
            name="initials"
            :rules="[rules.required, rules.min]"
            ref="initials"
            v-model="initials"
            :type="'text'"
        />

        </v-col>
         <v-col
        cols="12"
        md="12"
        style="padding: 0px;"
        >
        <v-checkbox
          v-model="checkbox"
          :rules="[rules.required]"
          :label="`Yes I have viewed the proof and approve them. `"
        ></v-checkbox>
        </v-col>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="!valid"
          @click="addToCartAPI"
        >
          ADD TO CART
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </v-app>
</template>

<script>
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
    components: {'RotateLoader': ClipLoader},
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        loading:false,
        dialog:false,
        initials:'',
        valid:true,
        offset: 0,
        limit : 50,
        item_per_page: 50,
        selectedItems:[],
        jobCount: 0,
        optionsDatatable: {},
        selected_job:'',
        selected_project:'',
        checkbox: '',
        headers: [
          {
            text: 'NO.',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Date', value: 'DateSent',
            sortable: true },
          { text: 'Recipient Fax / Subject', value: 'ToFaxNumber',
            sortable: false },
            { text: 'Pages', value: 'Pages',
            sortable: false },
            { text: 'Size', value: 'Size',
            sortable: false },
            { text: 'Status', value: 'SentStatus',
            sortable: false },
            { text: 'Options', value: 'action',
            sortable: false },
            { text: 'Delete', value: 'delete',
            sortable: false },
        ],
        headers_jobs: [
            {
            text: 'Job Number',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Product Name', value: 'envelope',
            sortable: false },
          { text: 'Document Name', value: 'document',
            sortable: false },
          { text: 'Mailing List', value: 'addressList',
            sortable: false },
          { text: 'Status', value: 'jobStatus',
            sortable: false },
          { text: 'Created', value: 'submittedDate',
            sortable: false },
          { text: 'Updated', value: 'dateUpdated',
            sortable: false },
          { text: 'Action', value: 'action',
            sortable: false },
        ],
        projects: [
          
        ],
        project_jobs: []
      }
    },
    watch: {
      optionsDatatable: {
        handler () {
          let self = this;
          self.item_per_page = self.optionsDatatable.itemsPerPage;
          self.offset = (self.optionsDatatable.page - 1) * self.item_per_page
          if(self.selected_project)
            self.getJobs(self.selected_project);
        },
        deep: true,
      },
    },
    created () { 
        this.getProjects();
    },
    methods:{
      formatBytes(bytes, decimals = 0) {
          if (bytes === 0) return '0 Bytes';

          const k = 1024;
          const dm = decimals < 0 ? 0 : decimals;
          const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

          const i = Math.floor(Math.log(bytes) / Math.log(k));

          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      },
      formatPhoneNumber(phoneNumberString) {
          let num = phoneNumberString.substring(1)
          var cleaned = ('' + num).replace(/\D/g, '')
          var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
          if (match) {
            return phoneNumberString.charAt(0)+' (' + match[1] + ') ' + match[2] + '-' + match[3]
          }
          return null
        },
        viewFax(item){
          let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        this.loading = true;
        axios
            .post("/api/viewfax",item,header)
            .then(response => {
            let data = response.data;
            if (data.status == true) {
                let url = '/faxes/'+data.url;
                let title = item.FileName;
                 var left = (screen.width/2)-(1200/2);
                var top = (screen.height/2)-(650/2);
                var targetWin = window.open (url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+1200+', height='+650+', top='+top+', left='+left);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        resendFax(item){
          let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        
          if(confirm('Are you sure you want to resend?')){
            // this.loading = true;
            axios
                .post("/api/resendfax",item,header)
                .then(response => {
                let data = response.data;
                if (data.status == true) {
                    Vue.$toast.success('Successfully Sent', {
                        position: 'top-right'
                        });
                    this.loading = false;
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }

                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });

              });
          }
        },
        seletedItemsProcess(flag){
          console.log(this.selectedItems);
          let msg = flag =='cart' ? "Are you sure, you want to add to cart?" : "Are you sure, you want to delete?"
          if(this.selectedItems.length != 0){
            if(confirm(msg)){
              this.loading = true;
              axios
                .post("/api/process-seleted-items",{'jobs': this.selectedItems, 'flag': flag},this.header)
                .then(response => {
                    let data = response.data;
                  console.log(data);
                  if (data.flag == 1) {
                      this.loading = false;
                      if(data.data){
                        if(flag =='cart')
                          Vue.$toast.success('Products added to cart', {
                            position: 'top-right'
                            });
                        else
                          Vue.$toast.success('Products deleted', {
                            position: 'top-right'
                            });
                          this.getProjects();
                          this.getJobs(this.selected_project);
                      }
                      
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }

                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
              });
            }
          }else{
               Vue.$toast.error('Select atleast one item', {
              position: 'top-right'
              });
          }
        },
        getProjects(){
        this.loading = true;
        let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        axios
            .post("/api/getfaxes",header)
            .then(response => {
                let data = response.data;

            if (data.status == true) {
                this.projects = data.data;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        getJobs(name){
            this.loading = true;
            this.selected_project = name;
            console.log(this.optionsDatatable);
            let req = {
              limit : this.item_per_page,
              offset : this.offset,
              name: name
              // sort : this.datatable_options.sortBy,
              // sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              // search : this.search
            }
            axios
            .post("/api/getjobs",req,this.header)
            .then(response => {
                let data = response.data;

            if (data.flag == 1) {
                console.log(JSON.parse(data.data));
                this.project_jobs = JSON.parse(data.data).jobs;
                console.log(this.project_jobs);
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        viewProof(id){
            this.loading = true;
            axios({
                    method: "POST",
                    url: "/api/viewProof",
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                    data: {'job_id': id}
                    })
                    
            .then(response => {
                let data = response.data;

            if (data) {
                this.loading = false;
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   var fileLink = document.createElement('a');
                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', 'Proof'+moment()+'.pdf');
                   document.body.appendChild(fileLink);

                   fileLink.click();
                // window.open(data.data, '_blank');
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        addToCart(id){
          this.dialog = true;
          this.selected_job = id;
        },
        deleteProfile(id){
            
            if(confirm("Are you sure, You want to delete?")){
              // this.loading = true;
              axios
              .post("/api/deletefax/"+id,this.selectedItems,this.header)
              .then(response => {
                  let data = response.data;

              if (data.flag == 1) {
                  this.loading = false;
                  this.getProjects();
                  Vue.$toast.success("Deleted successfully", {
                      position: 'top-right'
                      });
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });

            });
          }
        },
        addToCartAPI(){
          this.$refs.form.validate();
          this.dialog = true;
          axios
            .post("/api/addtocart",{'job_id': this.selected_job, 'initials': this.initials},this.header)
            .then(response => {
                let data = response.data;
              console.log(data);
            if (data.flag == 1) {
                this.loading = false;
                // if(data.data.description =='Success'){
                  Vue.$toast.success('Product added to cart', {
                    position: 'top-right'
                    });
                    this.dialog = false;
                    this.selected_job = '';
                    this.checkbox = '';
                    this.initials = '';
                    this.getJobs(this.selected_project);
                }
                
                else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
        }
    }
    
}
</script>

<style>

</style>