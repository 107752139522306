<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid>
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="8">
              <v-card class="center"  align=""
              style="margin-top:100px;"
            justify="center">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h1 class=" mb-5">Edit User</h1>
                    </v-col>
                    <v-col
                      cols="12"
                      style="margin-top:-35px;"
                      md="6"
                    >
                    <span style="color:#5D6167;">First Name<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        outlined
                        width="300px"
                        ref="name"
                        v-model="client.first_name"
                        />

                    </v-col>
                    <v-col
                    style="margin-top:-35px;"
                      cols="12"
                      md="6"
                    >
                    <span style="color:#5D6167;">Last Name<span class="required_span">*</span></span>
                     <v-text-field
                        id="name"
                        name="name"
                        outlined
                        width="300px"
                        ref="name"
                        v-model="client.last_name"
                        />

                    </v-col>
                   
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Email<span class="required_span">*</span></span>
                          <v-text-field
                          :disabled="client.is_email"
                          v-model="client.email"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Phone (Mobile)<span class="required_span">*</span></span>
                          <v-text-field
                          v-model="client.phone_m"
                          v-mask="'(###) ###-####'"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="12" md="12" lg="12" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Address<span class="required_span">*</span></span>
                          <vuetify-google-autocomplete
                                  id="map"
                                  v-model="address"
                                  placeholder="Enter a location"
                                  outlined
                                  v-on:placechanged="getAddressData"
                                  country="us"
                              ></vuetify-google-autocomplete>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">City</span>
                          <v-text-field
                          v-model="client.city"
                          outlined
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">State</span>
                          <v-select
                          :items="stateOptions"
                          item-text="name"
                          item-value="abbreviation"
                          placeholder="Select State"
                          v-model="client.state"
                          outlined
                    ></v-select>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <span style="color:#5D6167;">Zip Code</span>
                          <v-text-field
                          v-model="client.zip"
                          outlined
                    ></v-text-field>
                      </v-col>
                      
                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-card-actions class="justify-center">
                        <v-btn
                      dark
                    color="grey"
                    class="justify-center"
                    @click="$router.push({ name: 'CompanyUsers' })"
                  >Back</v-btn>
                  <v-btn
                    :dark="isDark"
                    :disabled="isDisable"
                    color="primary"
                    class="justify-center"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions class="justify-center ">
                  <v-btn
                    tile  dark
                    style="height: 49px;width:95px;"
                    color="primary"
                    class="mb-10"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
    </v-container>
    
     <!-- <vue-footer></vue-footer> -->
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import DateDropdown from 'vue-date-dropdown'
import moment from 'moment'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
export default {
components: {
      VueTelInput,DateDropdown,
      'RotateLoader': ClipLoader
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        dob:'',
        age:0,
        name:'',
        phone:'123456',
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        companyname : '',
        sector : '',
        no_of_employee : '',
        existOption: ["New","Existing"],
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        otp_email:'',
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            address: '',
        client : {
          first_name: "",
          last_name: "",
          is_exist: "",
          middle_name: '',
          email : '',
          is_email : false ,
          phone_h : '',
          phone_w : '',
          dob: '01-01-1950',
          ssn : '',
          phone_m : '',
          fax : '',
          address :'',
          address1: '',
          city : '',
          state : '',
          zip : '',
          country :'United States',
          memo : '',
          document : '',
          suffix:'',
          recaptcha: '',
          user_name: "",
          password: "",
          confirm_password: "",
          crc_id : ""

        },
        otp_phone_input:"",
        isDark : true,
        isDisable : false,
        rules: [
                value => !!value || 'Required.',
            ],
        otp_email_input:"",
        year : new Date().getFullYear(),
        user_phone:'',
        user_email:'',
        verifyLoad: false,
        verfiedFlag : false,
        verifyUser: '',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },
    watch: {
      dob (val) {
        this.age = val ?  moment().diff(this.dob, 'years') :0
        console.log(this.age);
        if(isNaN(this.age))
          this.age = 0;
      },
    },
    methods: {
      getAddressData: function (addressData, placeResultData, id) {
            this.client.address = addressData.name;
            this.client.city = addressData.locality;
            this.client.state = addressData.administrative_area_level_1;
            this.client.zip = addressData.postal_code
            console.log(this.client);
        },
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    verify(){
    let self = this;
    let flag = 1;
    if (!self.email && self.emailFlag ==1) {
    Vue.$toast.error('Email '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['email'].focus()
    flag = 0;
    }
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(self.email) == false && flag == 1 && self.emailFlag == 1) {
        Vue.$toast.error('Invalid Email format', {
        position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
    }
    if (!self.phone && flag == 1 && self.phoneFlag == 1) {
    Vue.$toast.error('Phone Number '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['phone'].focus()
    flag = 0;
    }

    if (flag == 1) {
    var email = this.email;
    axios({
        method: "POST",
         
        url: "/api/auth/send_otp",
        data: {
        flag : self.phoneFlag == 1 ? 'phone' : 'email',
        email: self.email,
        phone:  self.phone,
        code: self.country,
        logged_flag : 1
        }
    })
        .then(response => {
        let data = response.data;
        if (data.data.flag == 1) {
            if(self.phoneFlag == 1 ){
                self.otp_phone = data.data.otp;
                self.phoneFlag = 2;
            }
            else{
                self.otp_email = data.data.otp;
                self.emailFlag = 2;
            }
            Vue.$toast.success(data.message, {
                position: 'top-right'
            });

        } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
        })
        .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
      }
    },
    verifyOTP(){
        if(this.phoneFlag ==2){
            if(! this.otp_phone_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_phone == this.otp_phone_input){
                    axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'phone',
                            phone: this.phone,
                            code: this.country
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                this.getUserProfile()
                                this.phoneFlag = 3;
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });


                }else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_phone_input ="";
                    this.$refs['phone_otp'].focus()
                }
            }

        }else{
            if(! this.otp_email_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_email == this.otp_email_input){
                     axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'email',
                            email: this.email,
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                //this.$router.push("/home");
                                this.emailFlag = 3;
                                this.getUserProfile()
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });

                } else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_email_input ="";
                    this.$refs['email_otp'].focus()
                }
            }
        }
    },
    verifyCrc(){
            let self = this;
            let flag = 1;
            if (!this.client.crc_id) {
                Vue.$toast.error('Customer ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }else{
                self.verifyLoad = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/verify-crc",
                data:{crc_id : self.client.crc_id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.data.success == 'True'){
                        self.verifyUser = response.data.data.result;
                        self.verfiedFlag = true;
                    }else{
                        Vue.$toast.error('Invalid customer id', {
                            position: 'top-right'
                        });
                    }
                    self.verifyLoad = false
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    self.verifyLoad = flase;

                });
            }
        },
        autofill(){
            let self = this;
            self.client.first_name = self.verifyUser.firstname.toString()
            self.client.last_name = self.verifyUser.lastname.toString()
            self.client.email = self.verifyUser.email.toString()
            self.client.middle_name = self.verifyUser.middlename.toString()
            self.client.phone_m = self.verifyUser.phone_mobile.toString()
            self.client.phone_h = self.verifyUser.phone_home.toString()
            self.client.phone_w = self.verifyUser.phone_work.toString()
            self.client.ssn = self.verifyUser.ssno.toString()
            self.address = self.verifyUser.street_address.toString()
            if(self.verifyUser.state){
                self.stateOptions.forEach(element => {
                    if(self.verifyUser.state.toUpperCase()  == element.name)
                        self.client.state = element.abbreviation.toString()
                });
            }
            self.client.zip = self.verifyUser.zip.toString()
            self.client.city = self.verifyUser.city.toString()
            // console.log('suman',Array.isArray(self.verifyUser.birth_date));
            if(self.verifyUser.birth_date && !Array.isArray(self.verifyUser.birth_date)){
                self.dob = self.verifyUser.birth_date;
                
            }
            
        },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['password'].focus()
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success(this.$t('my_profile.profile_alert_password_change_successfull'), {
                position: 'top-right'
                });

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        let id  = this.$route.params.id;
      axios
        .get("/api/get-client/" + id,header)
        .then(response => {
            let data = response.data.data;

           if (flag == 1) {
            this.client = data;
            if(this.client.smart_credit_password)
                this.client.smart_credit_password = atob(this.client.smart_credit_password)
            this.dob = data.dob
            this.address = data.address_1
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      console.log(this.dob);
            let flag = 1;
          
            if (!this.client.first_name && flag == 1) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.client.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.client.email && this.client.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.client.email) == false && this.client.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.client.phone_m && this.client.phone_m == false && flag == 1) {
                Vue.$toast.error('Phone (Mobile) is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
          
            if (!this.client.address && this.client.address == false  && !this.client.city && this.client.city == false   && !this.client.state && this.client.state == false  && !this.client.zip && this.client.zip == false  &&  flag == 1) {
                Vue.$toast.error('Address is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            this.client.user_name = this.client.email;
            

        if (flag == 1) {
          this.client.dob = this.dob;
          this.isDark = false;
          this.isDisable = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-client/"+this.$route.params.id,
          data: self.client
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('User updated', {
                    position: 'top-right'
                  })
                  this.isDark = true;
                  this.isDisable = false;

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
              this.isDark = true;
              this.isDisable = false;
              this.client.dob = '';
              if(err.response.data){
                  let tmp = JSON.parse(err.response.data);
                if(tmp.email)
                  Vue.$toast.error(tmp.email[0], {
                  position: 'top-right'
                  });
                if(tmp.user_name)
                  Vue.$toast.error(tmp.user_name[0], {
                  position: 'top-right'
                  });  
              }else{
                Vue.$toast.error(err.response.data, {
                position: 'top-right'
                });      
              }
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getUserProfile();
    },

}
</script>

