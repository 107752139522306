<template>
<v-app id="inspire" style="background:#F9F9F9;">
<vue-header-admin v-if="!modalFlag"></vue-header-admin>
    <v-main>
    <v-container fluid  class="px-6 py-6 mb-10">
        <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Certified Letters({{totalCount}})</h5>
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                    <rotate-loader class="mt-3" :loading="loading" color="#1D75F4"></rotate-loader>
                    <!-- <v-btn  class="mb-10" width="200px" style="float: right;" color="primary" :to="{path:'/add-parking'}" >Add Parking Lot</v-btn> -->
                    </v-col>
                     <v-col cols="12"
                      md="6" >
                     <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        dark
                                        @click="dialogDoc = true, selected_id = 'all'"
                                    >
                                        Change Status
                                    </v-btn></v-col>
                    <v-col cols="12"
                      md="6" >
                        <v-spacer></v-spacer>
                            <v-text-field
                               v-model="search"
                                label="Search"
                                single-line
                                clearable
                                hide-details
                            >
                                <template v-slot:append>
                                
                                </template>
                                <template v-slot:append-outer>
                                    <v-btn
                                    color="#4CAF50"
                        class="
                         ont-weight-bolder
                          btn-default
                          mr-5
                          py-4
                          px-8
                          ms-auto
                          mt-sm-auto mt-4
                                        
                                      "
                                        small
                                        @click="getUsers"
                                    >
                                        Submit
                                    </v-btn>
                                </template>
                            </v-text-field>
                    </v-col>
                    <v-col cols="12"
                      md="12">
                      
                        <v-data-table
                            :headers="headers_jobs"
                            :options.sync="datatable_options"
                            :items="users"
                            loading-text="Loading... Please wait"
                            v-model="selectedItems"
                            show-select
                            :page.sync="page"
                            :items-per-page="itemsPerPage"
                            hide-default-footer
                            class="table"
                        >
                        <template v-slot:item.name="{ item }">
                            {{item.first_name}} {{item.last_name}}
                        </template>
                     
                        <template v-slot:item.email="{ item }">
                            {{item.email ? item.email : 'Not Available'}}
                        </template>
                         <template v-slot:item.phone="{ item }">
                            {{item.phone_m ? item.phone_m : "Not Avilable"}}
                        </template>
                        <template v-slot:item.date_of_birth="{ item }">
                            {{dateFormat(item.dob)}}
                        </template>
                         <template v-slot:item.company="{ item }">
                            {{item.name ? item.name  : 'Idealergy'}}
                        </template>
                        <template v-slot:item.address="{ item }">
                        <span ><span v-if="item.address_1">{{ item.address_1 }}</span>
                        </span>
                        </template>
                        <template v-slot:item.status_id="{ item }">
                           <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus(item.user_id,item.status)"
                            x-small
                            :color="item.status==1 ? 'red':'green'"
                          >
                          {{item.status==1 ? 'Deactivate':'Activate'}}
                          </v-btn>
                        </template>
                         <template v-slot:item.round_1_status="{ item }">
                          {{item.round_1_status ? item.round_1_status : '-'}}
                        </template>
                        <template v-slot:item.round_2_status="{ item }">
                          {{item.round_2_status ? item.round_2_status : '-'}}
                        </template>
                        <template v-slot:item.round_3_status="{ item }">
                          {{item.round_3_status ? item.round_3_status : '-'}}
                        </template>
                        <template v-slot:item.round_4_status="{ item }">
                          {{item.round_4_status ? item.round_4_status : '-'}}
                        </template>
                     
                        <template v-slot:item.change_status="{ item }">
                          <v-btn
                            class="mx-2"
                            dark
                            @click="dialogDoc = true, selected_id = item.id"
                            x-small
                            v-if="!item.round_1_key && !item.round_2_key && !item.round_3_key && !item.round_4_key"
                            :color="'green'"
                          >
                          Start
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.round_4_status == 'Successful'"
                            :color="'green'"
                          >
                          Finished
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            disabled

                            @click="dialogDoc = true"
                            x-small
                            v-if="item.round_1_key || item.round_2_key || item.round_3_key || item.round_4_key"
                            :color="'green'"
                          >
                          Started
                          </v-btn>
                        </template>
                        <template v-slot:item.process_1_key="{ item }">
                          <v-btn
                            class="mx-2"
                            
                            @click="dialogDoc = true, selected_id = item.id"
                            x-small
                            disabled
                            v-if="item.process_1 == 0"
                            :color="'green'"
                          >
                          View
                          </v-btn>
                          <v-btn
                            class="mx-2"
                            dark
                            @click="getProcessStatus(item.process_1_key)"
                            x-small
                            v-if="item.process_1 == 1"
                            :color="'blue'"
                          >
                          View
                          </v-btn>
                        </template>
                        
                        <template v-slot:item.id="{ item }">

                          <v-btn
                            class="mx-2"
                            fab
                            v-if="!item.round_1_key && !item.round_2_key && !item.round_3_key && !item.round_4_key"
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon dark>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <v-pagination
                            prev-icon="fa fa-angle-left"
                            next-icon="fa fa-angle-right"
                            class="pagination mb-5"
                            circle
                            color="#4CAF50"
                                v-model="page"
                                :length="pageCount"
                                @input="getUsers"
                            ></v-pagination>
                            </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
          v-model="dialogDoc"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Change Status
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
               <v-select
                :items="['In review','In the mail','Processed for delivery']"
                v-model="letter_status"
                label="Status"
              ></v-select>
              <span class="text-h5" >
                <v-btn
                            class="mx-2"
                            dark
                            @click="changeStatus()"
                            :color="'green'"
                          >
                          Save
                          </v-btn>
              </span>
            </v-card-text>
         
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogDoc = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
         <v-dialog
          v-model="process_status_dialog"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Transaction {{transaction.Key}}
            </v-card-title>
            <div  class="mt-5 mb-5">
             <v-card-text >
              <span class="text-h5" >
                <b>Status: </b>
              </span>
              <span class="text-h5" v-if="!transaction.ProcessingException" >
                {{transaction.Status}}
              </span>
              <span class="text-h5" v-else >
                {{transaction.Status}} ({{transaction.ProcessingException.Reason}})
              </span>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="process_status_dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-container>
    <vue-footer-admin></vue-footer-admin>
</v-main>

</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import {Circle8} from 'vue-loading-spinner'
import moment from 'moment';
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
export default {
    components : {
      'RotateLoader': ClipLoader
    },
    props : ['modalFlag','clientId'],
    data () {
      return {
        header : { headers : {
            Authorization:"Bearer " + localStorage.getItem('token')
            },
        },
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 3 || 'Min 3 characters',
          emailMatch: () => (`The email and password you entered don't match`),
        },
        transaction : [],
        loading:true,
        dialog:false,
        initials:'',
        valid:true,
        pageCount:1,
        letter_status : 'In review',
        pageSort: {},
        documentList:[],
        totalCount:0,
        page:1,
        itemsPerPage:10,
        selected_job:'',
        search : "",
        selectedItems: [],
        dialogDoc:false,
        datatable_options:{},
        offset: 0,
        selected_id : '',
        limit: 10,
        selected_project:'',
        checkbox: '',
        process_status_dialog : false,
        headers_jobs: [
          { text: 'Client Name', value: 'name',
            sortable: true },
          { text: 'Client Email', value: 'email',
            sortable: true },
          { text: 'Document', value: 'document',
            sortable: true },
          { text: 'Recipient', value: 'receiver',
            sortable: true },
            { text: 'Sender', value: 'sender',
            sortable: true },
            { text: 'Status', value: 'status',
            sortable: true },
            // { text: 'Status', value: 'status',
            // sortable: true },
            // { text: 'CRC ID', value: 'crc_id',
            // sortable: false },
          { text: 'Created at', value: 'created_at',
            sortable: true,width: '200' },
          { text: 'Updated at', value: 'updated_at',
            sortable: true,width: '200' },
            // { text: 'Change Status', value: 'change_status',
            // sortable: false,width: '150' }
            // { text: 'Process 1 Status', value: 'process_1_key',
            // sortable: false,width: '150' },
            

        ],
        projects: [
          
        ],
        users: []
      }
    },
    created () { 
        if(!localStorage.getItem('token'))
            this.$router.push('/login')
        this.getUsers();
    },
    methods:{
      dateFormat(date){
        return moment(date).format("MM/DD/YYYY")+', Age '+moment().diff(date, 'years');
      },
        formatDate(value) {
            return moment(value).format("DD MMM YYYY")
        },
        StartProcess1(flag){
          let id = this.selected_id;
          let multiple_flag = 0;
          if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to start process 1?");
            if (r == true) {
              this.loading = true;
              this.dialogDoc = false;
                    axios
                .post("/api/process1/"+id+"/"+flag,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Process started successfully', {
                            position: 'top-right'
                            });
                            this.loading = false;
                            this.getUsers();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                            this.loading = false;
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        openDocModal(id){
          
          this.dialogDoc = true;
          axios
            .get("/api/get-documents/"+id,this.header)
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.documentList = data.data;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
           });
        },
        editItem(id){
          this.$router.push('/add-parking/'+id)
        },
        deleteItem(id){
          
          let multiple_flag = 0
          if(id == 'all')
            multiple_flag = 1
          var r = confirm("Are you sure, You went to delete?");
          if(id == 'all' && this.selectedItems.length == 0){
            Vue.$toast.error("Select atleast one item to delete", {
              position: 'top-right'
              });
              r = false
          }
          if (r == true) {
            axios
              .post("/api/delete-request/"+id,{multiple_flag : multiple_flag, ids : this.selectedItems},this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUsers();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },
        changeStatus(){
            this.loading = true
             var r = confirm("Are you sure, You went to change status?");
            if (r == true) {
                    axios
                .post("/api/change-status-letters",{ids : this.selectedItems,status : this.letter_status},this.header)
                .then(response => {
                    let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Letters status changed successfully', {
                            position: 'top-right'
                            });
                            this.getUsers();
                            this.dialogDoc = false;
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                    .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                    })
                
            }else{
                return false;
            }
        },
        getUsers(){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            this.selected_project = name;
            let req = {
              client_id : this.clientId ? this.clientId : '',
              limit : this.limit,
              offset : this.offset,
              sort : this.datatable_options.sortBy,
              sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
              search : this.search
            }
            axios({
          method: "POST",
           
          url: "/api/get-certified-letters",
          data: req,
           header : { headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
              },
            }
        })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
                this.users = data.data;
                this.pageCount = data.total_page
                this.totalCount = data.count
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        getProcessStatus(id){
            this.loading = true;
            this.offset = (this.page - 1) * 10
            axios({
              method: "GET",
               
              url: "/api/get-process-status/"+id,
              header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
            .then(response => {
                
                let data = response.data;

            if (response.data) {
              console.log(data);
                this.transaction = data.data.value[0];
                console.log(this.transaction);
                this.process_status_dialog = true;
                this.loading = false;
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }

            })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
        },
        
        

    },
    watch: {
            datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getUsers()
                },
                deep: true,
            },
        },
    
}
</script>

