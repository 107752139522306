<template>
 <v-app id="inspire" >

    <div style=" justify-content: space-around" class="mt-3 mb-1">
        <vue-header></vue-header>
        <v-row align="center" justify="center" class="mt-10 mb-10">
        <v-col cols="12">
            <div style=" justify-content: space-around;display:flex;" >
            <rotate-loader :loading="isDisableUpload" color="#1D75F4"></rotate-loader>
            </div>
        </v-col>
        <v-col cols="11"  sm="10" md="10" lg="8" xs="10" style="margin-top:100px;">
             <v-stepper v-model="e1" style="margin-bottom:100px;margin-top-100px;" >
                    <v-stepper-header >
                    <v-stepper-step  :complete="e1 > 1" step="1" >Sign Agreement</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">Upload Documents</v-stepper-step>

                    </v-stepper-header>

                    <v-stepper-items>
                    <v-stepper-content step="1" style="margin-bottom:50px;margin-top:50px;">
                        <div v-if="disableSrep1">
                            <span style="color:#5D6167;">Refered by Company</span>
                            <v-select
                                        outlined
                                        item-text="name"
                                        placeholder="Select company"
                                        item-value="id"
                                        @input="changeCompany"
                                        v-model="selectedCompany"
                                        :items="companies"
                                        ></v-select>
                        </div>
                       <div v-if="agreemen_flag == 1">
                        <v-btn
                        color="primary"
                        :dark="isDarkDownload"
                        :disabled="isDisableDownload"
                        width="50"
                        height="50"
                        class="mt-8"
                        @click="downloadDoc()"
                        >
                        <v-icon>mdi-draw</v-icon> 
                        </v-btn>
                        <p class="mt-10">Please click the above button to sign agreement.</p>
                        <br>
                       </div>
                       <div v-if="agreemen_flag == 2">
                           <v-chip
                                class="ma-2"
                                color="green"
                                text-color="white"
                                >
                                <v-avatar left>
                                    <v-icon style="font-size:25px;">mdi-checkbox-marked-circle</v-icon>
                                </v-avatar>
                                Agreement signed
                                </v-chip>

                       </div>
                        <v-btn
                        class="mt-5"
                        color="#00aeef"
                        :dark="step1Dark"
                        :disabled="disableSrep1"
                        @click="submitAgreement"
                        >
                        Continue
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">

                    <v-layout>
                    <v-flex sm12 class="pa-2 mt-10 mb-10" >
                        <v-row>

                            <v-col cols="12" md="6">
                                <v-file-input
                                    show-size
                                    v-model="uploadedDocumentId"
                                    outlined
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon="mdi-file-image-outline"
                                    label="Upload ID (Max size 5MB)"
                                ></v-file-input>
                                <v-file-input
                                    show-size
                                    outlined
                                    v-model="uploadedDocumentBill"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon="mdi-file-image-outline"
                                    label="Upload Bill (Max size 5MB)"
                                ></v-file-input>
                                <v-file-input
                                    show-size
                                    outlined
                                    v-if="police_flag"
                                    v-model="uploadedDocumentPolice"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf "
                                    prepend-icon="mdi-file-image-outline"
                                    label="Police Report (Max size 5MB)"
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-flex>
                    </v-layout>
                    
                        <v-btn   @click="e1 = 1"  color="warning" >Back</v-btn>
                        <v-btn
                        color="#00aeef"
                        :dark="isDarkUpload"
                        :disabled="isDisableUpload"
                        @click="uploadFiles()"
                        >
                        Submit
                        </v-btn>
                        
                    </v-stepper-content>

                    <v-stepper-content step="3" style="margin-bottom:150px;margin-top:150px;">
                    <v-alert
                    :value="true"
                    type="success"
                    >
                    Documents uploaded successfully. 
                    </v-alert>
                        <v-btn
                        color="primary"
                        @click="finishProcess"
                        >
                        Finish
                        </v-btn>
                    </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
        </v-col>
        </v-row>
        <!-- <v-row class="pl-5 pr-5 " style="background:#3F3F3F;">
        <v-col cols="12" align="center" justify="center" >
                <p style="font-size: 18px;
            color: white;
            margin-top:10px;
            line-height: 150%;" ><v-icon style="font-size: 18px;color: white;margin-top:-5px">mdi-lock</v-icon> <span>Secure Area</span> | This website is protected by 256-bit SSL security.<br>
            @ 2021 iDealergy. All rights reserved. Patent Pending.
          </p>
        </v-col>
        </v-row> -->
    </div>
    <vue-footer></vue-footer>
    <v-dialog
        fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="dialogAssign"
    >

     <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogAssign = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Sign Agreement</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <div>
               <iframe
                                id="JotFormIFrame-90142788412154"
                                title="Trainee Registration Form"
                                onload="window.parent.scrollTo(0,0)"
                                allowtransparency="true"
                                allowfullscreen="true"
                                allow="geolocation; microphone; camera"
                                :src="src"
                                frameborder="0"
                                style="width: 1px;
                                min-width: 100%;
                                height:1000px;
                                border:none;"
                                scrolling="yes"
                                ></iframe>
        </div>
      </v-card>
    </v-dialog>
 </v-app>
</template>

<script>
import DateDropdown from 'vue-date-dropdown'
import VueRecaptcha from 'vue-recaptcha'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue'
import moment from 'moment'
export default {
    components: {
        DateDropdown,
        'vue-recaptcha' : VueRecaptcha,
        'RotateLoader': ClipLoader
    },
    data()  {
        return {
            process_dropdown: '',
            dialogAssign: false,
            selectedCompany: '',
            selectedCompanyName:'',
            disableSrep1: true,
            agreemen_flag: 0,
            src:'',
            step1Dark: false,
            partner : {
                first_name : "",
                last_name : '',
                middle_name: '',
                email : '',
                is_email : false ,
                phone_h : '',
                phone_w : '',
                dob: '01-01-1950',
                ssn : '',
                phone_m : '',
                fax : '',
                address :'',
                city : '',
                state : '',
                zip : '',
                country :'United States',
                memo : '',
                document : '',
                suffix:'',
                recaptcha: '',
                user_name: "",
                password: "",
                confirm_password: "",
                crc_id : ""

            },
            isDisable :false,
            loading: true,
            isDark: true,
            isDisableDownload: false,
            police_flag : false,
            isDarkDownload: true,
            e1: 1,
            isDisableUpload: false,
            isDarkUpload: true,
            switch1: false,
            screens: [
            'Sağlam',
            'Kırık',
            'Çizik'
            ],
            cases: [
            'Sağlam',
            'Kırık',
            'Çizik'
            ],
            checkbox: [],
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            year : new Date().getFullYear(),
            uploadedDocumentId: [],
            uploadedDocumentBill:[],
            uploadedDocumentAgreement:[],
            uploadedDocumentPolice:[],
            companies:[],
            user:[],
            form: {
            // Some form values...
                recaptcha: '',
            },
            sitekey: process.env.MIX_RECAPTCHA_SITE_KEY,
            itemOptions: ['609','Inquires','609 and Inquires','Bankrupcy','Identity Thieft'],
        }
    },
    created () {
        if(localStorage.getItem("agreement_flag") == 1)
                this.$router.push("/dashboard");
        this.getAllCompanies();
        this.getUser();
        if(this.$route.params.id){
            this.disableSrep1 = false
            this.step1Dark = true
            this.agreemen_flag = 2
        
        }
    },
    methods : {
        changeCompany(){
            this.agreemen_flag = 1;
            this.companies.map(val =>{
                if(val.id == this.selectedCompany) {this.selectedCompanyName = val}
            })
            var fullUrl = window.location.host + this.$route.path + "/"+this.selectedCompany
                console.log(fullUrl);
            //this.src = "https://form.jotform.com/212459051338050?companyName15="+this.selectedCompanyName+"&nameOf[first]="+this.user.first_name+"&nameOf[last]="+this.user.last_name+"&CompanyAddress[addr_line1]="+this.user.address_1+"&CompanyAddress[city]="+this.user.city+"&CompanyAddress[state]="+this.user.state+"&CompanyAddress[postal]="+this.user.zip+"&CompanyAddress[country]=United%20States&redirect_url="+fullUrl;
            this.src = "https://form.jotform.com/213558812994064?name[first]="+this.user.first_name+"&name[last]="+this.user.last_name+"&email="+this.user.email+"&company_address[addr_line1]="+this.selectedCompanyName.address_1+"&company_address[city]="+this.selectedCompanyName.city+"&company_address[state]="+this.selectedCompanyName.state+"&company_address[postal]="+this.selectedCompanyName.zip+"&company_name="+this.selectedCompanyName.name+"&question_1="+this.selectedCompanyName.question_1+"&question_2="+this.selectedCompanyName.question_2+"&question_3="+this.selectedCompanyName.question_3+"&question_5="+this.selectedCompanyName.question_5+"&question_4="+this.selectedCompanyName.question_4+"&redirect_url="+fullUrl
        },
        getAllCompanies(){
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios
            .get("/api/get-all-company", {headers: headers})
            .then(response => {
                this.companies = response.data.data;
                this.companies.push({name: 'None',id:'None'})
            })
        },
        logout() {
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
        axios
            .get("/api/logout", {headers: headers})
            .then(response => {
                localStorage.removeItem("token")
                localStorage.removeItem("user_id");
                localStorage.removeItem("email");
                localStorage.removeItem("name");
                localStorage.removeItem("is_admin");
                this.$router.push("/login");
                window.location.reload();
            })
            .catch(error => {
                localStorage.removeItem("token")
                localStorage.removeItem("user_id");
                localStorage.removeItem("email");
                localStorage.removeItem("name");
                this.$router.push("/admin");
                location.reload();
            });
        },
        onCaptchaVerified: function (recaptchaToken) {
        this.partner.recaptcha = recaptchaToken
        this.validateCaptcha = true
        },
        onCaptchaExpired: function () {
        this.$refs.recaptcha.reset();
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.partner.city = addressData.name;
            this.partner.state = addressData.administrative_area_level_1;
            this.partner.zip = addressData.postal_code
        },
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        finishProcess(){
            localStorage.setItem('agreement_flag',1)
            this.$router.push('/my-request');
        },
        uploadFiles(){
            let self = this;
            let flag = 1;
            if(self.uploadedDocumentId.length == 0){
                Vue.$toast.error('Attach id file to upload', {
                position: 'top-right'
                });
                flag = 0;
            }else if(self.uploadedDocumentBill.length == 0){
                Vue.$toast.error('Attach bill file to upload', {
                position: 'top-right'
                });
                flag = 0;
            }else if(self.uploadedDocumentPolice.length == 0 && self.police_flag){
                Vue.$toast.error('Attach police report file to upload', {
                position: 'top-right'
                });
                flag = 0;
            }
            else{
                this.isDisableUpload = true;
                this.isDarkUpload = false;
                const data = new FormData();
                data.append('file_agreement', self.uploadedDocumentAgreement);
                data.append('file_id', self.uploadedDocumentId);
                data.append('file_bill', self.uploadedDocumentBill);
                data.append('selected_process', self.process_dropdown);
                data.append('file_police',self.uploadedDocumentPolice);
                axios({
                method: "POST",
                url: "/api/upload-files",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: data
                })
                .then(response => {
                console.log(response);
                this.isDisableUpload = false;
                this.isDarkUpload = true;
                self.e1 = 3;
                
                })
                .catch(err => {
                    Vue.$toast.error("Invalid file to upload", {
                    position: 'top-right'
                    });
                    this.isDisableUpload = false;
                    this.isDarkUpload = true;
                });
            }
        },
        processDropdownChange(){
            if (this.process_dropdown == 'Identity Thieft') {
                this.police_flag = true;
            }else{
                this.police_flag = false;
            }
        },
        submitAgreement(){
            axios({
                method: "POST",
                url: "/api/submit-agreement",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                data: {
                    company_id: this.$route.params.id
                }
                })
                .then(response => {
                console.log(response); 
                this.finishProcess();               
                })
                .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                });
        },
        getUser(){
            let self = this;
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios.get('/api/user', headers ).then(function (result) {
                self.user = result.data.user
            })
        },
        downloadDoc(){
            let self = this;
            // this.isDisableDownload = true;
            // this.isDarkDownload = false;
            this.dialogAssign = true;
                // axios({
                //     method: "POST",
                //     url: "/api/generate-pdf",
                //     responseType: 'blob',
                //     headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                //     data: {}
                //     })
                //     .then(response => {
                //     console.log(response);
                //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                //    var fileLink = document.createElement('a');

                //    fileLink.href = fileURL;
                //    fileLink.setAttribute('download', 'Agrement'+moment()+'.pdf');
                //    document.body.appendChild(fileLink);

                //    fileLink.click();
                    
                //     self.isDisableDownload = false;
                //     self.isDarkDownload = true;
                //     })
                //     .catch(err => {
                //         Vue.$toast.error(err, {
                //         position: 'top-right'
                //         });
                //     });
        },
        checkForm(){
            let self = this;
            let flag = 1;
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.ssn && this.partner.ssn == false && flag == 1) {
                Vue.$toast.error('SSN is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.phone_m && this.partner.phone_m == false && flag == 1) {
                Vue.$toast.error('Phone (Mobile) is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            
            if (!this.partner.address && this.partner.address == false  && !this.partner.city && this.partner.city == false   && !this.partner.state && this.partner.state == false  && !this.partner.zip && this.partner.zip == false  &&  flag == 1) {
                Vue.$toast.error('Address is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.crc_id && this.partner.crc_id == false && flag == 1) {
                Vue.$toast.error('CRC ID is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.user_name && this.partner.user_name == false && flag == 1) {
                Vue.$toast.error('User name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.password && this.partner.password == false && flag == 1) {
                Vue.$toast.error('Password is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.confirm_password && this.partner.confirm_password == false && flag == 1) {
                Vue.$toast.error('Confirm password is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (this.partner.password != this.partner.confirm_password && flag == 1) {
                Vue.$toast.error('Confirm password did not match with password', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (flag == 1) {
                
                let old_date = this.partner.dob.split(".")
                this.partner.dob = old_date[1] + '/' + old_date[0] + '/' + old_date[2];
                this.isDark = false;
                this.isDisable = true;
                axios({
                method: "POST",
                 
                url: "/api/auth/register",
                data:self.partner,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.success != "False"){
                        self.$router.push('/thanks');
                    }else{
                        this.isDark = true;
                        this.isDisable = false;
                        Vue.$toast.error(response.data.result.errors.error_message, {
                            position: 'top-right'
                            }); 
                    }
                })
                .catch(err => {
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                    if(data.email){
                        Vue.$toast.error(data.email[0], {
                        position: 'top-right'
                        });
                    }else if(data.password){
                        Vue.$toast.error(data.password[0], {
                        position: 'top-right'
                        });
                    }
                    else if(data.user_name){
                        Vue.$toast.error(data.user_name[0], {
                        position: 'top-right'
                        });
                    }
                    else{
                        Vue.$toast.error(data, {
                        position: 'top-right'
                        });
                    }
                    self.onCaptchaExpired()

                });

            }
        }
    }
}
</script>

<style>
/* .image-logo2{
position: absolute;
margin: -78px -5px -4px 170px;
} */
.date-dropdown-container{
    height: 85px;
}
.date-dropdown-select{
    border: 1px solid #9E9E9E!important;
    padding: 15px!important;
}
.required_span{
    color:red;
}
</style>