<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container fluid class="px-6 py-6">
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
              <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Add New User</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="First Name"
                        width="300px"
                        ref="name"
                        v-model="company.first_name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="Last Name"
                        width="300px"
                        ref="name"
                        v-model="company.last_name"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      style="margin-top:-35px;" 
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="Email"
                        width="300px"
                        ref="name"
                        v-model="company.contact_email"
                        />

                    </v-col>
                    <v-col
                      cols="12"
                      style="margin-top:-35px;" 
                      md="6"
                    >
                     <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="Phone"
                        v-mask="'(###) ###-####'"
                        width="300px"
                        ref="name"
                        v-model="company.contact_phone"
                        />

                    </v-col>
                     
                    <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <vuetify-google-autocomplete
                                  id="map"
                                  label="Address"
                                  v-model="company.address"
                                  placeholder="Enter a location"
                                  class="font-size-input input-style"
                                  v-on:placechanged="getAddressData"
                                  country="us"
                              ></vuetify-google-autocomplete>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <v-text-field
                          v-model="company.city"
                          label="City"
                          class="font-size-input input-style"
                    ></v-text-field>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <v-select
                          label="State"
                          :items="stateOptions"
                          item-text="name"
                          item-value="abbreviation"
                          placeholder="Select State"
                          v-model="company.state"
                          class="font-size-input input-style"
                    ></v-select>
                      </v-col>
                      <v-col cols="12"  sm="6" md="6" lg="6" style="margin-top:-35px;" >
                          <v-text-field
                          v-model="company.zip"
                          class="font-size-input input-style"
                          label="Zip"
                    ></v-text-field>
                      </v-col>
                      
                     <v-col
                    cols="12"
                    md="12"
                    >
                    
                      <h5 class="text-h5 font-weight-bold text-typo">User login credentials</h5>
                      <v-divider class="mt-5"></v-divider>
                    </v-col>
                    
                    <v-col
                    cols="12"
                    md="6"
                    >
                    <v-text-field
                        id="password"
                        label="Password"
                        class="font-size-input input-style"
                        name="password"
                        ref="password"
                        v-model="company.password"
                        :type="'password'"
                    />

                    </v-col>
                    <v-col
                    cols="12"
                    md="6"
                    >
                        <v-text-field
                        id="confpassword"
                        label="Confirm Password"
                        name="confpassword"
                        class="font-size-input input-style"
                        ref="confpassword"
                        v-model="company.password_confirmation"
                        :type="'password'"

                    >
                    </v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    >
       
                 <v-card-actions class="justify-center ">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="#4CAF50"
                        large
                        :disabled="!validGeneral"
                        @click="saveProfile"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                        "
                        
                      >
                        Save
                      </v-btn>
                      </v-col>
                    </v-row>
                </v-card-actions>
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions class="justify-center ">
                  <v-btn
                    tile  dark
                    style="height: 49px;width:95px;"
                    color="primary"
                    class="mb-10"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
    </v-container>
    
     <!-- <vue-footer></vue-footer> -->
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
export default {
components: {
      VueTelInput,
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        validGeneral : true,
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        role : '',
        companyname : '',
        sector : '',
        no_of_employee : '',
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        country :'',
        otp_email:'',
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        company : {
          first_name: "",
          last_name : "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: "",
          address: '',
          city: '',
          state: '',
          zip:'',
          question_1 : '',
          question_2 : '',
          question_3 : '',
          question_4 : '',
          question_5 : ''


        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    verify(){
    let self = this;
    let flag = 1;
    if (!self.email && self.emailFlag ==1) {
    Vue.$toast.error('Email '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['email'].focus()
    flag = 0;
    }
    let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (reg.test(self.email) == false && flag == 1 && self.emailFlag == 1) {
        Vue.$toast.error('Invalid Email format', {
        position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
    }
    if (!self.phone && flag == 1 && self.phoneFlag == 1) {
    Vue.$toast.error('Phone Number '+this.$t('questionnaire.questionnaire_alert_required'), {
        position: 'top-right'
    });
    this.$refs['phone'].focus()
    flag = 0;
    }

    if (flag == 1) {
    var email = this.email;
    axios({
        method: "POST",
         
        url: "/api/auth/send_otp",
        data: {
        flag : self.phoneFlag == 1 ? 'phone' : 'email',
        email: self.email,
        phone:  self.phone,
        code: self.country,
        logged_flag : 1
        }
    })
        .then(response => {
        let data = response.data;
        if (data.data.flag == 1) {
            if(self.phoneFlag == 1 ){
                self.otp_phone = data.data.otp;
                self.phoneFlag = 2;
            }
            else{
                self.otp_email = data.data.otp;
                self.emailFlag = 2;
            }
            Vue.$toast.success(data.message, {
                position: 'top-right'
            });

        } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
        })
        .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
        });
      }
    },
    verifyOTP(){
        if(this.phoneFlag ==2){
            if(! this.otp_phone_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_phone == this.otp_phone_input){
                    axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'phone',
                            phone: this.phone,
                            code: this.country
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                this.getUserProfile()
                                this.phoneFlag = 3;
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });


                }else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_phone_input ="";
                    this.$refs['phone_otp'].focus()
                }
            }

        }else{
            if(! this.otp_email_input){
                Vue.$toast.error('OTP '+this.$t('questionnaire.questionnaire_alert_required'), {
                    position: 'top-right'
                });
            }else{
                if(this.otp_email == this.otp_email_input){
                     axios({
                        method: "POST",
                         
                        url: "/api/updateprofile",
                        data: {
                            flag: 'email',
                            email: this.email,
                        }
                        })
                        .then(response => {
                            let data = response.data;
                            if (data.data == 1) {
                                Vue.$toast.success(data.message, {
                                    position: 'top-right'
                                })
                                //this.$router.push("/home");
                                this.emailFlag = 3;
                                this.getUserProfile()
                            } else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
                        })
                        .catch(err => {
                            Vue.$toast.error(err, {
                            position: 'top-right'
                            });
                        });

                } else{
                    Vue.$toast.error('Invalid OTP', {
                        position: 'top-right'
                    });
                    this.otp_email_input ="";
                    this.$refs['email_otp'].focus()
                }
            }
        }
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['password'].focus()
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password '+this.$t('questionnaire.questionnaire_alert_required'), {
                position: 'top-right'
            });
            this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success(this.$t('my_profile.profile_alert_password_change_successfull'), {
                position: 'top-right'
                });

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.fname = data.user.first_name;
              this.lname = data.user.last_name;
              this.user_email =  data.user.email;
              this.user_phone = data.user.mobile;
              this.name = this.fname+' '+this.lname
              this.status = data.user.status;
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.first_name) {
            Vue.$toast.error('Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_email && flag == 1) {
            Vue.$toast.error('Contact email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_phone && flag == 1) {
            Vue.$toast.error('Contact phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
     
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.company.contact_email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        this.company.email = this.company.contact_email;
        if (!this.company.password && flag == 1) {
            Vue.$toast.error('Password is required', {
                position: 'top-right'
            });
            // this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (!this.company.password_confirmation && flag == 1) {
            Vue.$toast.error('Confirm Password is required', {
                position: 'top-right'
            });
            // this.$refs['confpassword'].focus()
            flag = 0;
          }
          if (this.company.password_confirmation != this.company.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            // self.$refs['conf_password'].focus()
            flag = 0;
        }

        if (flag == 1) {
          self.company.address_1 = self.company.address;
          self.company.company_id = localStorage.getItem('company_id');
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-company-user",
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('User added', {
                    position: 'top-right'
                  })
                  this.$router.push('company-users')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
    //   this.getUserProfile();
    },

}
</script>

