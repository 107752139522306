const Utility = {
    hasRole: function (role) {
        var admin = currentUserRole.find(el => el.name === role);
        return admin ? true : false;
    },
    parseError: function (message, detailError) {
        if (!detailError)
            return message;

        let errors = Object.values(detailError);
        errors = errors.flat();
        var str = "";
        for (var i = 0; i < errors.length; i++) {
            str += errors[i] + "<br/>";
        }
        return message + "<br/>" + str;
    },
    findAndDeleteByid: function (data, field, id) {
        return $.grep(data, function (e) {
            return e[field] != id;
        });
    },
};
export default Utility
