<template>
  <v-app class="bg-white">
    Loading...
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      showPassword : false,
      dialog: false,
      pageFlag: 'init',
      forgot_flag : false,
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      company : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL
    };
  },
  beforeCreate: function () {
    // if (localStorage.getItem('token')) {
    //     if(localStorage.getItem('is_admin') && localStorage.getItem('is_admin') == 1)
    //         this.$router.push('/admin/dashboard')
    //     else
    //         this.$router.push('/dashboard')
    // }
},
created: function () {
  this.url = this.$route.params.id ? this.$route.params.id : '';
  if(this.url)
      this.getCompanyInfo(this.url)
  else
      this.loadingFlag = true;
},
methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},
getCompanyInfo(url){
  axios({
          method: "GET",
           
          url: "/api/redirect-qr/"+url
        
        })
          .then(response => {
            this.company = response.data.link;
            location.replace(this.company)
            
          })

},
forgotPass(){
  if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password-admin",
          data: {
            email: this.email,
            quantum : 1,
            frontend_url : this.url,
            company : this.company,
            company_flag : this.company ? true : false
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Please check your email for change password', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.forgot_flag = false;
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
        }
},
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
// forgotPass(){
//     this.buttonFlag =true;
//     this.isDark = false;
//     axios({
//           method: "POST",
           
//           url: "/api/auth/forgot-password",
//           data: {
//             email: this.email,
//           }
//         })
//           .then(response => {
//                 this.sendFlag = 1;
//                 Vue.$toast.success('Forgot Password Mail sent', {
//                 position: 'top-right'
//                 });
//                 this.buttonFlag =false;
//                 this.isDark = true;
//                 this.pageFlag = 'login';
//           })
//           .catch(err => {
//             Vue.$toast.error(err, {
//             position: 'top-right'
//             });
//           })
// },
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
/**
 * checkForm
 * login the user with valid email/pass
 */
 checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
           
          url: "/api/auth/login-admin",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("first_name", result.first_name);
              localStorage.setItem("email", result.email);
              localStorage.setItem("phone", result.phone_m);
              localStorage.setItem("is_admin", result.is_admin);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              localStorage.setItem("role", JSON.stringify(result.roles[0]));
              localStorage.setItem("company_name", result.company_name);
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+result.logo);
              localStorage.setItem("company_id", result.company_id);
              if(result.roles[0].name !='super_admin')
                localStorage.setItem("agreement_flag", result.agreement);
              if(result.roles[0].name =='super_admin')
                this.$router.push("/admin/qr-codes");
              else{
              if(result.agreement == 0)
                this.$router.push("/admin/agreement");
              else
                this.$router.push("/admin/qr-codes");
              }
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },
  }
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login-new .v-icon{
    color:  #3498db !important;
}
.input-login-new .v-label{
  color:  #3498db !important;
}
.input-login-new #input-6{
  color:  #3498db !important;
}
.input-login-new #input-10{
  color:  #3498db !important;
}
.new-login-left {
  height: 100%;
  display: table;
  position: relative;
}
.new-login-left-box {
  display: table-cell;
  vertical-align: middle;
  height: 770px;
}
.new-login-left-form {
  max-width: 550px;
  width: 90%;
  margin: 30px auto 0;
  border: 1px solid #dedede;
  box-sizing: border-box;
}
.text-label{
  font-family: Arial,Helvetica,sans-serif!important;color: rgb(123, 128, 154) !important;font-weight: 400;
}
.default-color-code{
  color:  #3498db !important;
}
.score-header-1{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 800;
  font-family: "Poppins", Sans-serif;
}
.score-header-2{
  color: rgb(255, 255, 255);
  line-height: 1.2;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Poppins", Sans-serif;
}
.e-hosted-video video {
  max-width: 100%;
width: 100%;
margin: 0;
line-height: 1;
border: none;
}
</style>