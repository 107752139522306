<template>
<v-app id="inspire">

<vue-header-admin></vue-header-admin>
<v-main>
    <v-container >
        <v-row
            align="center"
            style="margin-bottom: 80px!important;"
            justify="center"
          >
            <v-col cols="12" md="12">
             <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                <v-card-text style="margin-bottom: -30px;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                    <h5 class="text-h4 font-weight-bold text-typo">Edit Letter</h5>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-select
                      :items="categories"
                      label="Category *"
                      item-text="category"
                      item-value="id"
                      v-model="letter.category"
                    ></v-select>
                    <p style="color:#0075cc !important;cursor:pointer;padding-top:-10px;" @click="letter_category_dialog = true,categoryEditId= ''"><b>Manage template category</b></p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-select
                      :items="['Active','Inactive']"
                      label="Status *"
                      v-model="letter.status"
                    ></v-select>

                    </v-col>
                    <v-col
                      cols="12"
                      v-if="role.name =='super_admin'"
                      md="12"
                    >

                    <v-select
                      label="Share with"
                        v-model="letter.display_to"
                        :items="['All','Specific Company']"
                        inset
                      ></v-select>
                      <v-select
                      v-if="letter.display_to == 'Specific Company'"
                        v-model="letter.list_account"
                        :items="companies"
                        chips
                        item-text="name"
                        item-value="id"
                        label="Select Company"
                        multiple
                        solo
                      >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    
                    </v-select>
                      </v-col>
                     <v-col
                      cols="12"
                      md="9"
                    >
                    <v-text-field
                        label="Letter title *"
                        v-model="letter.title"
                      ></v-text-field>
                     </v-col>
                      <v-col
                      cols="12"
                      md="3"
                    >
                      <v-btn
                      outlined
                      style="float: right;"
                        height="55px"
                        color="success"
                        class="justify-center"
                        @click="showPlaceholder = !showPlaceholder"
                      >View Placeholders</v-btn>
                    </v-col>
                      <div class="col-12" v-if="showPlaceholder">
                        <div class="content-box m-b-0" style="border: solid 1px #dddbda;
padding: 20px !important;">
                            <div class="row">
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                    {company_logo} - <span class="font-400">Company logo</span><br>
                                        {client_suffix} - <span class="font-400">Suffix of client</span><br>
                                        {client_first_name} - <span class="font-400">First name of client</span><br>
                                        {client_middle_name} - <span class="font-400">Middle name of client</span><br>
                                        {client_last_name} - <span class="font-400">Last name of client</span><br>
                                        {client_address} - <span class="font-400">Address of client</span><br>
                                        {client_previous_address} - <span class="font-400">Previous address of client</span><br>
                                        {bdate} - <span class="font-400">Birth date of client</span>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                    {ss_number} - <span class="font-400">SSN of client</span><br>
                                        {t_no} - <span class="font-400">Telephone number of client</span><br>
                                        {curr_date} - <span class="font-400">Current date</span><br>
                                        {client_signature} - <span class="font-400">Client's signature</span><br>
                                        {bureau_name} - <span class="font-400">Credit bureau name</span><br>
                                        {bureau_address} - <span class="font-400">Credit bureau name and address</span><br>
                                        {account_number} - <span class="font-400">Account number</span><br>
                                        {dispute_item_and_explanation} - <span class="font-400">Dispute items and explanation</span>
                                    </p>
                                </div>
                                <div class="col-4">
                                    <p class="sans-p font-15 m-b-0">
                                        {creditor_name} - <span class="font-400">Creditor/Furnisher name</span><br>
                                        {creditor_address} - <span class="font-400">Creditor/Furnisher address</span><br>
                                        {creditor_phone} - <span class="font-400">Creditor/Furnisher phone number</span><br>
                                        {creditor_city} - <span class="font-400">Creditor/Furnisher city</span><br>
                                        {creditor_state} - <span class="font-400">Creditor/Furnisher state</span><br>
                                        {creditor_zip} - <span class="font-400">Creditor/Furnisher zip</span><br>
                                        {report_number} - <span class="font-400">Report number</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                     <v-col
                      cols="12"
                      md="12"
                    >
                    
                    <html-editor height=500 v-model="letter.body"></html-editor>
                    
                    </v-col>

                    <v-col
                    cols="12"
                    md="12"
                    >
                    <v-card-actions class="justify-center ">
                  <v-row>
                    <v-col>
                      <v-btn
                        color="#4CAF50"
                        large
                        @click="saveLetter"
                        :disabled="letter.company_id == '1' && role.name != 'super_admin'"
                        class="
                        float-right
                          font-weight-bolder
                          btn-default
                          mb-5
                          px-8
                          ms-auto
                        "
                        
                      >
                        Save
                      </v-btn>
                      </v-col>
                    </v-row>
                  <!-- <v-btn
                    tile  dark
                    style="height: 49px;width:200px;"
                    color="primary"
                    class="mb-10"
                    @click="saveSettings"
                  >Save</v-btn> -->
                </v-card-actions>
                    
                    </v-col>

                    
                  </v-row>
                </v-card-text>
                <!-- <v-card-actions class="justify-center ">
                  <v-btn
                    tile  dark
                    style="height: 49px;width:95px;"
                    color="primary"
                    class="mb-10"
                    @click="saveProfile"
                  >Save</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
    </v-container>
    <v-dialog
          v-model="letter_category_dialog"
          width="700"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Manage Template Category
            </v-card-title>
            <div  class="mt-5">
              <v-card-text >
               <v-btn
                        color="blue"
                        dark
                        v-if="!addCategoryFlag"
                        @click="addCategoryFlag = true"
                      >Add new category</v-btn>
                       <v-text-field
                       v-if="addCategoryFlag"
                        label="Category Name *"
                        class="mt-5"
                        v-model="category_name"
                      ></v-text-field>
                       <v-btn
                        v-if="addCategoryFlag"
                        class="mr-2"
                        color="green"
                        dark
                        @click="saveCategory"
                      >Save</v-btn>
                       <v-btn
                       v-if="addCategoryFlag"
                      outlined
                        color="red"
                        @click="addCategoryFlag = false"
                      >Cancel</v-btn>
              </v-card-text>
            </div>
            <div  class=" mb-5">
             
             <v-card-text >
               
              <v-simple-table>
                  <template v-slot:default>
                    <thead style="background:#e0e0e0">
                      <tr>
                        <th class="text-left">
                          Categories
                        </th>
                        <th class="text-left">
                          
                        </th>
                        <th class="text-left">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in categories"
                        :key="item.category"
                      >
                        <td>{{ item.category }}</td>
                        <td>

                             <v-btn
                            class="mx-2"
                            text
                            dark
                           @click="categoryEditId = item.id,addCategoryFlag = true, category_name = item.category"
                            x-small
                            color="blue"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              edit
                            </v-icon>
                          </v-btn>
                        </td>
                        <td class="text-right"> 
                          <v-btn
                            class="mx-2"
                            text
                            dark
                            @click="deleteItem(item.id)"
                            x-small
                            color="red"
                          >
                            <v-icon size="20" class="material-icons-round opacity-10" dark>
                              delete
                            </v-icon>
                          </v-btn></td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card-text>
            </div>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="letter_category_dialog = false"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
     <!-- <vue-footer></vue-footer> -->
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
export default {
components: {
      VueTelInput,
      HtmlEditor
  },
  data () {

      return {
        fname : '',
        lname : '',
        email : '',
        name:'',
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        letter_category_dialog : false,
        role : '',
        companyname : '',
        sector : '',
        showPlaceholder: 0,
        role : JSON.parse(localStorage.getItem('role')),
        addCategoryFlag: false,
        no_of_employee : '',
        category_name : '',
        showPassword : false,
        phoneFlag:0,
        emailFlag:0,
        companies: [],
        searchTerm : '',
        companiesCopy : [],
        categoryEditId:"",
        country :'',
        otp_email:'',
        categories: [],
        otp_phone:'',
        stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
        letter : {
          category: "",
          status : "",
          title: "",
          body : "",
          display_to : 'All',
          list_account : []

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
    addPhone(){
        this.phoneFlag = 1;
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
    deleteItem(id){
          var r = confirm("Are you sure, You went to delete?");
          if (r == true) {
            axios
              .post("/api/delete-category/"+id,this.header)
              .then(response => {
                  let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Deleted successfully', {
                      position: 'top-right'
                      });
                    this.getUserProfile();
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
            .catch(err => {
              Vue.$toast.error(err, {
              position: 'top-right'
              });
            })
          }
        },

    saveCategory(){
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      if(! this.category_name){
                Vue.$toast.error('Category Name is required', {
                    position: 'top-right'
                });
            }else{
                axios
        .post("/api/save-category",{data: this.category_name,id: this.categoryEditId},header)
        .then(response => {
          if(response.data.flag == 1){
            if(this.categoryEditId == ''){
              Vue.$toast.success('Category added', {
                  position: 'top-right'
                  });
              }else{
                Vue.$toast.success('Category edited', {
                  position: 'top-right'
                  });
              }
          }
          this.category_name = '';
          this.addCategoryFlag = false;
          this.getUserProfile();
        })
            }
    },
    searchFruits(e) {
      if (!this.searchTerm) {
        this.companies = this.companiesCopy;
      }
      this.companies = this.companiesCopy.filter((fruit) => {
        return fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
      });
    },
    getAllCompanies(){
            let headers = {
                Authorization: "Bearer " + localStorage.getItem('token')
            }
            axios
            .get("/api/get-all-company", {headers: headers})
            .then(response => {
                this.companies = response.data.data;
                this.companies.push({name: 'None',id:'None'})
                this.companiesCopy  = [...this.companies]
            })
        },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
     

    add: function () {
         // for setting tab
    },

    getUserProfile() {
    let flag = 1;
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-category",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.categories = response.data.value
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });
           axios
        .get("/api/get-letter/"+this.$route.params.id,header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.letter = response.data.value
              this.letter.status = response.data.value.status == 1 ? 'Active' : 'Inactive'
              this.letter.body = response.data.value.letter
              this.letter.category = response.data.value.category_id
              this.letter.display_to = response.data.value.display_to
              this.letter.list_account = JSON.parse(response.data.value.list_account)
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });


     },




    saveLetter: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.letter.category) {
            Vue.$toast.error('Category is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.letter.status && flag == 1) {
            Vue.$toast.error('Staus email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.letter.title && flag == 1) {
            Vue.$toast.error('Title phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        

        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-letter/"+this.$route.params.id,
          data: self.letter
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Letter edited', {
                    position: 'top-right'
                  })
                  if(this.role.name == '/admin/super_admin')
                    this.$router.push('/admin/contract-templates')
                  else
                    this.$router.push('/admin/contract-templates')

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
      console.log('created called.');
      this.getUserProfile();
      this.getAllCompanies()
    },

}
</script>

