<template>
  <div>
    <div class="px-4 pt-2 pb-0 d-flex">
      <div class="ms-4">
        <h6 class="text-h6 text-typo font-weight-bold">{{name}}</h6>
       
      </div>
    </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart">
       
        <v-simple-table class="table">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="
                text-left text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              Zip
            </th>
           
            <th
              class="
                 text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              Scans
            </th>
            <th
              class="
                text-center text-uppercase text-secondary text-xxs
                font-weight-bolder
                opacity-7
              "
            >
              %
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.zip">
            <td class="border-bottom" style="width: 60%;">

                    <v-list-item-title
                      class="text-typo text-subtitle-2 ls-0 font-weight-bold"
                      >{{ item.zip }}</v-list-item-title
                    >

            </td>
           
            <td class="border-bottom"  style="width: 30%;">
              <div class="d-flex align-center" style="text-align: center;">
               <span style="color: green;">{{item.count}}</span>
              </div>
            </td>

            <td class="border-bottom"  style="width: 10%;">
              <span class="text-secondary font-weight-light text-sm">{{
                item.percentage
              }}%</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
export default {
  name: "bar-chart",
  data: function () {
    return {
      barChartId: "bar-chart",
      chart : '',
      projects: [
      {
        id: 230019,
        project: "Christopher Knight Home",
        imgSrc:
          "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/ecommerce/black-chair.jpg",
        price: "$89.53",
        lastIcon: "half",
        progressValue: "80",
        progressColor: "success",
      },],
    };
  },
  props : ["name","data"],
  mounted() {
    
    
    
  },
  methods : {
    updateChart() {
      let labels = [];
      let chart_data = [];
      this.data.forEach(element => {
        labels.push(element.date)
        chart_data.push(element.count)
      });
      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = chart_data;
      this.chart.update();
    },
  },
  watch: {
    
    
  },
};
</script>
